import { Link } from "react-router-dom";
import { Image, Button } from "react-bootstrap";

function InternationallyOrderDHL(props) {
  return (
    <>
      <section
        className="delivery-section sc-mt"
        id="ShippedUSPSOvernight"
        // style={{ display: "none" }}
      >
        {/*<h3>IF Shipped to US and USPS Tracking, USPS 2-Day, USPS Overnight</h3>*/}
        <div className="Shipping-document">
          {props.trackingId !== "" &&
          <p className="text-white mb-0">
            Your order with Tracking Number{" "}
            <span className="text-warning">
              {props.trackingId || "---------"}
            </span>{" "}
            should have arrived by now.
          </p>
          }

          <h3 className="text-white">
            Your order should arrive by{" "}
            <span className="text-warning">{props.arrivalDate}</span>
          </h3>
          <Image
            className="deliver-document w-25"
            src={require("../../../../../assets/images/dhl.png").default}
            alt="USPS"
          />
          <h3 className="text-white">
            {/* <span className="text-warning"> */}
            Your address has been submitted for review
            {/* </span> */}
          </h3>
        </div>
      </section>
    </>
  );
}

export default InternationallyOrderDHL;

import React from "react";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'
import { Button } from "react-bootstrap"

class AdminReturn extends React.Component {
  componentDidMount() {
    $(document).ready(function () {
      $('#example').DataTable();
    });
  }

  render() {
    return (
      <>
              <div className="MainDiv">
                    <table id="example" className="display table-responsive">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Courier</th>
                          <th>Tracking number</th>
                          <th>Date</th>
                          <th>Address</th>
                          <th>ZIP Code</th>
                          <th>Phone</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>81901</td>
                          <td>USPS</td>
                          <td>789456</td>
                          <td>06/25/2021</td>
                          <td>1281 Washington St, Weymouth, USA</td>
                          <td>02189</td>
                          <td>789 340 3333</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>

                        <tr>
                          <td>81902</td>
                          <td>USPS Tracking</td>
                          <td>456789</td>
                          <td>07/25/2021</td>
                          <td>250 Greenwich St. | New York, NY </td>
                          <td>10007</td>
                          <td>789 341 4444</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>

                        <tr>
                          <td>81903</td>
                          <td>USPS 2-Day</td>
                          <td>456123</td>
                          <td>08/25/2021</td>
                          <td>15305 Dallas Parkway, Addison, TX, 75001</td>
                          <td>02191</td>
                          <td>789 342 5555</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>

                        <tr>
                          <td>81904</td>
                          <td>USPS Overnight</td>
                          <td>147852</td>
                          <td>09/25/2021</td>
                          <td>2815 Elliott Avenue, Seattle, WA, 98121</td>
                          <td>02192</td>
                          <td>789 343 6666</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>

                        <tr>
                          <td>81905</td>
                          <td>DHL</td>
                          <td>258963</td>
                          <td>10/25/2021</td>
                          <td>611 Gateway Blvd, San Francisco, CA, 94080</td>
                          <td>02193</td>
                          <td>789 344 7777</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>

                        <tr>
                          <td>81906</td>
                          <td>USPS</td>
                          <td>3698582</td>
                          <td>06/25/2021</td>
                          <td>1281 Washington St, Weymouth, USA</td>
                          <td>02189</td>
                          <td>789 340 3333</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>

                        <tr>
                          <td>81907</td>
                          <td>USPS Tracking</td>
                          <td>159357</td>
                          <td>07/25/2021</td>
                          <td>6425 Penn Avenue Pittsburgh, PA 15206 United States </td>
                          <td>556689</td>
                          <td>+1 206-876-1800</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>

                        <tr>
                          <td>81908</td>
                          <td>USPS 2-Day</td>
                          <td>136479</td>
                          <td>08/25/2021</td>
                          <td>Wells Fargo Building, 309 SW 6th Ave Portland, OR 97204 United States</td>
                          <td>112233</td>
                          <td>+1 858-239-4000</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>

                        <tr>
                          <td>81909</td>
                          <td>USPS Overnight</td>
                          <td>659878</td>
                          <td>09/25/2021</td>
                          <td>1875 Explorer Street 10th Floor Reston, VA 20190 United States</td>
                          <td>774455</td>
                          <td>+1 202-370-5600</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>

                        <tr>
                          <td>81910</td>
                          <td>DHL</td>
                          <td>202020</td>
                          <td>10/25/2021</td>
                          <td>6420 Sequence Dr Suite 400 San Diego, CA 92121 United States</td>
                          <td>887799</td>
                          <td>416-915-8200</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>

                        <tr>
                          <td>81911</td>
                          <td>USPS</td>
                          <td>123000</td>
                          <td>06/25/2021</td>
                          <td>601 N. 34th Street Seattle, WA 98103 United States</td>
                          <td>852147</td>
                          <td>789 340 3333</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>

                        <tr>
                          <td>81912</td>
                          <td>USPS Tracking</td>
                          <td>254136</td>
                          <td>07/25/2021</td>
                          <td>1450 Brickell Ave Ste 900 Miami FL 33131 United States </td>
                          <td>123123</td>
                          <td>+1 305-985-7900</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>

                        <tr>
                          <td>81913</td>
                          <td>USPS 2-Day</td>
                          <td>456456</td>
                          <td>08/25/2021</td>
                          <td>600 Amphitheatre Parkway Mountain View, CA 94043 United States</td>
                          <td>454545</td>
                          <td>+1 350-785-8000</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>

                        <tr>
                          <td>81914</td>
                          <td>USPS Overnight</td>
                          <td>595351</td>
                          <td>09/25/2021</td>
                          <td>340 Main Street Los Angeles, CA 90291 United States</td>
                          <td>159357</td>
                          <td>+1 310-310-6000</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>

                        <tr>
                          <td>81915</td>
                          <td>DHL</td>
                          <td>456456</td>
                          <td>10/25/2021</td>
                          <td>777 6th Street South Kirkland, WA United States</td>
                          <td>963258</td>
                          <td>+1 425-739-5600</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>

                        <tr>
                          <td>81916</td>
                          <td>USPS</td>
                          <td>424242</td>
                          <td>06/25/2021</td>
                          <td>210 Carpenter Ave Chicago, IL 60607 United States</td>
                          <td>456258</td>
                          <td>+1 248-593-4003</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>

                        <tr>
                          <td>81917</td>
                          <td>USPS Tracking</td>
                          <td>585858</td>
                          <td>07/25/2021</td>
                          <td>355 Main Street Cambridge, MA 02142 United States </td>
                          <td>852369</td>
                          <td>+1 617-575-1300</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>

                        <tr>
                          <td>81918</td>
                          <td>USPS 2-Day</td>
                          <td>787878</td>
                          <td>08/25/2021</td>
                          <td>2930 Pearl St Boulder, CO 80301 United States</td>
                          <td>147852</td>
                          <td>+1 303-245-0086</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>

                        <tr>
                          <td>81919</td>
                          <td>USPS Overnight</td>
                          <td>666666</td>
                          <td>09/25/2021</td>
                          <td>500 W 2nd St Suite 2900 Austin, TX 78701 United States</td>
                          <td>456123</td>
                          <td>+1 512-343-5283</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>

                        <tr>
                          <td>81920</td>
                          <td>DHL</td>
                          <td>444444</td>
                          <td>10/25/2021</td>
                          <td>2300 Traverwood Dr.Ann Arbor, MI 48105 United States</td>
                          <td>789456</td>
                          <td>+1 734-332-6500</td>
                          <td><Button className="btn btn-danger">Return</Button></td>
                        </tr>
                      </tbody>

                    </table>

                </div>
      </>
    );
  }
}

export default AdminReturn;

import React, {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../../route/services";
import {Link} from "react-router-dom";
import Image from "react-bootstrap/Image";
import {useHistory} from 'react-router-dom';
import CustomLoader from "react-loader-spinner";

const Unsubscribe = () => {
    const history = useHistory();
    const [isUnsubscribed, setIsUnsubscribed] = useState(false)
    const [isLoaderShow, setIsLoaderShow] = useState(true)
    const [unsubMsg, setUnsubMsg] = useState("")
    const tokenId = window.location.pathname.split('/')[3]


    useEffect(() => {
        axios.post(`${API.unsubscribeUser}${tokenId}/`, {checkunsubscribe: true},
            {
                headers: {
                    "Content-Type": "application/json"
                },
                mode: "no-cors",
            }).then((response) => {
            if (response.status == 200) {
                setIsUnsubscribed(false);
            }
            setIsLoaderShow(false);
        }).catch((error) => {
            setUnsubMsg("Already")
            setIsUnsubscribed(true);
            setIsLoaderShow(false);
        })
    }, []);


    const unsubscribeUser = () => {
        if (tokenId) {
            axios.post(`${API.unsubscribeUser}${tokenId}/`, {},
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    mode: "no-cors",
                }).then((response) => {
                if (response.status == 200) {
                    setIsUnsubscribed(true);
                }
            }).catch((error) => {
                console.log(error);
            })
        }
    };

    return (
        <div>

            <div className="unsubscribe-container">
                <div className='logo-image-container'>
                    <Image src={require('../../../assets/images/logo.png').default} alt="Eita-app" className="p-4"/>
                </div>
                <div className="detail-container">
                    {
                        isUnsubscribed ? <div className='Unsubscribe-description'>
                                {isLoaderShow ?
                                    <div className='h-100 d-flex justify-content-center align-items-center'>
                                        <CustomLoader type="ThreeDots" color="#007B96" transform="translate(-50%,-50%)"
                                                      height={20}
                                                      width={160}/>
                                    </div>
                                    :
                                    <div>
                                        <div className='unsubscribe-title'> {unsubMsg} Unsubscribed</div>
                                        <div className='unsubscribe-desc'>
                                            You have {unsubMsg.toLowerCase()} successfully unsubscribed from our mailing
                                            list.
                                            <p className='contact-admin-text'>If you have any further questions or
                                                concerns, please feel free to contact us.</p>
                                        </div>
                                        <div className='text-right d-flex justify-content-end mt-4'>
                                            <button className='btn-text-login ' type='button' onClick={() => {
                                                history.push('/login')
                                            }}>Go To Login
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div> :
                            <div className='mx-auto desc-container'>
                                {isLoaderShow ?
                                    <div className='h-100 d-flex justify-content-center align-items-center'>
                                        <CustomLoader type="ThreeDots" color="#007B96" transform="translate(-50%,-50%)"
                                                      height={20}
                                                      width={160}/>
                                    </div>
                                    : <div>
                                        <h3 className="confirmation-question-container">
                                            Are you sure you want to Unsubscribe ?
                                        </h3>

                                        <p className="unsubscribe-desc-container">If you unsubscribe, you will no longer
                                            receiving any
                                            email from EITA.</p>
                                        <div className="button-container">
                                            <button className="btn-warning px-4 py-2" onClick={unsubscribeUser}
                                                    type='button'>Yes,
                                                Sure
                                            </button>
                                            <Link to={'/login'}>
                                                <button className="btn-warning px-4 py-2" type='button'>
                                                    Cancel
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                }
                            </div>
                    }
                </div>

            </div>

        </div>
    )
}

export default Unsubscribe

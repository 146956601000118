
import React from 'react';
import { useState } from 'react';


const AuthContext = React.createContext({
  isLoggedIn: false,
  payment: {},
  onLogin: () => { },
  onLogout: () => { },
  paymentHandler: () => { }
});


export const AuthContextProvider = (props) => {

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [payment, setPayment] = useState({})

  function onLoginHandler() {
    setIsLoggedIn(true)
  }

  function onLogoutHandler() {
    setIsLoggedIn(false)
  }

  function paymentDetail(data) {
    setPayment(data)
  }


  return (
    <AuthContext.Provider value=
      {{
        isLoggedIn: isLoggedIn,
        onLogin: onLoginHandler,
        onLogout: onLogoutHandler,
        payment: payment,
        paymentHandler: paymentDetail
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
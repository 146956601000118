import React from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { API } from "../../../../route/services";

let table = ""

class DataTable extends React.Component {
  state = {
    shippingId: localStorage.getItem("shippingId"),
  };

  componentDidMount() {
    const currentPage = localStorage.getItem('shippingCurrentPage')
    var changeValue = (id, userId, oderId) => {
      this.props.dataChangehandler(id, userId, oderId);
    };
    const userId = localStorage.getItem("userId");
    const shippingId = localStorage.getItem("shippingId");
    const orderDataId = localStorage.getItem("shippingDataId")
    if (userId !== undefined && userId !== null && !isNaN(parseInt(userId)) &&
        shippingId !== undefined && shippingId !== null && !isNaN(parseInt(shippingId)) &&
        orderDataId !== undefined && orderDataId !== null && !isNaN(parseInt(orderDataId))) {
      changeValue(shippingId, userId, orderDataId);
    }

    setInterval(() => {
      if (this.props.isReload) {
        table.ajax.reload();
        this.props.reloadOff();
      }
    }, 1000);
    table = $("#dataTable").DataTable({
      processing: true,
      paging: true,
      pageLength: 25,
      lengthChange: false,
      searching: {
        regex: false,
      },
      serverSide: true,
      select: true,
      order: [[0, "desc"]],
      ajax: {
        url: `${API.adminShippingTable}?format=datatables`,
        totalUsers: "data.iTotalRecords",
        iDisplayLength: 25,
        defaultContent: "",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        error: function (error) {
          if (error.status === 401) {
            localStorage.clear();
            window.location.href="/login";
          }
        }
        // dataSrc:''
      },
      columns: [
        {
          data: "order",
          name: "order__id",
          searchable: true,
        },
        {
          data: "user",
          name: "order__user__id",
          searchable: false,
          visible: false
        },
        {
          data: "shipping_status",
          name: "shipping_status",
          searchable:true,
          render : function(data, type, row) {
            let status = data.toLowerCase();
            return '<span id="dt-'+row.order+'" class="dt-status status-'+status+'">'+status+'</span>'
          }
        } ,
        {
          data: "status",
          name: "status",
          render : function(data, type, row) {
            let status = data.toLowerCase();
            data = data === "DHL" ? "FedEx" : data;
            return '<span id="dts-'+row.order+'" class="dt-status option-'+status.replaceAll(' ', '')+'">'+data+'</span>'
          }
        },
        {
          data: "first_name",
          name: "order__user__first_name",
          searchable: true,
        },
        {
          data: "last_name",
          name: "order__user__last_name",
          searchable: true,
        },

        {
          data: "email",
          name: "order__user__email",
          searchable: true,
          visible: false
        },

        {
          data: "id",
          name: "id",
          searchable: false,
          visible: false
        },
      ],
      createdRow: (row, data, dataIndex) => {
        if (data.order == this.state.shippingId) {
          $(row).addClass("selected");
        }
      },
    });
    setTimeout(() => {
      if (currentPage !== undefined && currentPage !== null && !isNaN(parseInt(currentPage))) {
        table.page(parseInt(currentPage)).draw('page');
      }
    }, 500)
    $("#dataTable tbody").on("click", "tr", function() {
      var data = table.row(this).data();

      if ($(this).hasClass("selected")) {
        // $(this).removeClass("selected");
      } else {
        table.$("tr.selected").removeClass("selected");
        $(this).addClass("selected");
      }

      changeValue(data.order, data.user, data.id);
      localStorage.setItem("shippingDataId", data.id);
      localStorage.setItem("shippingId", data.order);
      localStorage.setItem("userId", data.user);
    });
    $(document).ready(function() {
      $("#dataTable").css("cursor", "pointer");
    });
  }
  componentDidUpdate() {
    if (table) {
      setTimeout(() => {
        localStorage.setItem('shippingCurrentPage', table.page())
      }, 1000)
    }
  }


  render() {
    return (
      <>
        <div className="MainDiv">
          <table id="dataTable" className="display table-responsive">
            <thead>
              <tr id="tr">
                <th>Id</th>
                <th>user_id</th>
                <th>Shipping Status</th>
                <th>Shipping Option</th>
                <th>First</th>
                <th>Last</th>
              </tr>
            </thead>
            <tbody></tbody>
            {/* <tfoot>
              <tr>
                <th>Id</th>
                <th>Shipping Option</th>
                <th>First</th>
                <th>Last</th>
              </tr>
            </tfoot> */}
          </table>
        </div>
      </>
    );
  }
}

export default DataTable;

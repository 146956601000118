import { Link, useHistory } from "react-router-dom";
import { Image, Button } from "react-bootstrap";

function ShippedUSandUSPSTracking(props) {
  const userId = localStorage.getItem("id");
  const url = process.env.REACT_APP_SERVER_URL;
  const history = useHistory();

  async function submitHandler(e) {
    history.push({ pathname: "/user/upgradeshipping", state: userId });
  }
  return (
    <>
      <section className="delivery-section sc-mt" id="ShippingInternationally">
        {/*<h3>IF Shipping to Internationally and USPS</h3>*/}
        <div className="Shipping-document">
          <p className="text-white mb-0">Your order has been shipped</p>
          <h3 className="text-white">
            You will receive your order in 20 business days
          </h3>
          <div className="d-block d-md-block d-lg-flex align-items-center justify-content-between w-75 m-auto">
            <Image
              className="deliver-document w-25"
              src={
                require("./../../../../../assets/images/internationally.png")
                  .default
              }
              alt="USPS"
            />
            <h3 className="text-white pe-3">
              It's not too late to upgrade to tracking to ensure document
              arrival.
            </h3>
          </div>
          {/* <Link to="/ordertrack"> */}
          {!props.isShipped &&
          <Button type="button" className="btn btn-warning" onClick={submitHandler}> Upgrade Tracking </Button>
          }
          {/* </Link> */}
        </div>
      </section>
    </>
  );
}

export default ShippedUSandUSPSTracking;

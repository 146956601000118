function Banner32(props) {
    return (
        <>
            <section className="delivery-section sc-mt" id="ShippedUSPSOvernight">
                <div className="Shipping-document-banner">
                    <div className="shipping-content-details">
                        <h3 className="text-white banner-32-title">Your documents are in transit.<br/>
                        {/*For Royal Mail*/}
                        {/*    An email was sent from {*/}
                        {/*    props.shippingOption==="DHL" ? "DHL"*/}
                        {/*        : (props.shippingOption==="RM" || props.shippingOption==="RM Tracked") ? "Royal Mail"*/}
                        {/*            : "GoShippo"*/}
                        {/*} with your tracking information.</h3>*/}
                            An email was sent from {props.shippingOption==="DHL" ? "FedEx" : "Shipstation"} with your
                            tracking information.</h3>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Banner32;
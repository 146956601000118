import React from "react";
import { Form, Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

function Verificationemail() {
  const location = useLocation();
  const email = location.state;
  return (
    <>
      <div className="form-wrapper">
        <div className="login-form">
          <Image
            src={require("./../../../assets/images/logo.png").default}
            alt="Eita-app"
            className="w-auto p-4"
          />
          <div className="login-page">
            <Form>
              <h3>Verification email sent</h3>
              <p>We just sent a verification email to {email}</p>
              <p>Please follow the instruction in the email to continue.</p>
              <p>
                {" "}
                user can <Link to="/login">login</Link> here
              </p>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Verificationemail;


import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { API } from '../route/services';

const UserContext = React.createContext({
  isCompleteProfile: {},
  completeProfileHandler: () => { },

  cardDetail: {},
  cardHandler: () => { },
  cardDelete: () => { },

  payment: {},
  paymentHandler: () => { },

  amount: {},
  amountHandler: () => { }

});


export const UserContextProvider = (props) => {
  const [payment, setPayment] = useState({})
  const [completeProfile, setComplete] = useState("")
  const [cardDetail, setCardDetail] = useState({})
  const [amount, setAmount] = useState(0)

  function paymentDetail(data) {
    setPayment(data)
  }

  function countryDetail() {
    const userId = localStorage.getItem("id");

    axios
      .get(
        ` ${API.userAllDetailCheckFilled}/${userId}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          mode: "no-cors",
        }
      )
      .then((response) => {
        setComplete(response.data.complete_flag);
      })
      .catch((error) => {
        console.log("complete-details-check page error:", error.response);
      });
  }

  function cardHandler(data) {
    setCardDetail(data)
  }

  function cardDeleteHandler() {
    setCardDetail({ card_flag: 0 })
  }

  function amountHandler(data) {
    setAmount(data)
  }


  return (
    <UserContext.Provider value=
      {{
        payment: payment,
        paymentHandler: paymentDetail,

        amount: amount,
        amountHandler: amountHandler,

        cardDetail: cardDetail,
        cardHandler: cardHandler,
        cardDelete: cardDeleteHandler,

        isCompleteProfile: completeProfile,
        completeProfileHandler: countryDetail
      }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
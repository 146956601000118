import { Link, useHistory } from "react-router-dom";
import { Image, Button } from "react-bootstrap";

function Banner25() {
  const userId = localStorage.getItem("id");
  const url = process.env.REACT_APP_SERVER_URL;
  const history = useHistory();

  async function submitHandler(e) {
    history.push({ pathname: "/user/reshipdocument", state: userId });
  }
  return (
    <>
      <section className="delivery-section sc-mt" id="ShippedUSPSOvernight">
        {/*<h3>IF Shipped to US and USPS Tracking, USPS 2-Day, USPS Overnight</h3>*/}
        <div className="Shipping-document-banner">
          <div className="shipping-content-details">
            <p className="text-white mb-0">
              The address you submitted on your application was incorrect
            </p>
            <h3 className="text-white">
              You will need to repuchase shipping to have your documnets
              reshipped.
            </h3>
            <Button
              type="button"
              className="btn btn-warning"
              onClick={submitHandler}
            >
              Reship my ducuments
            </Button>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner25;

import axios from "axios";
import {Row, Col, Form, Button, Alert, Image, Modal} from "react-bootstrap";
import React, {useCallback, useEffect, useRef} from "react";
import {useState} from "react";
import CountrySelect from "react-bootstrap-country-select";
import "../driverinformation/style.css";
import {countryList, shippingCountry} from "../../../../common/country-list";
import ImageResizeByAdmin from "../licencephotouser/photo";
import {shippingErrors, personalInfoErrors, driverInfoErrors, sendInvoiceErrors} from "./errors";
import Loader from "../../loader/Loader";
import {API} from "../../../../route/services";
import TransactionHistoryModel from "./transaction-history/Transaction-History";
import ImageResize from "../../../../user/component/userdashboard/carriersection/uploadphoto/photo";
import {MapInteractionCSS} from "react-map-interaction";
import DocumentPDF from "../../../../user/component/userdashboard/informationsection/documentPDF/DocumentPDF";
import CustomLoader from "react-loader-spinner";

const UserDetails = (props) => {
    const [show, setShow] = useState(false);
    const [shipOptionError, setShipOptionError] = useState({shipdate: false, tracking: false});

    const handleShow = (e) => {
        e.preventDefault();
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    const orderId = localStorage.getItem("orderId");
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [alertDeleteAccount, setAlertDeleteAccount] = useState(false);
    const [alertInvalid, setAlertInvalid] = useState(false);
    const [alertProcess, setAlertProcess] = useState(false);
    const [isShippingCustomer, setIsShippingCustomer] = useState(false);
    const [isShippingAdd, setIsShippingAdd] = useState(false);
    const [isShippingRemove, setIsShippingRemove] = useState(false);
    const countryType = "Other";

    const userData = props.data;
    const userId = userData.userId;
    const isUnpaidOrder = userData.orderStatus === 'Unpaid';
    const [isProcess, setProcess] = useState({show: isUnpaidOrder || userData.orderStatus === 'Japan/SK/China', disabled: false});
    const [btnGroup, setBtnGroup] = useState({makeLabelDisable: false,
        shippedFileDisable: false, reshipedDisable: false,
        returnedDisable: false, sendIdDisable: false, regenerateDocs: false, softDeleteAccount: false});
    const [makeLabelBtnAlert, setMakeLabelBtnAlert] = useState(false);
    const [shippedFileBtnAlert, setShippedFileBtnAlert] = useState(false);
    const [reshippedBtnAlert, setReshippedBtnAlert] = useState(false);
    const [returnedBtnAlert, setReturnedBtnAlert] = useState(false);
    const [sendIDBtnAlert, setSendIDBtnAlert] = useState(false);
    const [regenerateDocBtnAlert, setRegenerateDocBtnAlert] = useState(false);
    const [data, setData] = useState({
        personalInformation: {
            firstname: userData.personalInformation.firstname,
            lastname: userData.personalInformation.lastname,
            email: userData.personalInformation.email,
            dob: userData.personalInformation.dob,
            placeofbirth: userData.personalInformation.placeofbirth,
            placeofbirthother: userData.personalInformation.placeofbirthother,
            isMailEnabled: userData.personalInformation.isMailEnabled
        },
        driverInformation: {
            country: userData.driverInformation.country,
            countryOther: userData.driverInformation.countryOther,
            licenceNumber: userData.driverInformation.licenceNumber,
            A: userData.driverInformation.A,
            B: userData.driverInformation.B,
            C: userData.driverInformation.C,
            D: userData.driverInformation.D,
            all: userData.driverInformation.all,
            showDLNumber: userData.driverInformation.showDLNumber,
        },
        shippingInformation: {
            address: userData.shippingInformation.address,
            address2: userData.shippingInformation.address2,
            city: userData.shippingInformation.city,
            state: userData.shippingInformation.state,
            country: userData.shippingInformation.country,
            countryOther: userData.shippingInformation.countryOther,
            zipcode: userData.shippingInformation.zipcode,
            phoneNumber: userData.shippingInformation.phoneNumber,
            tracking: userData.shippingInformation.tracking,
            shipdate: userData.shippingInformation.shipdate,
            shipOption: userData.shippingInformation.shipOption,
            shipCheck: userData.shippingInformation.shipCheck,
        },
        sendInvoice: {
            validity: userData.sendInvoice.validity,
            issueDate: userData.sendInvoice.issueDate,
            ipLocation: userData.sendInvoice.ipLocation,
            address: userData.sendInvoice.address,
            docType: userData.sendInvoice.docType,
            checkIssueDate: userData.sendInvoice.checkIssueDate,
            checkAddress: userData.sendInvoice.checkAddress,
        },
        urgent: userData.urgent,
        drivingLicence: userData.drivingLicence,
        orderStatus: userData.orderStatus,
        planType: userData.planType,
        isLoad: false,
    });
    const [reshipModel, handleReshipModel] = useState(false);
    const [reshipModelMsg, setReshipModelMsg] = useState("");
    const [btnGroupError, setBtnGroupError] = useState(false);
    const [btnGroupErrorMsg, setBtnGroupErrorMsg] = useState("");
    const [isDlModel, setDlModel] = useState(false);
    const [isProcessUnpaid, setIsProcessUnpaid] = useState(false);
    const [docFlag, setDocFlag] = useState(-1);

    const repDoc = useRef(null);

    const [error, setError] = useState({
        personalInfoError: {},
        driverInfoError: {},
        shippingError: {},
        sendInvoiceError: {},
    });

    const userDocumentsBannerCallingAPI = useCallback(
        () => {
            axios.get(`${API.userDocumentsCardBanner}/${userId}/`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            }).then((response) => {
                if(docFlag !== response.data.doc_flag){
                    setDocFlag(response.data.doc_flag);
                }
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    window.location.href="/login";
                } else {
                    console.log("digital card error:", error.response);
                }
            });
        },
        []
    );

    // driverchange{
    function handleCountryChange(e) {
        if (e && e.id) {
            setData({...data, driverInformation: {...data.driverInformation, country: e.name}});
        }
    }

    function vehicalBikeChange(e) {
        setData({...data, driverInformation: {...data.driverInformation, A: !data.driverInformation.A}});
    }

    function vehicalCarChange(e) {
        setData({...data, driverInformation: {...data.driverInformation, B: !data.driverInformation.B}});
    }

    function vehicalTruckChange(e) {
        setData({...data, driverInformation: {...data.driverInformation, C: !data.driverInformation.C}});
    }

    function vehicalBusChange(e) {
        setData({...data, driverInformation: {...data.driverInformation, D: !data.driverInformation.D}});
    }

    function vehicalAllChange(e) {
        setData({...data, driverInformation: {...data.driverInformation, all: !data.driverInformation.all}});
    }

    function changeDLCheckHandler(e) {
        setData({...data, driverInformation: {...data.driverInformation, showDLNumber: !data.driverInformation.showDLNumber}});
    }

    function changeSendMailCheckHandler(e) {
        setData({...data, personalInformation: {...data.personalInformation, isMailEnabled: !data.personalInformation.isMailEnabled}});
    }

    function onChangeColor(event) {
        setData({...data, shippingInformation: {...data.shippingInformation, shipOption: event.target.value}});
    }

    function changeShipCheckHandler(e) {
        if (!data.shippingInformation.shipCheck) {
            setIsShippingCustomer(true);
        } else {
            setIsShippingCustomer(false);
            changeIsShippingCustomer(false);
        }
        setData({...data, shippingInformation: {...data.shippingInformation, shipCheck: !data.shippingInformation.shipCheck}});
    }

    function changeShippingCustomer() {
        setIsShippingCustomer(false);
        setData({...data, shippingInformation: {...data.shippingInformation, shipCheck: false}});
    }

    function hideUnpaidProcessModel() {
        setIsProcessUnpaid(false);
    }

    function changeCheckDateHandler(e) {
        setData({...data, sendInvoice: {...data.sendInvoice, checkIssueDate: !data.sendInvoice.checkIssueDate}});
    }

    function changeCheckAddressHandler(e) {
        setData({...data, sendInvoice: {...data.sendInvoice, checkAddress: !data.sendInvoice.checkAddress}});
    }

    useEffect(() => {
        let timeout = setTimeout(() => {
            alertSuccess && setAlertSuccess(false);
            alertDeleteAccount && setAlertDeleteAccount(false);
            alertInvalid && setAlertInvalid(false);
            alertProcess && setAlertProcess(false);
            makeLabelBtnAlert && setMakeLabelBtnAlert(false);
            shippedFileBtnAlert && setShippedFileBtnAlert(false);
            reshippedBtnAlert && setReshippedBtnAlert(false);
            returnedBtnAlert && setReturnedBtnAlert(false);
            isShippingAdd && setIsShippingAdd(false);
            isShippingRemove && setIsShippingRemove(false);
            btnGroupError && setBtnGroupError(false);
            sendIDBtnAlert && setSendIDBtnAlert(false);
            regenerateDocBtnAlert && setRegenerateDocBtnAlert(false);

        }, 4000);
        return () => {clearTimeout(timeout)};
    }, [alertSuccess, alertInvalid, alertProcess, isShippingAdd,
        makeLabelBtnAlert, shippedFileBtnAlert, reshippedBtnAlert,
        returnedBtnAlert, isShippingRemove, btnGroupError, sendIDBtnAlert, regenerateDocBtnAlert, alertDeleteAccount
    ]);

    docFlag === -1 && userDocumentsBannerCallingAPI();
    useEffect(() => {
        repDoc.current = setInterval(
            userDocumentsBannerCallingAPI,
            20000
       );
        return  () => {clearInterval(repDoc.current)}
    }, []);

    async function submitDriverInfo(e) {
        e.preventDefault();
        let datas = data;
        if(data.personalInformation.placeofbirth !== countryType) {
            data.personalInformation.placeofbirthother = "";
            data.personalInformation['place_of_birth_other'] = "";
        } else {
            data.personalInformation['place_of_birth_other'] = data.personalInformation.placeofbirthother;
        }
        if(data.driverInformation.country !== countryType) {
            data.driverInformation.countryOther = "";
            data.driverInformation['driver_license_country_other'] = "";
        } else {
            data.driverInformation['driver_license_country_other'] = data.driverInformation.countryOther;
        }
        if(data.shippingInformation.country !== countryType) {
            data.shippingInformation.countryOther = "";
            data.shippingInformation['shipping_country_other'] = "";
        } else {
            data.shippingInformation['shipping_country_other'] = data.shippingInformation.countryOther;
        }
        setData(datas);
        setError({
            personalInfoError: personalInfoErrors(data.personalInformation),
            //shippingError: shippingErrors(data.shippingInformation),
            driverInfoError: driverInfoErrors(data.driverInformation),
            sendInvoiceError: sendInvoiceErrors(data.sendInvoice),
        });

        /*if (data.shippingInformation.shipCheck) {
            if (!data.shippingInformation.shipdate && !data.shippingInformation.tracking) {
                setAlert({...alert, invalid: true});
                return setShipOptionError({shipdate: true, tracking: true});
            } else {
                if (!data.shippingInformation.shipdate) {
                    setAlert({...alert, invalid: true});
                    return setShipOptionError({tracking: false, shipdate: true});
                }
                if (!data.shippingInformation.tracking) {
                    setAlert({...alert, invalid: true});
                    return setShipOptionError({shipdate: false, tracking: true});
                }
            }
        }*/

        setShipOptionError({shipdate: false, tracking: false});

        setData({...data, isLoad: true});

        if (!Object.keys(personalInfoErrors(data.personalInformation)).length
            //&& !Object.keys(shippingErrors(data.shippingInformation)).length
            && !Object.keys(driverInfoErrors(data.driverInformation)).length && !Object.keys(sendInvoiceErrors(data.sendInvoice)).length) {
            await axios.put(`${API.adminSavedUsersOrder}/${userId}/${orderId}/`,
                {
                    personalInformation: data.personalInformation,
                    driverInformation: data.driverInformation,
                    shippingInformation: data.shippingInformation,
                    sendInvoice: data.sendInvoice,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    mode: "no-cors",
                }
            ).then((response) => {
                props.reloadHandler();
                setData({...data, isLoad: false});
                setAlertSuccess(true);
                setDocFlag(-1);
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    window.location.href="/login";
                } else {
                    console.log(error);
                    setData({...data, isLoad: false});
                    setAlertInvalid(true);
                }
            });
        } else {
            setData({...data, isLoad: false});
            setAlertInvalid(true);
        }
    }

    async function changeIsShippingCustomer(isShip) {
        await axios.post(`${API.addShippingEntry}/${orderId}/`,
            {'shipping_bool': isShip},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            }
        ).then((response) => {
            setIsShippingCustomer(false);
            isShip ? setIsShippingAdd(true) : setIsShippingRemove(true);
        }).catch((error) => {
            console.log(error);
        });
    }

    function process() {
        setProcess({disabled: true});
         axios.post(`${API.processStatus}${orderId}/`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            }
        ).then((response) => {
            setAlertProcess(true);
             // setProcess({show: false});
             props.reloadHandler();
        }).catch((error) => {
             if (error.response && error.response.status === 401) {
                 localStorage.clear();
                 window.location.href="/login";
             } else {
                 setProcess({disabled: false});
             }
            console.log(error);
        });

        setIsProcessUnpaid(false)
    }

    function makeLabel(isReshiped) {
        if (!isReshiped) {
            setBtnGroup({...btnGroup, makeLabelDisable: true});
        } else {
            setReshipModelMsg("Creating shipping entry...");
            handleReshipModel(true);
            setBtnGroup({...btnGroup, reshipedDisable: true});
        }
        axios.post(`${API.makeLabel}${orderId}/`,
            {shipping_bool: true},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            }
        ).then((response) => {
            if (!isReshiped) {
                setMakeLabelBtnAlert(true);
                setBtnGroup({...btnGroup, makeLabelDisable: false, makeLabel: true});
            } else {
                makeShipped(true);
            }
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href="/login";
            } else {
                if (error.response.data && error.response.data.detail) {
                    setBtnGroupErrorMsg(error.response.data.detail)
                } else {
                    setBtnGroupErrorMsg("Something went wrong!");
                }
                setBtnGroupError(true);
                if (!isReshiped) {
                    setBtnGroup({...btnGroup, makeLabelDisable: false});
                } else {
                    handleReshipModel(false);
                    setReshipModelMsg("");
                    setBtnGroup({...btnGroup, reshipedDisable: false});
                }
            }
        });
    }

    function makeShipped(isReshiped) {
        let data = {};
        if (!isReshiped) {
            setReshipModelMsg("Uploading shipped files, It may take few minutes, Please wait ...");
            handleReshipModel(true);
            setBtnGroup({...btnGroup, shippedFileDisable: true});
        } else {
            setReshipModelMsg("Uploading shipped files, It may take few minutes, Please wait ...");
            data = {wait:true};
            setBtnGroup({...btnGroup, reshipedDisable: true});
        }
        axios.post(`${API.makeShippedFiles}${userId}/`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            }
        ).then((response) => {
            if (!isReshiped) {
                handleReshipModel(false);
                setShippedFileBtnAlert(true);
                setBtnGroup({...btnGroup, shippedFileDisable: false});
                setReshipModelMsg("");
            } else {
                handleReshipModel(false);
                setReshipModelMsg("");
                setReshippedBtnAlert(true);
                setBtnGroup({...btnGroup, reshipedDisable: false});
            }
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href="/login";
            } else {
                if (error.response.data && error.response.data.detail) {
                    setBtnGroupErrorMsg(error.response.data.detail)
                } else {
                    setBtnGroupErrorMsg("Something went wrong!");
                }
                setBtnGroupError(true);
                if (!isReshiped) {
                    handleReshipModel(false);
                    setReshipModelMsg("");
                    setBtnGroup({...btnGroup, shippedFileDisable: false});
                } else {
                    handleReshipModel(false);
                    setReshipModelMsg("");
                    setBtnGroup({...btnGroup, reshipedDisable: false});
                }
            }
        });
    }

    function regenerateDocs() {

        setBtnGroup({...btnGroup, regenerateDocs: true});
        setDocFlag(0)

        axios.post(`${API.regenerateDocs}${orderId}/`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            }
        ).then((response) => {
            setDocFlag(-1)
            setRegenerateDocBtnAlert(true);
            setBtnGroup({...btnGroup, regenerateDocs: false});

        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href="/login";
            } else {
                if (error.response.data && error.response.data.detail) {
                    setBtnGroupErrorMsg(error.response.data.detail)
                } else {
                    setBtnGroupErrorMsg("Something went wrong!");
                }
                setBtnGroupError(true);
                setBtnGroup({...btnGroup, regenerateDocs: false});
            }
        });

    }

    function handleDlModel(isModel) {
        setDlModel(isModel);
    }

    function Reshiped() {
        makeLabel(true);
    }

    function deleteAccount(){
        setBtnGroup({...btnGroup, softDeleteAccount: true});
        axios.post(`${API.deleteAccount}${userId}/`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            }
        ).then((response) => {
            setAlertDeleteAccount(true);
            setBtnGroup({...btnGroup, softDeleteAccount: false});
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href="/login";
            } else {
                if (error.response.data && error.response.data.detail) {
                    setBtnGroupErrorMsg(error.response.data.detail)
                } else {
                    setBtnGroupErrorMsg("Something went wrong!");
                }
                setBtnGroupError(true);
                setBtnGroup({...btnGroup, softDeleteAccount: false});
            }
        });

    }

    function ReturnedAddress(){
        setBtnGroup({...btnGroup, returnedDisable: true});
        axios.post(`${API.returnedAddressMail}${orderId}/`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            }
        ).then((response) => {
            setReturnedBtnAlert(true);
            setBtnGroup({...btnGroup, returnedDisable: false});

        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href="/login";
            } else {
                if (error.response.data && error.response.data.detail) {
                    setBtnGroupErrorMsg(error.response.data.detail)
                } else {
                    setBtnGroupErrorMsg("Something went wrong!");
                }
                setBtnGroupError(true);
                setBtnGroup({...btnGroup, returnedDisable: false});
            }
        });

    }

    function SendID() {
        setBtnGroup({...btnGroup, sendIdDisable: true});
        axios.post(`${API.sendIdCardMail}${orderId}/`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            }
        ).then((response) => {
            setSendIDBtnAlert(true);
            setBtnGroup({...btnGroup, sendIdDisable: false});

        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href="/login";
            } else {
                if (error.response.data && error.response.data.detail) {
                    setBtnGroupErrorMsg(error.response.data.detail)
                } else {
                    setBtnGroupErrorMsg("Something went wrong!");
                }
                setBtnGroupError(true);
                setBtnGroup({...btnGroup, sendIdDisable: false});
            }
        });

    }

    return (
        <>
            <Form className="form" noValidate>
                <Row>
                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="admin-order-btn-group">
                        {!data.isLoad && (
                            <div className="shipping-button-info text-end shipping-btn-d">
                                {isProcess.show &&
                                <Button className="btn btn-old-bg btn-font mb-3" style={{marginRight: 0}}
                                        onClick={isUnpaidOrder ? () => {setIsProcessUnpaid(true)} : process}
                                        disabled={isProcess.disabled}>Process</Button>
                                }
                                <Button type="submit" className="btn btn-old-bg btn-font mb-3" onClick={submitDriverInfo}>Save</Button>
                                <Button variant="danger" className="btn-font mb-3" disabled={btnGroup.makeLabelDisable} onClick={makeLabel.bind(this, false)}>Make Label</Button>
                                <Button variant="success" className="btn-font mb-3" disabled={btnGroup.shippedFileDisable} onClick={makeShipped.bind(this, false)}>Make Shipped Files</Button>
                                <Button variant="primary" className="btn-font mb-3" disabled={btnGroup.reshipedDisable} onClick={Reshiped}>Reship</Button>
                                <Button variant="primary" className="btn-font mb-3" disabled={btnGroup.returnedDisable} style={{marginLeft: 0, backgroundColor: '#5447ae'}} onClick={ReturnedAddress}>Returned</Button>

                                {userData.planType === 'Digital Only' &&
                                    <Button variant="primary"
                                            className="btn-font mb-3" disabled={btnGroup.sendIdDisable}
                                            style={{backgroundColor: '#bf41be'}}
                                            onClick={SendID}>Send ID</Button>
                                }

                                <Button className="btn-font mb-3" disabled={btnGroup.softDeleteAccount} style={{backgroundColor: 'rgb(255 69 104)'}} onClick={deleteAccount}>Delete Account</Button>

                                <span>
                                <label className={data.urgent ? "urgent-label btn-font mb-3" : "btn-off-urgent btn-font mb-3"}>
                                    Urgent
                                </label>
                            </span>
                            </div>
                        )}
                    </div>
                </Col>
                </Row>
                <Row>
                    <Col className="col-12 col-sm-12 col-md-12 col-lg-6">
                        {data.isLoad && (
                            <div className="userdetail-loader">
                                <Loader/>
                            </div>
                        )}
                        <section className="driver-info-section">
                            <h3>Customer Information</h3>
                            <Row>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="text" className="form-control" name="firstname" value={data.personalInformation.firstname}
                                                      onChange={(e) =>
                                                          setData({...data, personalInformation: {...data.personalInformation, firstname: e.target.value}})}>
                                        </Form.Control>
                                        {error.personalInfoError.firstname && (
                                            <small className="text-danger">
                                                {error.personalInfoError.firstname}
                                            </small>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" name="lastname" className="form-control" value={data.personalInformation.lastname}
                                                      onChange={(e) =>
                                                          setData({...data, personalInformation: {...data.personalInformation, lastname: e.target.value}})}>
                                        </Form.Control>
                                        {error.personalInfoError.lastname && (
                                            <small className="text-danger">
                                                {error.personalInfoError.lastname}
                                            </small>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <div className="d-flex mb-2" style={{justifyContent:'space-between'}}>
                                            <Form.Label className="m-0 p-0">
                                                Email
                                            </Form.Label>
                                            <div style={{display:'flex'}}>
                                                <Form.Check aria-label="option 1" className="me-2" name="checkSendMailShow" value={data.personalInformation.isMailEnabled}
                                                    checked={data.personalInformation.isMailEnabled} onChange={changeSendMailCheckHandler} />
                                                Send emails
                                            </div>
                                        </div>
                                        <Form.Control type="text" name="email" className="form-control" value={data.personalInformation.email}
                                                      onChange={(e) =>
                                                            setData({...data, personalInformation: {...data.personalInformation, email: e.target.value}})}>
                                        </Form.Control>
                                        {error.personalInfoError.email && (
                                            <small className="text-danger">
                                                {error.personalInfoError.email}
                                            </small>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Date of Birth</Form.Label>
                                        <Form.Control type="date" name="dob" className="form-control" value={data.personalInformation.dob}
                                                      onChange={(e) => setData({...data, personalInformation: {...data.personalInformation, dob: e.target.value}})}>
                                        </Form.Control>
                                        {error.personalInfoError.dob && (
                                            <small className="text-danger">
                                                {error.personalInfoError.dob}
                                            </small>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Place of Birth </Form.Label>
                                        <CountrySelect name="country" placeholder={data.personalInformation.placeofbirth} countries={countryList}
                                                       onChange={(e) => {
                                                           if (e && e.id) {
                                                               setData({...data, personalInformation: {...data.personalInformation, placeofbirth: e.name}})
                                                           }
                                                       }} className={data.personalInformation.placeofbirth === "Other" ? "other-country" : ""}/>
                                        {error.personalInfoError.placeofbirth && (
                                            <small className="text-danger">
                                                {error.personalInfoError.placeofbirth}
                                            </small>
                                        )}
                                    </Form.Group>
                                </Col>
                                {data.personalInformation.placeofbirth === countryType &&
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Other</Form.Label>
                                        <Form.Control type="text" name="other" className="form-control" value={data.personalInformation.placeofbirthother}
                                                      onChange={(e) =>
                                                          setData({...data, personalInformation: {...data.personalInformation, placeofbirthother: e.target.value}})}>
                                        </Form.Control>
                                        {error.personalInfoError.placeofbirthother && (
                                            <small className="text-danger">
                                                {error.personalInfoError.placeofbirthother}
                                            </small>
                                        )}
                                    </Form.Group>
                                </Col>
                                }
                            </Row>
                        </section>
                        <section className="driver-info-section">
                            <h3>Driver Information</h3>
                            <Row>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Driver's License Country of issue</Form.Label>
                                        <CountrySelect name="country" placeholder={data.driverInformation.country} countries={countryList} onChange={handleCountryChange} className={data.driverInformation.country === "Other" ? "other-country" : ""}/>
                                        {error.driverInfoError.country && (
                                            <small className="text-danger">
                                                {error.driverInfoError.country}
                                            </small>
                                        )}
                                    </Form.Group>
                                </Col>
                                {data.driverInformation.country === countryType &&
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Other</Form.Label>
                                        <Form.Control type="text" name="countryOther" className="form-control" value={data.driverInformation.countryOther}
                                                      onChange={(e) =>
                                                          setData({...data, driverInformation: {...data.driverInformation, countryOther: e.target.value}})}>
                                        </Form.Control>
                                        {error.driverInfoError.countryOther && (
                                            <small className="text-danger">
                                                {error.driverInfoError.countryOther}
                                            </small>
                                        )}
                                    </Form.Group>
                                </Col>
                                }
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <div className="d-flex align-items-center mb-2">
                                            <Form.Check aria-label="option 1" className="me-2" name="checkDLShow" value={data.driverInformation.showDLNumber}
                                                        checked={data.driverInformation.showDLNumber} onChange={changeDLCheckHandler}/>
                                            <Form.Label className="m-0 p-0">
                                                Driver's License Number
                                            </Form.Label>
                                        </div>
                                        <Form.Control type="string" className="form-control" name="licenceNumber" value={data.driverInformation.licenceNumber}
                                                      onChange={(e) =>
                                                          setData({...data, driverInformation: {...data.driverInformation, licenceNumber: e.target.value}})}>
                                        </Form.Control>
                                        {error.driverInfoError.licenceNumber && (
                                            <small className="text-danger">
                                                {error.driverInfoError.licenceNumber}
                                            </small>
                                        )}
                                    </Form.Group>
                                </Col>
                                <div className="select-type user-details-type">
                                    <div className="select-whitical">
                                        <div>
                                            <input className="select-address-type" type="checkbox" id="inputOne" name="A" value={data.driverInformation.A} checked={data.driverInformation.A}
                                                   onChange={vehicalBikeChange}/>
                                            <label htmlFor="inputOne"/>
                                        </div>
                                        <p>A. Motor-cycle</p>
                                    </div>
                                    <div className="select-whitical">
                                        <div>
                                            <input className="select-address-type" type="checkbox" id="inputThree" name="B" value={data.driverInformation.B} checked={data.driverInformation.B}
                                                   onChange={vehicalCarChange}/>
                                            <label htmlFor="inputThree"/>
                                        </div>
                                        <p>B: Car</p>
                                    </div>
                                    <div className="select-whitical">
                                        <div>
                                            <input className="select-address-type" type="checkbox" id="inputFive" value={data.driverInformation.C} checked={data.driverInformation.C} name="C"
                                                   onChange={vehicalTruckChange}/>
                                            <label htmlFor="inputFive"/>
                                        </div>
                                        <p>C: Semi-Truck</p>
                                    </div>
                                    <div className="select-whitical">
                                        <div>
                                            <input className="select-address-type" type="checkbox" id="inputTwo" name="D" value={data.driverInformation.D} checked={data.driverInformation.D}
                                                   onChange={vehicalBusChange}/>
                                            <label htmlFor="inputTwo"/>
                                        </div>
                                        <p>D: Bus</p>
                                    </div>
                                    <div className="select-whitical">
                                        <div>
                                            <input className="select-address-type" type="checkbox" id="inputFour" value={data.driverInformation.all} checked={data.driverInformation.all} name="all"
                                                   onChange={vehicalAllChange}/>
                                            <label htmlFor="inputFour"/>
                                        </div>
                                        <p>E: B, C, or D + Trailer</p>
                                    </div>
                                </div>
                            </Row>
                        </section>
                        <section className="personal-info-section" id="customerAddress">
                            <h3>Shipping Address</h3>
                            <Row>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <div className="d-flex align-items-center mb-2">
                                            <Form.Check aria-label="option 1" className="me-2" name="checkDLShow" value={data.shippingInformation.shipCheck}
                                                        checked={data.shippingInformation.shipCheck} onChange={changeShipCheckHandler} disabled={data.orderStatus === 'Japan/SK/China' ? true : false}/>
                                            <Form.Label className="m-0 p-0">{" "}Shipping option</Form.Label>
                                        </div>

                                        <Form.Control as="select" aria-label="Default select example" onChange={onChangeColor.bind(this)} value={data.shippingInformation.shipOption}>
                                            <option value="">--Select Shipping Option--</option>
                                            <option value="DHL">FedEx</option>
                                            <option value="USPS Free">USPS FREE</option>
                                            <option value="USPS First Class">USPS First Class</option>
                                            <option value="USPS 2 Day">USPS 2-Day</option>
                                            <option value="USPS Overnight">USPS Overnight</option>
                                            <option value="First Class International">First Class International</option>
                                            <option value="AUS Post">AUS Post</option>
                                            {/*For Royal Mail*/}
                                            {/*<option value="RM">RM</option>*/}
                                            {/*<option value="RM Tracked">RM Tracked</option>*/}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Date Shipped</Form.Label>
                                        <Form.Control type="date" className="form-control" name="shipdate" value={data.shippingInformation.shipdate !== null ? data.shippingInformation.shipdate : ""}
                                                      onChange={(e) =>
                                                          setData({...data, shippingInformation: {...data.shippingInformation, shipdate: e.target.value}})}/>
                                        {/*{shipOptionError.shipdate && (
                                            <small className="text-danger">
                                                this field is required
                                            </small>
                                        )}*/}
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control type="text" className="form-control" name="address" value={data.shippingInformation.address}
                                                      onChange={(e) => setData({...data, shippingInformation: {...data.shippingInformation, address: e.target.value}})}/>
                                        {/*{error.shippingError.address && (
                                            <small className="text-danger">
                                                {error.shippingError.address}
                                            </small>
                                        )}*/}
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Address 2</Form.Label>
                                        <Form.Control type="text" className="form-control" name="address2" value={data.shippingInformation.address2}
                                                      onChange={(e) => setData({...data, shippingInformation: {...data.shippingInformation, address2: e.target.value}})}/>
                                        {/*{error.shippingError.address2 && (
                                            <small className="text-danger">
                                                {error.shippingError.address2}
                                            </small>
                                        )}*/}
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-6 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control type="text" className="form-control" name="city" value={data.shippingInformation.city}
                                                      onChange={(e) => setData({...data, shippingInformation: {...data.shippingInformation, city: e.target.value}})}/>
                                        {/*{error.shippingError.city && (
                                            <small className="text-danger">
                                                {error.shippingError.city}
                                            </small>
                                        )}*/}
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-6 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control type="text" className="form-control" name="state" value={data.shippingInformation.state}
                                                      onChange={(e) => setData({...data, shippingInformation: {...data.shippingInformation, state: e.target.value}})}/>
                                        {/*{error.shippingError.state && (
                                            <small className="text-danger">
                                                {error.shippingError.state}
                                            </small>
                                        )}*/}
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-6 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Country </Form.Label>
                                        <CountrySelect name="country" placeholder={data.shippingInformation.country} countries={shippingCountry}
                                                       onChange={(e) => {
                                                           if (e && e.id) {
                                                               setData({...data, shippingInformation: {...data.shippingInformation, country: e.name}})
                                                           }
                                                       }} className={data.shippingInformation.country === "Other" ? "other-country" : ""}/>
                                        {/*{error.shippingError.country && (
                                            <small className="text-danger">
                                                {error.shippingError.country}
                                            </small>
                                        )}*/}
                                    </Form.Group>
                                </Col>
                                {data.shippingInformation.country === countryType &&
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Other</Form.Label>
                                        <Form.Control type="text" name="countryOther" className="form-control" value={data.shippingInformation.countryOther}
                                                      onChange={(e) =>
                                                          setData({...data, shippingInformation: {...data.shippingInformation, countryOther: e.target.value}})}>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                }

                                <Col className="col-12 col-sm-12 col-md-6 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>ZIP Code</Form.Label>
                                        <Form.Control type="text" className="form-control" name="zipcode" value={data.shippingInformation.zipcode}
                                                      onChange={(e) => setData({...data, shippingInformation: {...data.shippingInformation, zipcode: e.target.value}})}/>
                                        {/*{error.shippingError.zipcode && (
                                            <small className="text-danger">
                                                {error.shippingError.zipcode}
                                            </small>
                                        )}*/}
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Phone number</Form.Label>
                                        <Form.Control type="number" className="form-control" name="phonenumber" value={data.shippingInformation.phoneNumber}
                                                      onChange={(e) => setData({...data, shippingInformation: {...data.shippingInformation, phoneNumber: e.target.value}})}/>
                                        {/*{error.shippingError.phoneNumber && (
                                            <small className="text-danger">
                                                {error.shippingError.phoneNumber}
                                            </small>
                                        )}*/}
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Tracking</Form.Label>
                                        <Form.Control type="number" className="form-control" name="tracking" value={data.shippingInformation.tracking}
                                                      onChange={(e) => setData({...data, shippingInformation: {...data.shippingInformation, tracking: e.target.value}})}/>
                                        {/*{shipOptionError.tracking && (
                                            <small className="text-danger">
                                                this field is required
                                            </small>
                                        )}*/}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </section>
                    </Col>
                    <Col className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <section className="upload-imges-profile">
                            {/*<ImageResizeByAdmin userId={userId}/>*/}
                            <ImageResize userId={userId} adminUser={true} reloadHandler={props.reloadHandler}/>
                        </section>
                        <section className="personal-info-section" id="sendInvoice">
                            <Form className="form" noValidate method="post">
                                <Row>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <Form.Group className="form-group">
                                            <Form.Label className="d-flex align-items-center">
                                                validity
                                            </Form.Label>
                                            <Form.Control id="validity" type="text" className="form-control" name="validity" value={data.sendInvoice.validity}
                                                          onChange={(e) => setData({...data, sendInvoice: {...data.sendInvoice, validity: e.target.value}})}/>
                                            {error.sendInvoiceError.validity && (
                                                <small className="text-danger">
                                                    {error.sendInvoiceError.validity}
                                                </small>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <Form.Group className="form-group">
                                            <div className="d-flex align-items-center mb-2">
                                                <Form.Check aria-label="option 1" className="me-2" name="checkIssueDate" value={data.sendInvoice.checkIssueDate}
                                                            checked={data.sendInvoice.checkIssueDate} onChange={changeCheckDateHandler}/>
                                                <Form.Label className="m-0 p-0">Issue date</Form.Label>
                                            </div>

                                            <Form.Control type="date" className="form-control" name="issueDate" value={data.sendInvoice.issueDate}
                                                          onChange={(e) => setData({...data, sendInvoice: {...data.sendInvoice, issueDate: e.target.value}})}/>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <Form.Group className="form-group">
                                            <Form.Label className="d-flex align-items-center">
                                                Document type
                                            </Form.Label>
                                            <Form.Select value={data.sendInvoice.docType} aria-label="Default select example"
                                                         onChange={(e) => setData({...data, sendInvoice: {...data.sendInvoice, docType: e.target.value}})}>
                                                <option value="default">Default</option>
                                                <option value="Vietnam">Vietnam</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <Form.Group className="form-group">
                                            <Form.Label className="d-flex align-items-center">
                                                IP Location
                                            </Form.Label>
                                            <Form.Control type="text" className="form-control" name="ipLocation" value={data.sendInvoice.ipLocation} disabled={true} readOnly={true}
                                                          onChange={(e) =>
                                                              setData({...data, sendInvoice: {...data.sendInvoice, ipLocation: e.target.value}})}/>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <Form.Group className="form-group">
                                            <div className="d-flex align-items-center mb-2">
                                                <Form.Check aria-label="option 1" className="me-2" name="checkAddress" value={data.sendInvoice.checkAddress} onChange={changeCheckAddressHandler}
                                                            checked={data.sendInvoice.checkAddress}/>
                                                <Form.Label className="m-0 p-0">Address</Form.Label>
                                            </div>
                                            <Form.Control type="text" className="form-control" name="address" value={data.sendInvoice.address !== null ? data.sendInvoice.address : ""} readOnly={!data.sendInvoice.checkAddress}
                                                          onChange={(e) =>
                                                              setData({...data, sendInvoice: {...data.sendInvoice, address: e.target.value}})}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </section>
                        <div className="invoice-button">
                            <Button type="submit" className="btn btn-info" onClick={handleShow}>
                                Transaction History
                            </Button>
                            <Button type="submit" className="btn btn-info">
                                Send Invoice
                            </Button>
                            <Button type="submit" className="btn btn-info">
                                Customer Log
                            </Button>
                        </div>
                        <section className="license-section">
                            <div className="driving-license">
                                <Image src={data.drivingLicence ? data.drivingLicence : require("./../../../../assets/images/notLicence.png").default} onClick={handleDlModel.bind(this, true)} style={{cursor: 'pointer'}}/>
                            </div>
                        </section>
                        {docFlag >= 0 &&
                            <section>
                                <DocumentPDF docFlag={docFlag}
                                             userID={userId} fromAdmin={true}
                                             shippingBool={data.shippingInformation.shipCheck}
                                             regenerateDocs={btnGroup.regenerateDocs}
                                />
                                <Button variant="success" style={{marginLeft:'auto', marginRight:'auto'}}  disabled={btnGroup.regenerateDocs} onClick={regenerateDocs}>
                                    {(!btnGroup.regenerateDocs)? 'Regenerate Documents' :
                                        <CustomLoader type="ThreeDots" color="#007B96" transform="translate(-50%,-50%)" height={20} width={160}/>}
                                    </Button>
                            </section>
                        }
                    </Col>
                </Row>
                <div className="modal-dialog modal-xl">
                    <Modal show={isDlModel} onHide={handleDlModel.bind(this, false)} centered>
                        <div className="MuiDialogTitle-root">
                            <h2 className="MuiTypography-root MuiTypography-h6"><span className="crop-title">Driver's license<button type="button" className="tool-close btn-danger m-0" onClick={handleDlModel.bind(this, false)}><svg viewBox="0 0 24 24"><path
                                d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></button></span></h2>
                        </div>
                        <Modal.Body className="pb-3 pt-3 MuiDialogContent-dividers" style={{paddingTop:'0px !important'}}>
                            <section className="license-section driver-license-img-display">
                                <MapInteractionCSS showControls={true} minScale={0.15}>
                                    <img src={data.drivingLicence ? data.drivingLicence : require("./../../../../assets/images/notLicence.png").default} style={{maxHeight:'100%', maxWidth:'100%'}}/>
                                </MapInteractionCSS>
                            </section>
                        </Modal.Body>
                    </Modal>
                </div>
                {alertDeleteAccount && (
                    <div className="alert-message">
                        <Alert className="alert-success">
                            <Image src={require("./../../../../assets/images/success.svg").default} alt="Document" className="me-2"/>
                            Account deleted successfully
                        </Alert>
                    </div>
                )}
                {alertSuccess && (
                    <div className="alert-message">
                        <Alert className="alert-success">
                            <Image src={require("./../../../../assets/images/success.svg").default} alt="Document" className="me-2"/>
                            Licence Information updated successfully
                        </Alert>
                    </div>
                )}
                {alertProcess && (
                    <div className="alert-message">
                        <Alert className="alert-success">
                            <Image src={require("./../../../../assets/images/success.svg").default} alt="Document" className="me-2"/>
                            Order Processed successfully
                        </Alert>
                    </div>
                )}
                {alertInvalid && (
                    <div className="alert-message">
                        <Alert className="alert-danger">
                            <Image src={require("./../../../../assets/images/not-received.svg").default} alt="Document" className="me-2"/>
                            Please Enter a valid Information
                        </Alert>
                    </div>
                )}

                <div className="modal-dialog modal-xl">
                    <Modal show={isShippingCustomer} onHide={changeShippingCustomer} centered>
                        <Modal.Body className="text-center">
                            <h3>Add shipping for customer?</h3>
                            <div className="modal-btn">
                                <Button type="submit" className="btn btn-primary" onClick={changeIsShippingCustomer.bind(this, true)}>Yes</Button>
                                <Button type="submit" className="btn btn-danger" onClick={changeShippingCustomer}>No</Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>

                {/*process upaid order*/}
                <div className="modal-dialog modal-xl">
                    <Modal show={isProcessUnpaid} onHide={hideUnpaidProcessModel} centered>
                        <Modal.Body className="text-center">
                            <h3>{['Japan', 'South Korea', 'China'].includes(data.sendInvoice.ipLocation) ?
                                "This Order came in from Japan/SK/China. Would you still like to process it?":
                                "Are you sure you would like to process this order?"

                            } </h3>
                            <div className="modal-btn">
                                <Button type="submit" className="btn btn-primary" onClick={process}>Process</Button>
                                <Button type="submit" className="btn btn-danger" onClick={hideUnpaidProcessModel}>Cancel</Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>

                <div className="modal-dialog modal-xl">
                    <Modal show={reshipModel} onHide={false} centered>
                        <Modal.Body className="text-center">
                            <h3>{reshipModelMsg}</h3>
                            <Loader/>
                        </Modal.Body>
                    </Modal>
                </div>

                {isShippingAdd && (
                    <div className="alert-message">
                        <Alert className="alert-success">
                            <Image src={require("./../../../../assets/images/success.svg").default} alt="Document" className="me-2"/>
                            Add shipping for customer successfully
                        </Alert>
                    </div>
                )}

                {isShippingRemove && (
                    <div className="alert-message">
                        <Alert className="alert-success">
                            <Image src={require("./../../../../assets/images/success.svg").default} alt="Document" className="me-2"/>
                            Remove shipping for customer successfully
                        </Alert>
                    </div>
                )}

                {makeLabelBtnAlert && (
                    <div className="alert-message">
                        <Alert className="alert-success">
                            <Image src={require("./../../../../assets/images/success.svg").default} alt="Document" className="me-2"/>
                            Shipping entry successfully created.
                        </Alert>
                    </div>
                )}
                {shippedFileBtnAlert && (
                    <div className="alert-message">
                        <Alert className="alert-success">
                            <Image src={require("./../../../../assets/images/success.svg").default} alt="Document" className="me-2"/>
                            Shipped files uploaded successfully.
                        </Alert>
                    </div>
                )}
                {(returnedBtnAlert || sendIDBtnAlert) && (
                    <div className="alert-message">
                        <Alert className="alert-success">
                            <Image src={require("./../../../../assets/images/success.svg").default} alt="Document" className="me-2"/>
                            Mail sent successfully.
                        </Alert>
                    </div>
                )}

                {regenerateDocBtnAlert && (
                    <div className="alert-message">
                        <Alert className="alert-success">
                            <Image src={require("./../../../../assets/images/success.svg").default} alt="Document" className="me-2"/>
                            Document generated successfully.
                        </Alert>
                    </div>
                )}

                {reshippedBtnAlert && (
                    <div className="alert-message">
                        <Alert className="alert-success">
                            <Image src={require("./../../../../assets/images/success.svg").default} alt="Document" className="me-2"/>
                            Order reshipped successfully.
                        </Alert>
                    </div>
                )}
                {btnGroupError && (
                    <div className="alert-message">
                        <Alert className="alert-danger">
                            <Image src={require("./../../../../assets/images/not-received.svg").default} alt="Document" className="me-2"/>
                            {btnGroupErrorMsg}
                        </Alert>
                    </div>
                )}
            </Form>
            {show && (
                <TransactionHistoryModel show={show} handleClose={handleClose}/>
            )}
        </>
    );
};
export default UserDetails;

import React, { useEffect } from "react";

const InternalServerError = (props) => {
  return (
    <div className="page-notfound">
      <h3>500 Internal Server Error</h3>
      <p>Something went wrong</p>
      <a className="btn btn-primary" href="/login">
        Home Page
      </a>
      <p>{props.errorInfo}</p>
    </div>
  );
};

export default InternalServerError;

import React from 'react';
import { Link } from 'react-router-dom'
import { Row, Col, Container, Form, Button, Image } from "react-bootstrap"

  function Transactionhistory() {
      return (
        <>
          <Container>
                  <section className="processing-section">
                    <div className="processing-title">
                      <h3>Tracking</h3>
                      <p>Get the best possible delivery option.</p>
                    </div>
                    
                    <Row>
                      <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="processing-section-title">
                          <p>Tracking</p>
                          <div className="usps-tracking">
                            <span class="custom_radio">
                              <input type="radio" id="USPS1" name="Tracking" checked/>
                              <label for="USPS1"></label>
                            </span>
                            <Image src={require('./../../../assets/images/usps.svg').default} alt="usps"/>
                            <label className="courier-name">USPS First Class</label>
                            <span className="courier-price">$4.50</span>
                          </div>
                          <div className="usps-tracking">
                            <span class="custom_radio">
                              <input type="radio" id="USPS2" name="Tracking"/>
                              <label for="USPS2"></label>
                            </span>
                            <Image src={require('./../../../assets/images/usps.svg').default} alt="usps"/>
                            <label className="courier-name">USPS 2-Day</label>
                            <span className="courier-price">$8.75</span>
                          </div>
                          <div className="usps-tracking">
                            <span className="custom_radio">
                              <input type="radio" id="USPS3" name="Tracking"/>
                              <label for="USPS3"></label>
                            </span>
                            <Image src={require('./../../../assets/images/usps.svg').default} alt="usps"/>
                            <label className="courier-name">USPS Overnight</label>
                            <span className="courier-price">$24.95</span>
                          </div>
                        </div>
                        <div className="payment-section">
                          <p>Payment</p>
                          
                          
                          <div className="stripe-payment">
                            <Form>
                              <Row>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <Form.Group className="form-group">
                                    <Form.Label>Name of Card</Form.Label>
                                    <Form.Control type='name' name='firstname' placeholder="" ></Form.Control>
                                  </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <Form.Group className="form-group">
                                    <Form.Label>Card number</Form.Label>
                                    <Form.Control type='number' name='firstname' placeholder="1234-4567-8901-1234"></Form.Control>
                                  </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                                  <Form.Group className="form-group">
                                    <Form.Label>Expiration Date</Form.Label>
                                    <Form.Control type='text' name='firstname' placeholder="08/11" ></Form.Control>
                                  </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                                  <Form.Group className="form-group">
                                    <Form.Label>CVV</Form.Label>
                                    <Form.Control type='number' name='firstname' placeholder="811" ></Form.Control>
                                  </Form.Group>
                                </Col>
                                
                                
                              </Row>
                              <Button className="btn btn-priamry">Pay</Button>
                            </Form>
                          </div>
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="summary-section">
                          <p>SUMMARY</p>
                          <ul className="processing">
                            <li>Tracking </li>
                            <li>$4.50</li>
                          </ul>
                          <ul>
                            <li>Total</li>
                            <li>$4.50</li>
                          </ul>
                          <h6><Image src={require('./../../../assets/images/payment.svg').default} alt="Payment"/> Safe & Secure Payment</h6>
                        </div>
                        <Link to="/dashboard">
                          <Button type="Submit" className="btn btn-primary">Submit</Button>
                        </Link>
                      </Col>
                    </Row>
                  </section>
            </Container>
        </>
      );
  }

export default Transactionhistory;



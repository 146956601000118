import {Carousel} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../../route/services";
import {Document, Page, pdfjs} from 'react-pdf';
import Loader from "../loader/Loader";
import $ from "jquery";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function UserDocuments(props) {
    const userId = props.userID || localStorage.getItem("id");
    const title = props.title;
    const docFlag = props.docFlag;
    const fromAdmin = props.fromAdmin;
    const shippingBool = props.shippingBool;
    const regenerateDocs = useState(props.regenerateDocs);
    const [userDocuments, setUserDocuments] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [isDocument, setIsDocument] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [isProcess, setProcess] = useState(false);

    function getWindowDimensions() {
        const {innerWidth: width} = window;
        return {width};
    }

    function onDocumentLoadSuccess({numPages}) {
        setTimeout(function () {
            setIsDocument(true);
        }, 1000);
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        axios.get(`${API.getUserDocuments}${userId}/`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            mode: "no-cors",
        }).then((response) => {
            let result = response.data;
            let documentList = [];
            if (result.user_license_print_booklet !== null) {
                documentList.push({pdf: true, name: '_print_document', doc: result.user_license_print_booklet, id: "print"});
            }
            if (result.user_license_scanned_booklet !== null) {
                documentList.push({pdf: true, name: '_scanned_document', doc: result.user_license_scanned_booklet, id:"scanned"});
            }
            if (fromAdmin && result.user_license_card === null){
                documentList.push({pdf: true, name: '_id_card_dummy', doc: require("../../../assets/images/greyed_ID.pdf").default, id:"card"});
            } else if (fromAdmin || (docFlag === 3 && result.user_license_card !== null)) {
                documentList.push({pdf: true, name: '_id_card', doc: result.user_license_card, id:"card"});
            } else if([2,3].includes(docFlag) && !shippingBool) {
                documentList.push({pdf: true, name: '_id_card_dummy', doc: require("../../../assets/images/greyed_ID.pdf").default, id:"card"});
            }
            setUserDocuments(documentList);
        }).catch((error) => {
            console.log("getUserDocuments error:", error.response);
        });
    }, [docFlag]);

    const saveFile = (doc, name) => {
        if (!isProcess) {
            let fName = "";
            let lName = "";
            let url = "";
            let cursor = {pointerEvents: "auto"};
            setProcess(true);
            $(".carousel-control-prev, .carousel-control-next").css({pointerEvents: 'none'});
            axios.get(`${API.getUserDocuments}${userId}/`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            }).then((response) => {
                let result = response.data;
                fName = result.first_name.replaceAll(' ', '_');
                lName = result.last_name.replaceAll(' ', '_');
                if (doc === "print" && result.user_license_print_booklet !== null) {
                    url = result.user_license_print_booklet;
                }
                if (doc === "scanned" && result.user_license_scanned_booklet !== null) {
                    url = result.user_license_scanned_booklet;
                }
                if (doc === "card" && result.user_license_card !== null) {
                    url = result.user_license_card;
                }
                if (url !== "") {
                    axios.get(url, {responseType: "arraybuffer", mode: "no-cors",}).then((response) => {
                        let link = document.createElement("a");
                        link.href = window.URL.createObjectURL(
                            new Blob([response.data], {type: "application/octet-stream"})
                        );

                        link.download = fName + '_' + lName + name + '.pdf';
                        document.body.appendChild(link);
                        link.click();

                        setTimeout(function () {
                            window.URL.revokeObjectURL(link);
                        }, 200);
                        setProcess(false);
                        $('.carousel-control-prev, .carousel-control-next').css(cursor);
                    }).catch((error) => {
                        setProcess(false);
                        $('.carousel-control-prev, .carousel-control-next').css(cursor);
                        console.log("getUserDocuments Download error:", error.response);
                    });
                }
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    window.location.href="/login";
                } else {
                    setProcess(false);
                    $('.carousel-control-prev, .carousel-control-next').css(cursor);
                    console.log("getUserDocuments error:", error.response);
                }
            });
        }
    };

    function getPageScalePhoto(width) {
        let scale = 1;
        if(width > 1700){
            scale = 2
        }else if(width > 1300){
            scale = 1.5;
        }else if(width > 1200){
            scale = 1.3;
        }else if(width > 992){
            scale = 1;
        }else if(width > 580){
            scale = 2
        }
        return scale;
    }

    function getPageScalePdf(width) {
        let scale = 1;
        if(width > 1700){
            scale = 1
        }else if(width > 1300){
            scale = 0.85;
        }else if(width > 1200){
            scale = 0.75;
        }else if(width > 992){
            scale = 0.65;
        }else if(width > 580){
            scale = 0.55
        }
        return scale;
    }

    return (
        <section className="document-section">
            <div className="personal-info-section">
                <h3>{title}</h3>
                <Carousel className="doc-slider" indicators={false} wrap={true} controls={userDocuments.length > 1} interval={100000}>
                    {
                        (fromAdmin && userDocuments.length > 0 && docFlag in [0,1] &&
                            <div style={{left: "50%", top: "48%", textAlign:'center'}}>
                                <Loader/>
                                <p style={{color: "#004a58"}}>Regenerating...<br/> It may take some time</p>
                            </div>
                        ) ||

                        (fromAdmin && userDocuments.length === 0 &&
                            <div style={{textAlign:'center'}}>
                                <p style={{color: "#004a58"}}>Documents are not generated yet.</p>
                            </div>
                        ) ||

                        (userDocuments.length > 0 && userDocuments.map((row, i) => (
                            <Carousel.Item key={i.toString()}>
                                <div className="inline-carousel">
                                    <div className="pdf-one" style={{position: 'relative', opacity: isProcess ? "0.5" : "1", pointerEvents: isProcess ? 'none' : 'auto'}}>
                                        {row.name === "_id_card_dummy" &&
                                            <h3 style={{color: "#212529", marginBottom:"15px !important"}}>
                                                {!fromAdmin ? "Add Shipping to get your ID Card": ""}
                                            </h3>
                                        }
                                        <div className={row.name === "_print_document" ? "pdf-view print_document"
                                            : row.name === "_scanned_document" ? "scanned_document pdf-view"
                                            : row.name === "_id_card_dummy" ? "id_card_dummy pdf-view"
                                            : "pdf-view"}
                                        >
                                            <Document loading="Loading document" error="Failed to load document" onLoadSuccess={onDocumentLoadSuccess} file={row.doc}>
                                                <Page onClick={row.name === "_id_card_dummy" ? () => {} : saveFile.bind(this, row.id, row.name)} scale={row.name === '_id_card' ? getPageScalePhoto(windowDimensions.width) : getPageScalePdf(windowDimensions.width)}
                                                      pageNumber={pageNumber}/>
                                            </Document>
                                        </div>
                                        {isDocument && row.name !== "_id_card_dummy" &&
                                        <button className="btn btn-warning" disabled={isProcess} style={{margin: row.name === "_id_card" ?  "15px 0" : "10px 0"}} onClick={saveFile.bind(this, row.id, row.name)}>Download</button>
                                        }
                                        {isDocument && row.name === "_id_card_dummy" && !fromAdmin &&
                                            <button className="btn btn-warning" disabled={isProcess}
                                                    style={{margin: (row.name === "_id_card" || row.name === "_id_card_dummy") ?  "15px 0" : "10px 0"}}
                                                    onClick={() => {window.location.href = "/user/documentshipping"}}
                                            >Add Shipping</button>
                                        }
                                        { isDocument && row.name === "_id_card_dummy" && fromAdmin &&
                                            <h3 style={{color: "#96989b", marginBottom:"15px !important"}}>
                                                ID Card not available
                                            </h3>
                                        }
                                    </div>
                                    {isProcess &&
                                    <div className="loader-dashboard" style={{left: "50%", top: "48%"}}>
                                        <Loader/>
                                        <p style={{color: "#004a58"}}>Downloading...</p>
                                    </div>
                                    }
                                </div>
                            </Carousel.Item>
                            ))
                        )
                    }
                </Carousel>
            </div>
        </section>
    );
}

export default UserDocuments;
import { Link, useHistory } from "react-router-dom";
import { Button, Image } from "react-bootstrap";

function IFShippedInternationalyUSPS(props) {
  const userId = localStorage.getItem("id");
  const url = process.env.REACT_APP_SERVER_URL;
  const history = useHistory();

  async function submitHandler(e) {
    history.push({ pathname: "/user/upgradeshipping", state: userId });
  }
  return (
    <>
      <section
        className="delivery-section sc-mt"
        id="ShippingInternationally"
        // style={{ display: "none" }}
      >
        {/*<h3>IF Shipping to Internationally and USPS</h3>*/}
        <div className="Shipping-document">
          <p className="text-white mb-0">Your order has been shipped</p>
          {props.trackingId !== "" &&
          <h3 className="text-white">
            Your Tracking Number is{" "}
            <span className="text-warning">
              {" "}
              {props.trackingId || "-------"}
            </span>
          </h3>
          }
          <div className="d-block d-md-block d-lg-flex align-items-center justify-content-between w-75 m-auto">
            <Image
              className="deliver-document w-25"
              src={
                require("./../../../../../assets/images/internationally.png")
                  .default
              }
              alt="USPS"
            />
            <h3 className="text-white pe-3">
              It's not too late to upgrade to DHL to ensure document arrival.
            </h3>
          </div>
          {/* <Link to="/ordertrack"> */}
          {!props.isShipped &&
          <Button type="button" className="btn btn-warning" onClick={submitHandler}> Upgrade Tracking </Button>
          }
          {/* </Link> */}
        </div>
      </section>
    </>
  );
  // return (
  //   <>
  //     <section
  //       className="delivery-section"
  //       id="ShippedInternationalyUSPS"
  //       // style={{ display: "none" }}
  //     >
  //       <h3>IF Shipped Internationaly and USPS</h3>
  //       <div className="Shipping-document">
  //         <p className="text-white mb-0">Thank you for your patience.</p>
  //         <h3 className="text-white">
  //           Your order should arrive by{" "}
  //           <span className="text-warning">July 23, 2021</span>
  //         </h3>
  //         <Image
  //           className="deliver-document"
  //           src={
  //             require("./../../../../../assets/images/internationally.png")
  //               .default
  //           }
  //           alt="USPS"
  //         />
  //         <Link to="" className="text-white text-decoration-none">
  //           Your address has been submitted for review.
  //         </Link>
  //       </div>
  //     </section>
  //   </>
  // );
}

export default IFShippedInternationalyUSPS;


import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import {directAccessGuard} from "../App";

const ProtectedRoute = ({ component: Component, guards: Guards, ...rest }) => {
    const authentication = localStorage.getItem("id");
    const isAdmin = localStorage.getItem("isAdmin");
    return (<
        Route {...rest}
        render={
            props => {
                if (authentication && !isAdmin) {
                    return directAccessGuard(
                        Guards,
                        <Component {...rest} {...props}/>
                    )
                } else {
                    return <Redirect to={
                        {
                            pathname: '/page-not-found',
                            state: {
                                from: props.location
                            },
                        }
                    }
                    />
                }
            }
        }
    />
    )
}

export default ProtectedRoute;
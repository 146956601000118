import React, { useState, useEffect, useContext } from "react";
import { Form, Image, Alert } from "react-bootstrap";
import useForm from "./useFormlogin";
import validate from "./validateinfo";
import {Redirect, useHistory} from "react-router-dom";
import axios from "axios";
import Loader from "../loader/Loader";
import $ from "jquery";
import AuthContext from "../../../store/auth-context";
import { API } from "../../../route/services";
import {directAccessGuard} from "../../../App"

const path = window.location.pathname;
const page = path.split("admin/login").pop();
$("#hidediv").addClass("d-block");

if (path === "/admin/login") {
  $("#hidediv").addClass("d-none");
}

const Login = ({ submitForm, location, ...rest }) => {

  const history = useHistory();
  const context = useContext(AuthContext);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isTwoFa, setTwoFa] = useState(false);
  const [otp, setOtp] = useState("");
  const [isLogin, handleLogin] = useState(false);
  const [showError, handleError] = useState(false);
  const [isDisabled, handleTwoFaBtn] = useState(false);
  const redirected = (location.state && location.state.redirected) || false;


  const { handleChange, handleSubmit, values, errors } = useForm(
    submitForm,
    validate
  );

  const [alert, setAlert] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
    }, 9000);
  });

  function handleOtp (e) {
    let name = e.target.name;
    let value = e.target.value;
    let number = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if (name === 'otp' || name === 'validateOTP') {
      let character = value.charAt(value.length - 1);
      if (number.includes(character) || value.length === 0) {
        setOtp(value)
      }
    } else {
      setOtp(value)
    }
    handleError(false);
  }

  function verifyTwoFA() {
    if (otp === "" || otp === undefined || (otp.length !== 6 && otp.length !== 8)) {
      handleError(true);
    } else {
      handleTwoFaBtn(true);
      handleError(false);
      setError("");
      setLoading(true);
      //call two-fa verification api for verify otp and redirect to the admin dashboard
      submitHandler({validateOTP: true});
    }
  }


  async function submitHandler(params) {
    if (!Object.keys(validate(values)).length) {
      setError("");
      setLoading(true);

      let data = {
        username: values.username,
        password: values.password,
      };
      if (params.validateOTP) {
        if (otp.length === 6){
          data.otp = otp
        }else if (otp.length === 8)
        {
          data.backup_code = otp
        }
      }

      await axios
          .post(
              API.login,
              data,
              { timeout: 20000 },
              {
                headers: {
                  "access-control-allow-origin": "*",
                  "Content-Type": "application/json",
                },
                mode: "no-cors",
              }
          )
          .then(async (response) => {
            setError("");
            localStorage.setItem("token", response.data.access_tokens);
            let redirect_pathname = "/user/dashboard";
            context.onLogin();
            setAlert(true);

            setTimeout(() => {
              const token = response.data.access_token;
              setLoading(false);
              if (!response.data.two_factor_enable) {
                if (response.data.is_admin === 1) {
                  if (response.data.role !== undefined) {
                    localStorage.setItem("role", response.data.role);
                  }
                  localStorage.setItem("isAdmin", 1);
                  localStorage.setItem("id", response.data.id);

                  history.push({pathname: "/admin/order"});

                } else if (response.data.is_admin === 0) {
                  localStorage.setItem("id", response.data.id);

                  if (location.state && location.state.redirected) {
                    redirect_pathname = directAccessGuard(location.state.guards, location.state.from.pathname)
                  }
                  history.push({pathname: redirect_pathname, state: {redirected: redirected}});

                }
              } else {
                if (response.data.has_two_factor_key) {
                  handleLogin(true);
                } else {
                  history.push({pathname: "/twofa-setup", token: token});
                }
              }
            }, 1000);
          })

          .catch((error) => {
            setLoading(false);
            console.log("login error:", error);
            handleError(true);
            handleTwoFaBtn(false);
            setError(error.response.data.detail);
          });
    }
  }

  return (
    <div className="form-wrapper">
      {!isLogin &&
      <div className="login-form">
        <Image
            src={require("./../../../assets/images/logo.png").default}
            alt="Eita-app"
            className="w-auto p-4"
        />
        <div className="login-page">
          <Form onSubmit={handleSubmit} className="form" noValidate>
            <h3>Sign In</h3>
            <p>Sign into your account</p>
            <Form.Group className="form-group">
              <Form.Control
                  type="username"
                  name="username"
                  placeholder="Enter username"
                  value={values.username}
                  onChange={handleChange}
              ></Form.Control>
              {errors.username && <p>{errors.username}</p>}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Control
                  type="password"
                  name="password"
                  placeholder="Enter password"
                  value={values.password}
                  onChange={handleChange}
              ></Form.Control>
              {error && <p>{error}</p>}
              {errors.password && <p>{errors.password}</p>}
            </Form.Group>

            {path === "/admin/login" ? (
              <div />
            ) : (
                <div id="hidediv">
                  <div className="d-block d-md-flex d-lg-flex align-items-center justify-content-between">
                    <p>
                      {" "}
                      <input
                          type="checkbox"
                          value="lsRememberMe"
                          id="rememberMe"
                      />{" "}
                      <label for="rememberMe">Remember me</label>
                    </p>
                    <a href="forgotpassword">Forgot your password?</a>
                  </div>
                  <p className="account-register">
                    Don’t have an account? <a href="https://e-ita.org/international-drivers-license-apply-online/">Apply here</a>{" "}
                  </p>
                </div>
            )}

            {alert && (
                <div className="alert-message">
                  <Alert className="alert-success">
                    <Image
                        src={
                          require("./../../../assets/images/success.svg").default
                        }
                        alt="Document"
                        className="me-2"
                    />
                    Login successfully
                  </Alert>
                </div>
            )}
            {loading && <Loader/>}
            {!loading && (
                <button
                    className="btn btn-primary mt-3 mb-1"
                    type="submit"
                    onClick={submitHandler}
                >
                  {" "}
                  Sign into your account{" "}
                </button>
            )}
          </Form>
        </div>
      </div>
      }
      {isLogin &&
      <div className="login-form">
        <Image
            src={require("./../../../assets/images/logo.png").default}
            alt="Eita-app"
            className="w-auto p-4"
        />
        <div className="login-page">
          <div className="two-fa-otp">
            <Form onSubmit={handleSubmit} className="form" noValidate>
              <h3>Validate Code</h3>
              <Form.Group className="form-group" style={{width: '374px', height: '70px'}}>
                <Form.Control
                    type="otp"
                    name="otp"
                    placeholder="MFA/Backup Code*"
                    className="two-fa-otp-input"
                    maxLength="8"
                    value={otp}
                    onChange={handleOtp}
                ></Form.Control>
                <p>{
                  showError && otp === "" ? "MFA/Backup Code is required"
                      : showError && (otp.length !== 6 && otp.length !== 8) ? 'MFA/Backup Code is minimum 6 or 8 digit'
                      : showError && error ? error
                      : " "}</p>
              </Form.Group>
              {loading && <Loader/>}
              {!loading && (
                  <button className="btn btn-primary mt-3 mb-1" disabled={isDisabled} onClick={verifyTwoFA}>
                    {" "}
                    Validate Code{" "}
                  </button>
              )}
            </Form>
          </div>
        </div>
      </div>
      }
    </div>
  );
};

export default Login;

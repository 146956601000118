import { Image, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import {useEffect, useState} from "react";

function UpgradeTrackingDHL() {
  const userId = localStorage.getItem("id");
  const url = process.env.REACT_APP_SERVER_URL;
  const history = useHistory();
  const [hourDiff, setHour] = useState("");
  const [minuteDiff, setMinute] = useState("");

  async function submitHandler(e) {
    history.push({ pathname: "/user/upgradeshipping", state: userId });
  }

  useEffect(() => {
    getTimercount();
    setInterval(() => {
      getTimercount();
    },5000);
  }, []);

  function getTimercount(){
    let currentDate = moment().tz("America/Los_Angeles");
    let nextDate = currentDate.clone().set({hours:4, minute:0, second:59}).add(1, 'day');
    let difference = moment.duration(nextDate.diff(currentDate));
    setHour(difference.hours());
    setMinute(difference.minutes());
  };

  return (
    <>
      <section
        className="delivery-section sc-mt"
        id="UpgradeTrackingDHL"
        // style={{ display: "none" }}
      >
        <div className="Shipping-document">
          <p className="text-white mb-0">
            Your order will be shipped in <span>{hourDiff ? `${hourDiff} hours and` : ''} {minuteDiff} mins.</span>
          </p>
          <h3 className="text-white">
            We highly recommend FedEx to ensure document arrival.
          </h3>
          <Image
            className="deliver-document"
            src={require("./../../../../../assets/images/fedex_box.png").default}
            alt="FedEx"
          />
          <Button
            type="button"
            className="btn btn-warning"
            id="UpgradeTrackingDHLbtn"
            onClick={submitHandler}
          >
            Upgrade Tracking
          </Button>
        </div>
      </section>
    </>
  );
}

export default UpgradeTrackingDHL;

import React, {Component, useState} from "react";
import QRCode from "react-qr-code";
import {Form, Image, Alert, Modal} from "react-bootstrap";
import useForm from "./useFormlogin";
import validate from "./validateinfo";
import {Redirect, useHistory} from "react-router-dom";
import axios from "axios";
import Loader from "../loader/Loader";
import $ from "jquery";
import AuthContext from "../../../store/auth-context";
import { API } from "../../../route/services";


class TwoFASetup extends Component {

    constructor(props) {
        super();

        this.state = {
            publicKey: "",
            qrCode: "",
            otp: "",
            showError: false,
            errorMsg: "",
            isDisabled: false,
            timeoutModel: false,
            isLoading: false,
            isBackupCode: false,
            token: props.location.token,
            backupCodeList: [],
        };

    }

    componentDidMount = () => {
        setTimeout(() =>
            this.setState({timeoutModel: true})
        ,150000);
        this.getQRCode()
    };

    getQRCode = async () => {


        axios.get(`${API.twoFactorSetup}/`, {
            headers: {
                "Content-Type": "application/json",
                // Authorization: `Bearer ${localStorage.getItem("token")}`,
                Authorization: `Bearer ${this.state.token}`,
            },
            mode: "no-cors",
        }).then((response) => {
            this.setState({publicKey: response.data.public_key});
            this.setState({qrCode: response.data.qr_code});
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                this.setState({showError: true, errorMsg: "Timeout!. Please login again"})
                this.setState({isLoading: false, isDisabled: true});
                localStorage.clear();
                setTimeout(() => {
                    window.location.href="/login";
                }, this.state.token ? 2000 : 0);
            }
            console.log("getUserDocuments error:", error.response);
        });
       //call api for get qr code url and set in to the state
    };

    handleContinue = () => {
        localStorage.clear();
        window.location.href="/login";
    };

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let number = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        if (name === 'otp' || name === 'validateOTP') {
            let character = value.charAt(value.length - 1);
            if (number.includes(character) || value.length === 0) {
                this.setState({[name]: value})
            }
        } else {
            this.setState({[name]: value})
        }
    };

    handleTwoFa = (e) => {
        e.preventDefault();
        if (this.state.otp === "" || this.state.otp.length !== 6) {
            this.setState({showError: true});
        } else {
            this.setState({showError: false, isDisabled: true, isLoading: true});
            let data = {
                'public_key': this.state.publicKey,
                'otp': this.state.otp
            };
            //call verify otp api and set this(  this.setState({backupCodeList: res.backup_codes});  this.setState({isBackupCode: true, isLoading: false, isDisabled: false});)

            axios.post(`${API.twoFactorEnable}/`,
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.state.token}`,
                    },
                    mode: "no-cors"
                }).then((res) => {
                    this.setState({backupCodeList: res.data.backup_codes});
                    setTimeout(() => {
                        this.setState({isBackupCode: true, isLoading: false, isDisabled: false});
                    }, 2000);
                }).catch((error) => {
                    if (error.response && error.response.status === 401) {
                        this.setState({showError: true, errorMsg: "Timeout!. Please login again"})
                        this.setState({isLoading: false, isDisabled: true});
                        localStorage.clear();
                        setTimeout(() => {
                            window.location.href="/login";
                        }, 1500);
                    } else if (error.response.status === 500 || error.response.status === 400 || error.response.status === 422) {
                        setTimeout(() => {
                            this.setState({showError: true, errorMsg: error.response.data.detail || "Timeout!. Please login again"})
                            this.setState({isLoading: false, isDisabled: false});
                        }, 500);
                    }
                });
        }
    };

    handleBack = () => {
      /*  if (Cookie.get("user_type") === "admin") {
            Helper.redirectPage('/merchant/list', 0);
        } else {
            Helper.redirectPage('/dashboard', 0);
        }*/
    };

    render() {
        const {qrCode, otp, showError, isLoading, isDisabled, isBackupCode, backupCodeList, errorMsg, timeoutModel} = this.state;
        const {classes} = this.props;

        return (
            <div className="form-wrapper two-fa-setup">
                <div className="login-form">
                    <Image
                        src={require("./../../../assets/images/logo.png").default}
                        alt="Eita-app"
                        className="w-auto p-4"
                    />
                    <div className="login-page">
                        {!isLoading &&
                        <div className="text-center mb-4">
                            <h4>{!isBackupCode ? 'Setup Two Factor Authentication' : 'Backup Code'}</h4>
                        </div>
                        }
                        {!isLoading && !isBackupCode &&
                        <div>
                            <div>
                                <QRCode value={qrCode}/>
                            </div>
                            <div className="two-fa-otp mt-1">
                                <form className="form">
                                    <Form.Group className="form-group mb-0" style={{height: '80px'}}>
                                        <Form.Control
                                            type="text"
                                            name="otp"
                                            placeholder="MFA Code*"
                                            className="two-fa-otp-input"
                                            maxLength={6}
                                            value={otp}
                                            onChange={this.handleChange}
                                        ></Form.Control>
                                        <p>{
                                            showError && otp === "" ? "MFA Code is required"
                                                : showError && otp.length !== 6 ? 'MFA Code is minimum 6 digit'
                                                : showError && errorMsg ? errorMsg
                                                : " "
                                        }</p>
                                    </Form.Group>
                                </form>
                            </div>
                            <div>
                                <button className="btn btn-primary mt-3 mb-1" disabled={isDisabled} onClick={this.handleTwoFa}>
                                    Validate Code
                                </button>
                            </div>
                        </div>
                        }
                        {!isLoading && isBackupCode &&
                            <div>
                                <div>
                                    <p>Backup codes are used to access your account when you are not able to receive two-factor authentication. For security reason each code can be used only one time.
                                        So keep these codes somewhere safe but accessible.</p>
                                </div>
                                <div className="mt-3">
                                    {backupCodeList.map((row, index) => (
                                        <p>{row}</p>
                                    ))}
                                </div>
                                <div>
                                    <button className="btn btn-primary mt-3 mb-1" disabled={isDisabled}
                                            onClick={this.handleContinue}>
                                        Continue
                                    </button>
                                </div>
                            </div>
                        }
                        {isLoading && <Loader />}
                    </div>
                </div>
                <div className="modal-dialog modal-xl">
                    <Modal show={timeoutModel} onHide={false} centered>
                        <Modal.Body className="text-center">
                            <p>
                                Timeout! Please Login again.
                            </p>
                            <div className="modal-btn">
                                <button type="submit" className="btn btn-primary"
                                        onClick={() => {
                                            localStorage.clear();
                                            window.location.href="/login";
                                        }}>OK</button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default TwoFASetup;

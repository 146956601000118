import React, { useEffect } from "react";
import AdminSidebar from "./../../adminsidebar/AdminSidebar";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import Datatable from "./datatable/Datatable";
import axios from "axios";
import Loader from "./../loader/Loader";
import UserDetails from "./userdetail/UserDetails";
import { API } from "../../../route/services";
import { Link } from "react-router-dom";
//country list from backend
import {CountryList} from "../../../common/country-list";

import $ from "jquery";

class AdminOrder extends React.Component {
  //api calling

  constructor(props) {
    super(props);
    this.state = {
      isSaved: false,
      isReloadDT: false,
      orderId: localStorage.getItem("orderId"),
      data: {
        personalInformation: {
          firstname: "",
          lastname: "",
          email: "",
          dob: "",
          placeofbirth: "",
          placeofbirthother: "",
        },
        driverInformation: {
          country: "",
          countryOther: "",
          licenceNumber: "",
          A: false,
          B: false,
          C: false,
          D: false,
          all: false,
        },
        shippingInformation: {
          address: "india",
          address2: "132456",
          city: "",
          state: "",
          country: "",
          countryOther: "",
          zipcode: "",
          phoneNumber: "",
          tracking: "",
          shipdate: "",
          shipCheck: false,
          shipOption: "DHL",
        },
        sendInvoice: {
          validity: "",
          phonenumber: "",
          ipLocation: "",
          address: "",
          docType: "",
          checkIssueDate: false,
          checkAddress: false,
        },
        urgent: false,
      },
      loader: false,
      drivingLicence: ""
    };
  }

  reloadHandler = () => {
    this.setState({
      isReloadDT: true,
    });
  };

  reloadOff = () => {
    this.setState({
      isReloadDT: false,
    });
  };

  componentDidMount() {
    if (localStorage.getItem("orderId") && !isNaN(parseInt(localStorage.getItem("orderId")))) {
      this.setState({orderId: parseInt(localStorage.getItem("orderId"))})
    }
  }

  componentWillMount = async () => {
    CountryList();
    if (localStorage.getItem("orderId") && !isNaN(parseInt(localStorage.getItem("orderId")))) {
      this.dataClickChangehandler(parseInt(localStorage.getItem("orderId")));
    } else if (this.state.orderId) {
      this.dataClickChangehandler(this.state.orderId);
    }
  };

  dataClickChangehandler = (id) => {
    this.setState({
      userId: null,
    });
    this.setState({
      orderId: id,
      loader: true,
    });
    axios
      .get(`${API.adminOrderDetail}/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        mode: "no-cors",
      })
      .then((response) => {
        this.setState({
          loader: false,
          data: {
            userId: response.data.user_id,
            orderStatus: response.data.order_status,
            personalInformation: {
              firstname: response.data.first_name,
              lastname: response.data.last_name,
              email: response.data.email,
              dob: response.data.date_of_birth,
              placeofbirth: response.data.place_of_birth,
              placeofbirthother: response.data.place_of_birth_other,
              isMailEnabled: response.data.is_mail_enabled
            },
            driverInformation: {
              country: response.data.driver_license_country,
              countryOther: response.data.driver_license_country_other,
              licenceNumber: response.data.driver_license_no,
              showDLNumber: response.data.showDLNumber,
              A: response.data.vehicle_Motorcycle,
              B: response.data.vehicle_Car,
              C: response.data.vehicle_Semi_Truck,
              D: response.data.vehicle_bus,
              all: response.data.Others_and_trailers,
            },
            shippingInformation: {
              address: response.data.shipping_address,
              address2: response.data.shipping_address2,
              city: response.data.shipping_city,
              state: response.data.shipping_state,
              country: response.data.shipping_country,
              countryOther: response.data.shipping_country_other,
              zipcode: response.data.shipping_zipcode,
              phoneNumber: response.data.phone_number,
              tracking: response.data.track_id,
              shipdate: response.data.date_shipped,
              // shipCheck: response.data.shipped_bool,
              shipCheck: response.data.shipping_bool,
              shipOption: response.data.shipping_option,
            },

            sendInvoice: {
              validity: response.data.validity,
              issueDate: response.data.issue_date,
              ipLocation: response.data.user_ip_location,
              address: response.data.address_on_license,
              docType: response.data.docType,
              checkIssueDate: response.data.checkIssueDate,
              checkAddress: response.data.checkAddress,
              loader: false,
            },
            urgent: response.data.urgent,
            drivingLicence: response.data.driver_license_photo,
            planType: response.data.plan_type
          },
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          window.location.href="/login";
        }
        console.log("axios error:", error.response);
      });
  };

  savedHandler() {
    this.setState({ isSaved: true });
  }

  toggleMenu = () =>{
    $(".sidebar").toggleClass("sidebarshow");
    $(".dashboard-data").toggleClass("dashboard-data-show");
  };

  render() {
    return (
      <>
        <AdminSidebar />
        <div className="dashboard-data admin-wrapper">
          <div className="document-section d-flex align-items-center justify-content-between pt-2 pb-2 ps-3 pe-3">
            <h3 className="p-0 m-0">Admin Order</h3>
            <p className="menuicon mb-0" onClick={this.toggleMenu}>
              <Image
                src={require("../../../assets/images/menu.svg").default}
                alt="menu"
                className="d-block d-md-none d-lg-none"
              />
            </p>
          </div>
          <Container fluid>
            <Row>
              <Col className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                {
                  <Datatable
                    dataChangehandler={this.dataClickChangehandler}
                    isReload={this.state.isReloadDT}
                    reloadOff={this.reloadOff}
                    orderId={this.state.orderId}
                  />
                }
              </Col>
              <Col className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 position-relative">
                <Form className="admin-from" noValidate>
                  {this.state.loader && (
                    <div className="admin-loader">
                      <Loader />
                    </div>
                  )}
                  {!this.state.loader && this.state.orderId && (
                    <UserDetails
                      data={this.state.data}
                      isSaved={this.state.isSaved}
                      reloadHandler={this.reloadHandler}
                    />
                  )}
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default AdminOrder;

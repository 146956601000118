import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

function IFShippingInternationallyDHL(props) {
  return (
    <>
      <section
        className="delivery-section sc-mt"
        id="ShippingInternationallyDHL"
        // style={{ display: "none" }}
      >
        {/*<h3>IF Shipping Internationally and DHL</h3>*/}
        <div className="Shipping-document">
          <p className="text-white mb-0">Your order has been shipped</p>
          {props.trackingId !== "" &&
          <h3 className="text-white">
              Your Tracking Number is <span className="text-warning">{props.trackingId || "------_"}</span>
          </h3>
          }
          <Image
            className="deliver-document"
            src={require("./../../../../../assets/images/dhl.png").default}
            alt="DHL"
          />
          <a href="https://www.dhl.com/us-en/home/tracking.html" className="text-white text-decoration-none">
            Use DHL to track
          </a>
        </div>
      </section>
    </>
  );
}

export default IFShippingInternationallyDHL;

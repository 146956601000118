import UserDocuments from "../../../userdocument/userdocument";

function DocumentShipped(props) {
    return (
        <UserDocuments title="Digital Documents"
                       docFlag={props.docFlag}
                       userID={props.userID}
                       fromAdmin={props.fromAdmin || false}
                       shippingBool={props.shippingBool}
                       regenerateDocs={props.regenerateDocs}
        />
    );
}

export default DocumentShipped;

import React from "react";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
//CSS
import "./assets/css/style.css";
import "./assets/js/custom.js";
import "bootstrap/dist/css/bootstrap.min.css";

//Components & pages
import UserLogin from "./user/component/userlogin/Login";
import UserForgotpassword from "./user/component/userforgotpassword/UserForgotpassword";
import Resetpassword from "./user/component/resetpassword/Resetpassword";
import Signup from "./user/component/signup/Signup";
import UserDashboard from "./user/component/userdashboard/UserDashboard";
import Transactionhistory from "./user/component/transactionhistory/Transactionhistory";
import Setting from "./user/component/setting/Setting";
import Urgentprocessing from "./user/component/urgentprocessing/Urgentprocessing";
import Urgentprocessingship from "./user/component/urgentprocessing/Urgentprocessingship";
import Tracking from "./user/component/tracking/Tracking";
import Verificationemail from "./user/component/verificationemail/Verificationemail";
import Addshipping from "./user/component/addshipping/Addshipping";
import Documentshipping from "./user/component/addshipping/Documentshipping";
import Ordertrack from "./user/component/ordertrack/Ordertrack";
import AdminOrder from "./admin/component/adminorder/AdminOrder";
import AdminShipping from "./admin/component/adminshipping/AdminShipping";
import AdminReturns from "./admin/component/adminreturns/AdminReturns";
import AdminRefunds from "./admin/component/adminrefunds/AdminRefunds";
import PageNotFound from "./user/pages/PageNotFound";
import Upgradeshipping from "./user/component/upgradeshipping/upgradeshipping";
import InternalServerError from "../src/user/pages/InternalServerError";
import ErrorBoundary from "./user/pages/ErrorBoundary";
import ReShip from "./user/component/reship/reship";
import ReshipAtDiscount from "./user/component/reship/reshipatdiscount";
import ReshipDocument from "./user/component/reship/reshipdocument";
import ReShipping from "./user/component/reship/reshipping";
import PlanRenewal from "./user/component/planrenewal/PlanRenewal";
import PlanRenewDigital from "./user/component/planrenewal/planrenew-digital/PlanRenewDigital";
import PlanRenewPrint from "./user/component/planrenewal/planrenew-print/PlanRenewPrint";
import Unpaidorder from "./user/component/unpaidorder/unpaidorder";
import Unsubscribe from "./user/component/unsubscribeuser/Unsubscribe";

//Authentication
import ProtectedRoute from "./route/protectedRoute";
import ProtectedRedirectRoute from "./route/protectedRedirectRoute";
import PublicRoute from "./route/publicRoute";
import AdminRoute from "./route/adminRoute";

import $ from "jquery";
import TwoFASetup from "./user/component/userlogin/twofa-setup";
import axios from "axios";
import {API} from "./route/services";

// $(document).ready(function() {
//   $(".menuicon").click(function() {
//     $(".sidebar").toggleClass("sidebarshow");
//     $(".dashboard-data").toggleClass("dashboard-data-show");
//   });
// });

require("dotenv").config();

const SHIPPINGCARD = 1

// *** Guards *** to prevent direct access to the form and redirect to the appropriate correct form.
// will only work with ProtectedRedirectRoute and ProtectedRoute routers

//based on shipping card
const G_UNPAID = {checkFor: SHIPPINGCARD, shippingCard: 30, redirectToPath: "/user/unpaidorder"}
const G_PLANRENEWAL = {checkFor: SHIPPINGCARD, shippingCard: 22, redirectToPath: "/user/planrenewal"}
const G_SHIPPING = {checkFor: SHIPPINGCARD, shippingCard: 1, redirectToPath: "/user/documentshipping"}

// userShippingCardBanner API is used for validation
function directAccessGuard(guards, defaultReturn) {

  if(!guards)
    return defaultReturn

  const userId = localStorage.getItem("id");
  let guardOn = 0

  axios.get(`${API.userShippingCardBanner}/${userId}/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    mode: "no-cors",
  }).then((response) => {
    guards.forEach((g) => {
          if (g.checkFor === SHIPPINGCARD && response.data.shipping_card === g.shippingCard) {
            window.location.href = g.redirectToPath
            guardOn = 1
          }
        }
    )
  }).catch((error) => {
    console.log("shipping card error:", error.response);
  });

  return guardOn ||  defaultReturn
}


const App = () => {
  return (
    <div className="App">
      <Router>
        <ErrorBoundary>
          <Switch>
            <Route exact path="/signup" component={Signup}/>
            <Route exact path="/forgotpassword" component={UserForgotpassword}/>
            <Route exact path="/verification-email" component={Verificationemail}/>
            <Route path="/reset/password" component={Resetpassword}/>
            <Route path="/twofa-setup" component={TwoFASetup}/>

            <Route path="/user/unsubscribe" component={Unsubscribe}/>

            <PublicRoute exact path="/login" component={UserLogin} />

            <Route exact path="/">
              <Redirect to="/login" />
            </Route>

            <ProtectedRoute
              exact
              path="/user/dashboard"
              component={UserDashboard}
            />
            <ProtectedRoute
              exact
              path="/user/transactionhistory"
              component={Transactionhistory}
            />
            <ProtectedRoute exact path="/user/setting" component={Setting} />
            <ProtectedRoute
              exact
              path="/user/urgentprocessing"
              component={Urgentprocessing}
              guards={[G_UNPAID]}
            />
            <ProtectedRoute
                exact
                path="/user/urgentprocessingship"
                component={Urgentprocessingship}
                guards={[G_UNPAID]}
            />
            <ProtectedRoute
                exact
                path="/user/tracking"
                component={Tracking}
                guards={[G_UNPAID]}
            />
            <ProtectedRoute
              exact
              path="/user/addshipping"
              component={Addshipping}
              guards={[G_UNPAID]}
            />
            <ProtectedRoute
                exact
                path="/user/documentshipping"
                component={Documentshipping}
                guards={[G_UNPAID]}
            />
            <ProtectedRoute
              exact
              path="/user/planrenewal"
              component={PlanRenewal}
              guards={[G_UNPAID]}
            />
            <ProtectedRoute
              exact
              path="/user/planrenewal/digital"
              component={PlanRenewDigital}
              guards={[G_UNPAID]}
            />
            <ProtectedRoute
              exact
              path="/user/planrenewal/print"
              component={PlanRenewPrint}
              guards={[G_UNPAID]}
            />
            <ProtectedRoute
                exact
                path="/user/upgradeshipping"
                component={Upgradeshipping}
                guards={[G_UNPAID]}
            />
            <ProtectedRedirectRoute
                exact
                path="/user/renewtoday"
                component={PlanRenewal}
                guards={[G_UNPAID, G_PLANRENEWAL]}
            />
            <ProtectedRedirectRoute
                exact
                path="/user/upgradetracking"
                component={Upgradeshipping}
                guards={[G_UNPAID, G_PLANRENEWAL, G_SHIPPING]}
            />
            <ProtectedRoute
                exact
                path="/user/unpaidorder"
                component={Unpaidorder}
            />
            <ProtectedRoute
              exact
              path="/user/ordertrack"
              component={Ordertrack}
              guards={[G_UNPAID]}
            />
            <ProtectedRoute
                exact
                path="/user/reship"
                component={ReShip}
                guards={[G_UNPAID]}
            />
            <ProtectedRoute
                exact
                path="/user/reshipatdiscount"
                component={ReshipAtDiscount}
                guards={[G_UNPAID]}
            />
            <ProtectedRoute
                exact
                path="/user/reshipdocument"
                component={ReshipDocument}
                guards={[G_UNPAID]}
            />
            <ProtectedRoute
                exact
                path="/user/reshipping"
                component={ReShipping}
                guards={[G_UNPAID]}
            />

            <AdminRoute exact path="/admin/order" component={AdminOrder}/>
            <AdminRoute exact path="/admin/shipping" component={AdminShipping}/>
            <AdminRoute exact path="/admin/returns" component={AdminReturns}/>
            <AdminRoute exact path="/admin/refunds" component={AdminRefunds}/>

            <Route path="/error">
              <InternalServerError />
            </Route>
            <Route path="*">{<PageNotFound />}</Route>
          </Switch>
        </ErrorBoundary>
      </Router>
    </div>
  );
};

export {directAccessGuard};
export default App;

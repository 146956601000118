/* eslint-disable eqeqeq */
import React, {useContext, useEffect, useRef, useState, useCallback} from "react";
import {
  CardElement,
  StripeProvider,
  Elements,
  injectStripe,
} from "react-stripe-elements";
import { Row, Col, Container, Form, Image, Alert } from "react-bootstrap";
import axios from "axios";
import $ from "jquery";
import { useHistory, Redirect } from "react-router-dom";
import UserContext from "../../../store/user-context";
import { SavedCardDetail } from "./Carddetail";
import Loader from "../loader/Loader";
import { API } from "../../../route/services";
import SweetAlert from "react-bootstrap-sweetalert";

const apiUrl = process.env.REACT_APP_SERVER_URL;

class _CardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripeError: "",
      isLoad: false,
      isRedirect: false,
      isTransactionFailed: false,
      isTransactionSuccess: false,
    };
  }

  static contextType = UserContext;

  handleUserInput(e) {
    this.setState({
      stripeError: "",
    });
  }

  renderRedirect = () => {
    if (this.state.isRedirect) {
      return <Redirect to="/user/dashboard" />;
    }
  };

  render() {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();

          this.setState({ isLoad: true });
          const paymentService = this.context.payment;
          const amount = this.context.amount;

          this.props.stripe.createToken().then(async (payload) => {
            const userId = localStorage.getItem("id");

            this.setState({isTransactionFailed: false, isTransactionSuccess: false});

            if (payload.token) {
              this.setState({ stripeError: "" });
              await axios
                .post(
                  `${API.usersShippingPayment}`,
                  {
                    amount: parseFloat(amount.toFixed(2)),
                    id: userId,
                    token: payload.token.id,
                    description: "",
                    shipping_option: paymentService.serviceName,
                    tracking: paymentService.price == 0 ? 0 : 1,
                    user_ip_location: payload.token.client_ip,
                    flag: 1,
                    plan_type: "",
                    validity_years: "",
                    process_fee: 0,
                    plan_price: "",
                    ship_flag : 0
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    mode: "no-cors",
                  }
                )
                .then((response) => {

                  this.setState({isTransactionSuccess: true});

                  // window.location.replace("/user/dashboard");
                  setTimeout(() => {
                    this.setState({ isLoad: false, isRedirect: true });
                  }, 2000);
                })
                .catch((error) => {
                  if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    window.location.href="/login";
                  }
                  this.setState({ isTransactionFailed: true, isLoad: false });
                  console.log("post payment service error:", error);
                });
            } else {
              this.setState({
                stripeError: payload.error.message,
                isLoad: false,
              });
            }
          });
        }}
      >
        <CardElement />
        {this.state.stripeError && (
          <p className="text-left payment-error text-danger">
            {this.state.stripeError}
          </p>
        )}
        {this.state.isLoad && (
          <div className="payment-loader">
            <Loader />
          </div>
        )}
        {this.renderRedirect()}
        {this.state.isTransactionSuccess && (
            <SweetAlert success title="Payment Success" onConfirm={() => this.setState({isTransactionSuccess:false, isLoad: false, isRedirect: true})}>
              Your payment has been received
            </SweetAlert>
        )}

        {this.state.isTransactionFailed && (
            <SweetAlert danger title="Transaction failed" confirmBtnText="Try again" onConfirm={() => this.setState({isTransactionFailed: false})}>
              Transaction Failed , Please Try again
            </SweetAlert>
        )}

        {!this.state.isLoad && (
          <button
            className="btn btn-success shipping-button upgrade-button"
            type="submit"
            // onClick={submitHandler}
          >
            Pay
          </button>
        )}
      </form>
    );
  }
}
const CardForm = injectStripe(_CardForm);

class Checkout extends React.Component {
  render() {
    return (
      <div>
        <Elements className="form-control">
          <CardForm />
        </Elements>
      </div>
    );
  }
}

function Upgradeshipping({redirected}) {
  const userId = localStorage.getItem("id");
  const [checked, setChecked] = useState(0);
  const [data, setData] = useState([]);
  const [upgradePrice, setUpgradePrice] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [cardDetail, setCardDetail] = useState({ card_flag: 0 });
  const [isLoad, setLoad] = useState(true);
  const [payData, setPayData] = useState({});
  const [discountPer, setDiscountPer] = useState(0);
  const [discount, setDiscount] = useState(0);
  const userContext = useContext(UserContext);

  function bannerCallingAPI() {
    axios.get(`${API.userShippingCardBanner}/${userId}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      mode: "no-cors",
    }).then((response) => {
      apicalling();
    }).catch((error) => {
      console.log("shipping card error:", error.response);
    });
  }

  function apicalling() {
    axios
      .get(`${API.usersPaymentShippingOption}/${userId}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        mode: "no-cors",
      })
      .then(async (response) => {
        setLoad(false);
        if (redirected){
          response.data.data = response.data.data.filter(
              (shippingOption) => ['DHL', 'USPS 2 Day', 'USPS Overnight'].includes(shippingOption.serviceName)
          );
        }
        setDiscountPer(parseInt(response.data.discount ? response.data.discount: 0));
        setData(response.data.data);
        setUpgradePrice(response.data.selected_amount);
        setPayData(response.data.data[0]);
        if (!redirected){
          setTotalAmount(
              response.data.data[0].price -
              response.data.selected_amount
          );
          userContext.paymentHandler(response.data.data[0]);
          userContext.amountHandler(
              response.data.data[0].price -
              response.data.selected_amount
          );
        }else {
          let amount = handleDiscount(response.data.data[0].price,
              parseInt(response.data.discount ? response.data.discount: 0));
          userContext.paymentHandler(response.data.data[0]);
          userContext.amountHandler(response.data.data[0].price - amount);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          window.location.href="/login";
        }
        console.log("get shipping option error:", error);
      });

    axios
      .post(
        `${API.usersPaymentCardDeails}/${userId}/`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          mode: "no-cors",
        }
      )
      .then((response) => {
        setCardDetail(response.data[0]);
        userContext.cardHandler(response.data[0]);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          window.location.href="/login";
        }
        console.log("post card detail error:", error.response);
      });
  }

  useEffect(() => {
    if (redirected) {
      bannerCallingAPI();
    } else {
      apicalling();
    }
  }, []);

  function radioChangeHandler(id) {
    setChecked(id);
    const payData = data[id];
    setPayData(payData);

    if (!redirected){
      setTotalAmount(payData.price - upgradePrice)
      userContext.paymentHandler(payData);
      userContext.amountHandler(payData.price - upgradePrice);
    }else {
      let amount = handleDiscount(payData.price)
      userContext.paymentHandler(payData);
      userContext.amountHandler(payData.price - amount);
    }

  }

  function resetCardDetail() {
    setCardDetail({ card_flag: 0 });
  }

  function handleDiscount(price, discountPercent=null) {
    if (price !== undefined){
      let discountAmount = (price.toFixed(2) * (discountPercent || discountPer)/100).toFixed(2);
      setDiscount(discountAmount)
      setTotalAmount(
          price.toFixed(2) - discountAmount
      )
      return discountAmount
    }
    return 0.00
  }

  const serviceOfCountry = data.map((value, index) => {
    let image;
    if (value.image === 1) {
      image = require("./../../../assets/images/usps.svg").default;
    } else if (value.image === 2) {
      image = require("./../../../assets/images/fedex_logo.png").default;
    } else if (value.image === 3) {
      image = require("./../../../assets/images/internationally.png").default;
    } else if (value.image === 4) {
      image = require("./../../../assets/images/aus-post.svg").default;
    }
    // For Royal Mail
    // else if (value.image === 5) {
    //   image = require("./../../../assets/images/royal-mail-logo-3.png").default;
    // }
    else {
      image = require("./../../../assets/images/fedex_logo.png").default;
    }
    return (
      <>
        <div className="usps-tracking">
          <div>
            <span class="custom_radio">
              <input
                type="radio"
                id={value.serviceName}
                name="Tracking"
                checked={index === checked ? true : false}
                onChange={() => radioChangeHandler(index)}
              />
              <label for={value.serviceName}></label>
            </span>
            <Image src={image} alt="usps" />
            <label className="option-text">{value.serviceName === "DHL" ? "FedEx" : value.serviceName}</label>
          </div>
          <span className="text-end">
            {value.price === 0 ? "FREE" : "$" + value.price.toFixed(2)}
          </span>
        </div>
      </>
    );
  });

  return (
    <>
      <Container>
        <section className="processing-section">
          <div className="processing-title">
            <h3>Upgrade Tracking</h3>
            <p>Ensure your documents delivery with improved tracking.</p>
            <div></div>
          </div>
          <Form>
            <div className="payment-stripe">
              <Row>
                <Col className="col-12 col-sm-12 col-md-12 col-lg-6">
                  {isLoad && (
                    <div className="payment-loader">
                      <Loader />
                    </div>
                  )}
                  {!isLoad && (
                    <div className="processing-section-title">
                      <p>Tracking</p>
                      {serviceOfCountry}
                    </div>
                  )}
                  {!isLoad && (
                    <div className="payment-section">
                      <p>Payment</p>

                      <div className="stripe-payment">
                        <div className="AppWrapper text-center">
                          {cardDetail.card_flag == 1 && (
                            <SavedCardDetail
                              resetCardDetail={resetCardDetail}
                              processFee={0}
                            />
                          )}
                          {cardDetail.card_flag == 0 && (
                            <StripeProvider
                              apiKey={
                                process.env.REACT_APP_REACT_API_STRIPE_KEY
                              }
                            >
                              <Checkout />
                            </StripeProvider>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
                <Col className="col-12 col-sm-12 col-md-12 col-lg-6">
                  {!isLoad && (
                    <div className="summary-section">
                      <p>SUMMARY</p>
                      <ul className="processing border-bottom-0">
                        <li className="p-0">Tracking </li>
                        <li className="p-0">${payData.price !== undefined ? payData.price.toFixed(2) : ''}</li>
                      </ul>
                      <ul className="processing ">
                        <li className="p-0">{!redirected ? "Cost Adjustment" : `${discountPer}% Discount` }</li>
                        <li className="p-0">- ${!redirected ? upgradePrice.toFixed(2) : discount}</li>
                      </ul>
                      <ul className="ps-4 pe-4">
                        <li className="p-0">Total</li>
                        <li className="p-0">${totalAmount.toFixed(2)}</li>
                      </ul>
                      <h6>
                        <Image
                          src={
                            require("./../../../assets/images/payment.svg")
                              .default
                          }
                          alt="Payment"
                        />{" "}
                        Safe & Secure Payment
                      </h6>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Form>
        </section>
      </Container>
    </>
  );
}

export default Upgradeshipping;

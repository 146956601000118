
import React, {useEffect} from 'react';
import { Route, Redirect} from 'react-router-dom';
import axios from "axios";
import {API} from "./services";
import {directAccessGuard} from "../App";

/**
 * A custom router to redirect to the path after a successful login.
 *
 * When trying to access page by direct path without authentication,
 * the user will redirect to the login page. After successful login, it will be redirect to the actual page.
 *
 */

const ProtectedRedirectRoute = ({ component: Component, guards: Guards, ...rest }) => {
    const authentication = localStorage.getItem("id");
    const isAdmin = localStorage.getItem("isAdmin");

    return (<
        Route {...rest}
        render={
            props => {
                if (authentication && !isAdmin) {
                    return directAccessGuard(
                        Guards,
                        <Component {...rest} {...{...props, redirected:true}}/>
                    )
                } else if (authentication && isAdmin) {
                    return <Redirect to={
                        {
                            pathname: '/page-not-found',
                            state: {
                                from: props.location
                            },
                        }
                    }
                    />
                } else {
                    return <Redirect to={
                        {
                            pathname: '/login',
                            state: {
                                from: props.location,
                                redirected: true,
                                guards: Guards
                            },
                        }
                    }
                    />
                }
            }
        }
    />
    )
};

export default ProtectedRedirectRoute;
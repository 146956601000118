var BASE_URL = process.env.REACT_APP_SERVER_URL;

export const API = {
  registration: BASE_URL + 'auth/registration/',
  login: BASE_URL + 'auth/login/',
  forgotPassword: BASE_URL + 'auth/request-reset-email/',
  passwordResetThrewEmail: BASE_URL + 'auth/password-reset-complete/',
  twoFactorSetup: BASE_URL + 'auth/two-factor/setup',
  twoFactorEnable: BASE_URL + 'auth/two-factor/enable',

  //admin
  //admin-order-table
  adminOrderTable: BASE_URL + 'admin-portal/order_list/',

  //admin-order-detail-list
  adminOrderDetail: BASE_URL + 'admin-portal/order_detail',

  //admin saved user's data
  adminSavedUsersOrder: BASE_URL + 'admin-portal/admin-save-user-data',
  addShippingEntry: BASE_URL + 'admin-portal/add_shipping_entry',

  //admin-shipping-table
  adminShippingTable: BASE_URL + 'shipping/shipping_list/',

  //Admin-driver-info-update
  adminDriverInfoUpdate: BASE_URL + 'admin-portal/driver-information-admin',

  //Admin side-user's transaction history logs
  adminSideUsersTranscationHistory: BASE_URL + 'payment/order-payment-list',

  //admin shipping page user's shippingf address details
  adminUsersShippingAddressDetails: BASE_URL + 'shipping/shipping-data',
  adminPortalShippingAddressDetails: BASE_URL + 'admin-portal/address-validation-detail',
  userShippingStatus: BASE_URL + 'shipping/shipping_status',
  userShippingDetail: BASE_URL + 'shipping/shipping_detail',
  userShippingData: BASE_URL + 'shipping/shipping-data',
  generatePrepLabels: BASE_URL + 'admin-portal/generate_prep_labels/',
  addressMail: BASE_URL + 'admin-portal/invalid_address/',
  returnedAddressMail: BASE_URL + 'admin-portal/returned_address/',
  processStatus: BASE_URL + 'admin-portal/process_order/',
  makeLabel: BASE_URL + 'admin-portal/add_shipping_entry/',
  makeShippedFiles: BASE_URL + 'admin-portal/upload_shipped_file/',
  regenerateDocs: BASE_URL + 'admin-portal/regenerate_doc/',
  deleteEntry: BASE_URL + 'admin-portal/shippedorder/',
  refundAPO: BASE_URL + 'payment/refund_apo/',
  deleteAccount : BASE_URL + 'admin-portal/soft_delete_account/',
  sendIdCardMail: BASE_URL + 'admin-portal/send_id_card/',

  //admin payment refund 
  adminPaymentRefund: BASE_URL + 'payment/payment-refund/',




  //users
  //users-perosnal-information
  getUserPerosnalInfo: BASE_URL + 'customer-portal/personal-information',
  putUserPerosnalInfo: BASE_URL + 'customer-portal/personal-information',

  //users-driver-information
  getUserDriverInfo: BASE_URL + 'customer-portal/driver-information',
  putUserDriverInfo: BASE_URL + 'customer-portal/driver-information',

  //users-shiiping-address-information
  getUserShippingInfo: BASE_URL + 'customer-portal/shipping-address-details',
  putUserShippingInfo: BASE_URL + 'customer-portal/shipping-address-details',

  //profile image
  profileImageGetUpdate: BASE_URL + 'photo/user-profilephoto',

  //user's all data filled or not check for payment
  userAllDetailCheckFilled: BASE_URL + 'customer-portal/complete-details-check',

  //user's shipping card banner
  userShippingCardBanner: BASE_URL + 'customer-portal/user-shipping-card',

  //user's urgent(documents) card banner
  userDocumentsCardBanner: BASE_URL + 'customer-portal/documents',

  //Banner 28 no thanks
  banner28NoThanks: BASE_URL + 'customer-portal/no-thanks',

  //Banner 31 order not received
  banner31OrderNotReceived: BASE_URL + 'customer-portal/order_not_received',

  //Login Models
  loginModels: BASE_URL + 'customer-portal/order-models/',

  //get user's payment saved card details
  usersPaymentCardDeails: BASE_URL + 'payment/user-card-details',

  //delete user's payment saved card details 
  userDeleteCardDetail: BASE_URL + 'payment/delete-card',

  //add payment 
  usersShippingPayment: BASE_URL + 'payment/order-payment/',
  usersUrgentDigitalPayment: BASE_URL + 'payment/urgent-payment/',
  usersUrgentPaymentDetail: BASE_URL + 'shipping/urgent-shipping-fee/',


  //plan renewal 
  usersPlanRenewalDetails: BASE_URL + 'customer-portal/plan-types/',

  //transcation-history
  usersTranscationHistory: BASE_URL + 'customer-portal/transaction-history',

  //setting - change-password
  usersChangePassword: BASE_URL + 'auth/change_password',


  //user's country wise shiiiping options
  usersPaymentShippingOption: BASE_URL + 'payment/shipping-options',

  //user's order not arrived query submit
  usersOrderQuerySumbit: BASE_URL + 'customer-portal/order-not-arrived/',

  //country
  getCountryList: BASE_URL + 'customer-portal/country_list/',

  // Get Document
  getUserDocuments: BASE_URL + 'photo/user-documents/',

  //unpaidOrder
  getTrackingData: BASE_URL + 'customer-portal/shipping_option/',
  UnpaidOrderData: BASE_URL + 'customer-portal/unpaid_order/',

  //unsubscribeUrl
  unsubscribeUser: BASE_URL + 'user/unsubscribe/'

}

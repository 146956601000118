import React, {useState, useEffect, useRef, useCallback} from "react";
import {Container, Row, Col, Button, Modal, Image, Form} from "react-bootstrap";
import axios from "axios";
import {useHistory} from "react-router-dom";

//country list from backend
import {CountryList} from "../../../common/country-list";

//components
import UserSidebar from "../../usersidebar/UserSidebar";
import ShippingAddress from "./informationsection/shippingaddress/ShippingAddress";
import DocumentProcessing from "./informationsection/documentprocessing/DocumentProcessing";
import UrgentlyProcessed from "./informationsection/urgentlyprocessed/UrgentlyProcessed";
import DocumentShipped from "./informationsection/documentShipped/DocumentShipped";
import DriverInformation from "../../component/userdashboard/informationsection/driverinformation/DriverInformation";
import PersonalInformation from "./informationsection/personalinformation/PersonalInformation";
import DocumentExpired from "./informationsection/documentexpired/DocumentExpired";
import DocumentPDF from "./informationsection/documentPDF/DocumentPDF";
import UnPaidOrder from "./informationsection/unpaidorder/UnPaidOrder";
import DocumentsExpire from "./carriersection/documentsexpire/DocumentsExpire";
import AfterGracePeriod from "./carriersection/aftergraceperiod/AfterGracePeriod";
import ShippedInternationalyDHL from "./carriersection/shippedinternationalyDHL/ShippedInternationalyDHL";
import ShippedInternationalyUSPS from "./carriersection/shippedinternationalyUSPS/ShippedInternationalyUSPS";
import IFShippedInternationalyUSPS from "./carriersection/ifshippedinternationalyUSPS/IFShippedInternationalyUSPS";
import ShippedUSandUSPSTracking from "./carriersection/shippedusanduspstracking/ShippedUSandUSPSTracking";
import IFShippingInternationallyDHL from "./carriersection/ifshippinginternationallyDHL/IFShippingInternationallyDHL";
import ShippingInternationally from "./carriersection/shippinginternationally/ShippingInternationally";
import ShippingUSPSOvernight from "./carriersection/shippinguspsovernight/ShippingUSPSOvernight";
import ShippingUSPS from "./carriersection/shippingusps/ShippingUSPS";
import ShipMyDocument from "./carriersection/shipmydocument/ShipMyDocument";
import UnPaidDocument from "./carriersection/unpaiddocument/UnPaidDocument";
import USPSFreeShipping from "./carriersection/uspsfreeshipping/USPSFreeShipping";
import DayUSPSOvernight from "./carriersection/dayuspsovernight/DayUSPSOvernight";
import InternationallyUSPSFree from "./carriersection/internationallyuspsfree/InternationallyUSPSFree";
import InternationallyUSPSTracking from "./carriersection/internationallyuspstracking/InternationallyUSPSTracking";
import InternationallyDHL from "./carriersection/internationallyDHL/InternationallyDHL";
import UpgradeTrackingDHL from "./carriersection/upgradetrackingDHL/UpgradeTrackingDHL";
import Loader from "../loader/Loader";
import ImageResize from "./carriersection/uploadphoto/photo";
import IFShippedtoUSandUSPSNotArrived from "./carriersection/ifshippedtousandusps/IFShippedtoUSandUSPS";
import IFShippedtoFirstClassNotArrived from "./carriersection/ifshippinginternationallyUSPS/IFShippingInternationallyUSPS";
import InternationallyFree from "./carriersection/internationallytracking/InternationallyTracking";
import InternationallyTracking from "./carriersection/internationallytrackingorder/InternationallyTracking";
import InternationallyOrderDHL from "./carriersection/internationallyOrderDHL/InternationallyOrderDHL";
import Banner23 from "./carriersection/commonlastBanner/Baner23";
import Banner24 from "./carriersection/commonlastBanner/Baner24";
import Banner25 from "./carriersection/commonlastBanner/Baner25";
import Banner26 from "./carriersection/commonlastBanner/Baner26";
import Banner27 from "./carriersection/commonlastBanner/Baner27";
import Banner28 from "./carriersection/commonlastBanner/Baner28";
import Banner29 from "./carriersection/commonlastBanner/Baner29";
import Banner31 from "./carriersection/commonlastBanner/Baner31";
import Banner32 from "./carriersection/commonlastBanner/Baner32";
import $ from "jquery";
import {API} from "../../../route/services";
import moment from "moment-timezone";


function UserDashboard(props) {
    const userId = localStorage.getItem("id");
    const history = useHistory();

    const [isLoad, setLoad] = useState(false);
    const [isUrgentPayment, setUrgentPayment] = useState(false);
    const [isCompleteFlag, setIsCompleteFlag] = useState(false);
    const [trackProcess, setTrackProcess] = useState("");
    const [isShipped, setshippedProcess] = useState(false);
    const [trackShipping, setTrackShipping] = useState("");
    const [trackingId, setTrackingId] = useState("");
    const [maxArrivalDate, setMaxArrivalDate] = useState("");
    const [estimatedArrivalDate, setEstimatedArrivalDate] = useState("");
    const [shippingOption, setShippingOption] = useState("");
    const [isOrderInternational, setIsOrderInternational] = useState(false);
    const [timeElapsed, setTimeElapsed] = useState(0);
    const [shippedModel, setshippedModel] = useState(false);
    const [countryModel, setcountryModel] = useState(false);
    const [orderModel, setorderModel] = useState(false);
    const [incompleteModel, setincompleteModel] = useState(false);
    const [modelOrder, setmodelOrder] = useState(1);
    const [otherMessage, setotherMessage] = useState("");
    const [shippingModel, setShippingModel] = useState(false);
    const [shippingDiscount, setSippingDiscount] = useState(0);
    const [isShipping, setShipping] = useState(true);
    const [invalidAddressModel, setInvalidAddressModel] = useState(false);

    //model show popup on save button
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleCountyModel = (value, message) => {setcountryModel(value); setotherMessage(message);};
    const handleShow = () => setShow(true);
    const handleShippedModelShow = () => setshippedModel(true);
    const handlemodelOrder = (order) => {
        if (incompleteModel) {
            setmodelOrder(order)
        }
        else if(invalidAddressModel)
        {
            setmodelOrder(order + 1)
        }
    };
    const handleShippingModel = (value) => setShippingModel(value);

    const handleManuToggle = useCallback(() => {
        $(".sidebar").toggleClass("sidebarshow");
        $(".dashboard-data").toggleClass("dashboard-data-show");
    }, []);

    const getMomentObj = (date, format='MMMM D, YYYY') => {
        return moment(date, format).tz('UTC')
    }


    function bannerCallingAPI() {
        axios.get(`${API.userShippingCardBanner}/${userId}/`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            mode: "no-cors",
        }).then((response) => {
            if(response.data.discount !== undefined) {
                setSippingDiscount(response.data.discount);
            }
            setTrackShipping(response.data.shipping_card);
            if(response.data.track_id !== null && response.data.track_id !== undefined) {
                setTrackingId(response.data.track_id);
            } else {
                setTrackingId("");
            }
            setMaxArrivalDate(response.data.max_date);
            setShipping(response.data.plan_type === 'Print + Digital Bundle');
            setEstimatedArrivalDate(response.data.estimated_date);
            setShippingOption(response.data.shipping_option);
            setIsOrderInternational(response.data.is_international);
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href="/login";
            }
            console.log("shipping card error:", error.response);
        });

        axios.get(`${API.userDocumentsCardBanner}/${userId}/`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            mode: "no-cors",
        }).then((response) => {
            let flag = response.data.doc_flag;
            let isShow = response.data.isShow;
            setTrackProcess(flag);
            setshippedProcess(response.data.is_shipped);
            setUrgentPayment(isShow);
            setLoad(false);
            changeStatus(isShow, flag);
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href="/login";
            } else {
                setLoad(false);
                console.log("digital card error:", error.response);
            }
        });

        //country list
        CountryList();
    }

    useEffect(() => {
        if(props.location.shpping !== undefined) {
            handleShippingModel(props.location.shpping);
        }
        setLoad(true);
        loginModels(false);
        bannerCallingAPI();
        setTimeout(() => {
            handleUrgentPayment();
        },1000);
    }, []);

    function userDocumentsBannerCallingAPI() {
        axios.get(`${API.userDocumentsCardBanner}/${userId}/`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            mode: "no-cors",
        }).then((response) => {
            let flag = response.data.doc_flag;
            let isShow = response.data.isShow;
            setTrackProcess(flag);
            setshippedProcess(response.data.is_shipped);
            setUrgentPayment(isShow);
            setLoad(false);
            changeStatus(isShow, flag);
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href="/login";
            } else {
                setLoad(false);
                console.log("digital card error:", error.response);
            }
        });
    }

    const useInterval = (callback, delay) => {
        const intervalId = useRef(null);
        const savedCallback = useRef(callback);

        useEffect(() => {
            savedCallback.current = callback;
        });

        useEffect(() => {
            const tick = () => savedCallback.current();

            if (typeof delay === "number") {
                intervalId.current = window.setInterval(tick, delay);
                return () => window.clearInterval(intervalId.current);
            }
        }, [delay]);

        return intervalId.current;
    };

    useInterval(
        () => {
            setTimeElapsed(timeElapsed => timeElapsed + 1);
            userDocumentsBannerCallingAPI();
        },
        timeElapsed === 1 ? 15000 : null
    );

    const changeStatus = (isShow, isFlag) => {
        //let isStatus = isShow && (isFlag === 0 || isFlag === 1) ? 1 : 0;
        let isStatus = 1;
        setTimeElapsed(isStatus);
    };

    //reship again onclick submit button in popup
    function reShipAgain() {
        history.push("/user/upgradeshipping");
    }

    const handleUrgentPayment = () =>{
        const userId = localStorage.getItem("id");
        axios.get(` ${API.userAllDetailCheckFilled}/${userId}/`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            }
        ).then((response) => {
            setIsCompleteFlag(response.data.complete_flag);
        }).catch((error) => {
            console.log("complete-details-check page error:", error.response);
        });
    };

    function handleShippedModel(isClose) {
        if(isClose) {
            history.push({ pathname: "/user/addshipping", state: userId });
        } else {
            bannerCallingAPI();
            setshippedModel(false);
        }
    }

    function loginModels(isClose) {
        if(isClose){
            localStorage.setItem("loginModel", true);
            setmodelOrder(4);
            setincompleteModel(false);
        } else {
            axios.get(` ${API.loginModels}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    mode: "no-cors",
                }
            ).then((response) => {
                if(response.data.jsc) {
                    setmodelOrder(1);
                } else {
                    setmodelOrder(2);
                }
                setorderModel(response.data.jsc);
                setInvalidAddressModel(response.data.is_invalid_address);

                if(localStorage.getItem("loginModel") !== "true") {
                    setincompleteModel(response.data.incomplete);
                }
            }).catch((error) => {
                console.log("loginModels error:", error.response);
            });
        }
    }

    return (
        <>
            <UserSidebar/>
            <div className="dashboard-data">
                <Container fluid>
                    <p className=" menuicon mb-0 dashboard-menu-icon" onClick={handleManuToggle}>
                        <Image src={require("../../../assets/images/menu.svg").default} alt="menu" className="sd-block d-md-none d-lg-none"/>
                    </p>

                    <Row>
                        <Col className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                            {(trackShipping === 30 && !isLoad && (
                                    <UnPaidOrder/>
                                )) ||

                            (trackProcess === 1 && !isLoad && trackProcess && (
                                    <DocumentProcessing/>
                                )) ||

                            (trackProcess === 2 && !isLoad && trackProcess && (
                                    <DocumentShipped docFlag={trackProcess}/>
                                )) ||

                            (trackProcess === 3 && !isLoad && trackProcess && (
                                    <DocumentPDF docFlag={trackProcess}/>
                                )) ||

                            (trackProcess === 4 && !isLoad && trackProcess && (
                                    <DocumentExpired/>
                                )) ||

                            (trackProcess === 0 && !isLoad && (
                                    <UrgentlyProcessed apiCall={bannerCallingAPI} isUrgentPayment={isUrgentPayment} isCompleteFlag={isCompleteFlag}/>
                                ))
                            }

                            <PersonalInformation userId={userId} modelShow={handleShow} shippedModel={handleShippedModelShow} countryModel={handleCountyModel} isAdmin={0} apiCall={bannerCallingAPI} isShipped={isShipped}/>
                            <DriverInformation userId={userId} modelShow={handleShow} shippedModel={handleShippedModelShow} countryModel={handleCountyModel} apiCall={bannerCallingAPI} isShipped={isShipped}/>
                            {isShipping &&
                                <ShippingAddress userId={userId} isUser={true} modelShow={handleShow} shippedModel={handleShippedModelShow} countryModel={handleCountyModel} apiCall={bannerCallingAPI}
                                                 parentCallback={handleUrgentPayment} isShipped={isShipped}/>
                            }
                        </Col>

                        <Col className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                            <div style={{display: "flex", justifyContent: "space-between", verticalAlign: "middle", alignItems: "center"}}>
                                <h3 style={{fontSize: "20px", fontWeight: "400", margin: "0px", padding: "10px 0px 5px 0px"}}>Shipping Information</h3>

                                {isShipped
                                    && (shippingOption === 'USPS Free'
                                        ? getMomentObj(new Date()).isAfter(getMomentObj(estimatedArrivalDate))
                                        : true
                                    ) && (
                                        <Button type="button"
                                                className="btn btn-warning"
                                                onClick={() => {
                                                    history.push("/user/reship");
                                                }} > Reship my documents
                                        </Button>
                                    )
                                }

                            </div>
                            {isLoad && (
                                <div className="loader-dashboard">
                                    <Loader/>
                                </div>
                            )}
                            {trackShipping === 1 && !isLoad && <ShipMyDocument/>}
                            {trackShipping === 2 && !isLoad && <USPSFreeShipping/>}
                            {trackShipping === 3 && !isLoad && <DayUSPSOvernight/>}
                            {trackShipping === 4 && !isLoad && <UpgradeTrackingDHL/>}
                            {trackShipping === 5 && !isLoad && <InternationallyDHL/>}
                            {trackShipping === 6 && !isLoad && <ShippingUSPS isShipped={isShipped}/>}
                            {trackShipping === 7 && !isLoad && (
                                <ShippingUSPSOvernight trackingId={trackingId}/>
                            )}
                            {trackShipping === 8 && !isLoad && <ShippingInternationally isShipped={isShipped}/>}
                            {trackShipping === 9 && !isLoad && (
                                <IFShippedInternationalyUSPS trackingId={trackingId} isShipped={isShipped}/>
                            )}
                            {trackShipping === 10 && !isLoad && (
                                <IFShippingInternationallyDHL trackingId={trackingId}/>
                            )}
                            {trackShipping === 11 && !isLoad && (
                                <IFShippedtoUSandUSPSNotArrived maxDate={maxArrivalDate}/>
                            )}
                            {trackShipping === 12 && !isLoad && (
                                <ShippedUSandUSPSTracking maxDate={maxArrivalDate} trackingId={trackingId}/>
                            )}
                            {trackShipping === 13 && !isLoad && (
                                <IFShippedtoFirstClassNotArrived maxDate={maxArrivalDate}/>
                            )}
                            {trackShipping === 14 && !isLoad && (
                                <ShippedInternationalyUSPS maxDate={maxArrivalDate} trackingId={trackingId}/>
                            )}
                            {trackShipping === 15 && !isLoad && (
                                <ShippedInternationalyDHL maxDate={maxArrivalDate} trackingId={trackingId}/>
                            )}
                            {trackShipping === 16 && !isLoad && <AfterGracePeriod/>}
                            {trackShipping === 17 && !isLoad && (
                                <InternationallyUSPSFree arrivalDate={maxArrivalDate}/>
                            )}
                            {trackShipping === 18 && !isLoad && (
                                <InternationallyUSPSTracking trackingId={trackingId} arrivalDate={maxArrivalDate}/>
                            )}
                            {trackShipping === 19 && !isLoad && (
                                <InternationallyFree arrivalDate={maxArrivalDate}/>
                            )}
                            {trackShipping === 20 && !isLoad && (
                                <InternationallyTracking trackingId={trackingId} arrivalDate={maxArrivalDate}/>
                            )}
                            {trackShipping === 21 && !isLoad && (
                                <InternationallyOrderDHL trackingId={trackingId} arrivalDate={maxArrivalDate}/>
                            )}
                            {trackShipping === 22 && !isLoad && <DocumentsExpire shippingDiscount={shippingDiscount}/>}
                            {trackShipping === 23 && !isLoad && <Banner23/>}
                            {trackShipping === 24 && !isLoad && <Banner24/>}
                            {trackShipping === 25 && !isLoad && <Banner25/>}
                            {trackShipping === 26 && !isLoad && (
                                <Banner26 trackingId={trackingId}/>
                            )}
                            {trackShipping === 27 && !isLoad && <Banner27/>}
                            {trackShipping === 28 && !isLoad && <Banner28 apiCall={bannerCallingAPI}/>}
                            {trackShipping === 29 && !isLoad && <Banner29/>}
                            {trackShipping === 30 && !isLoad && <UnPaidDocument/>}
                            {trackShipping === 31 && !isLoad && <Banner31
                                arrivalDate={estimatedArrivalDate} isOrderInternational={isOrderInternational}
                                getMomentObj={getMomentObj}
                            />}
                            {trackShipping === 32 && !isLoad && <Banner32 shippingOption={shippingOption}/>}
                            <div style={{display: isLoad ? "none" : "block"}}>
                                <ImageResize userId={userId} adminUser={false} isShipped={isShipped} apiCall={bannerCallingAPI} shippedModel={handleShippedModelShow} orderModel={modelOrder}  handlemodelOrder={handlemodelOrder}/>
                            </div>
                        </Col>

                        <div className="popup">
                            <Modal show={show} onHide={handleClose} centered>
                                <Modal.Body className="text-center">
                                    <p>Oh no! Your order was already shipped!</p>
                                    <h3>Lucky for you -- we can reship them.</h3>
                                    <h5>Would you like to reship your document with your latest corrections?</h5>
                                    <div className="modal-btn">
                                        <Button type="submit" className="btn btn-primary" onClick={reShipAgain}>Yes, please!</Button>
                                        <Button type="submit" className="btn btn-danger" onClick={handleClose}>No, thanks</Button>
                                    </div>
                                    <div className="modal-footer text-center">
                                        <p>Don't worry. Your digital document will be recreated free of charge. We'll email as soon as they are ready.</p>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </Row>
                    <div className="modal-dialog modal-xl">
                        <Modal show={shippedModel} onHide={false} centered>
                            <Modal.Body>
                                <p style={{fontSize: "15px"}} className="text-center">You have made edits to your order but your printed documents have already been shipped.
                                    Your digital documents will be updated and ready for you to download in just a few minutes.
                                    However, if you would like your order reshipped, you will have to pay for additional shipping.</p>
                                <div className="modal-btn text-center">
                                    <Button type="submit" className="btn btn-primary" onClick={handleShippedModel.bind(this, true)}>Reship my documents</Button>
                                    <Button type="submit" className="btn btn-danger" onClick={handleShippedModel.bind(this, false)}>Okay</Button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                    <div className="modal-dialog modal-xl">
                        <Modal show={countryModel} onHide={false} centered>
                            <Modal.Body className="text-center">
                                <h3>Please select {otherMessage}.</h3>
                                <div className="modal-btn">
                                    <button type="submit" className="btn btn-primary" onClick={handleCountyModel.bind(this, false, "")}>Ok</button>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal show={shippingModel} onHide={false} centered>
                            <Modal.Body className="text-center">
                                <h3>Thank you for confirming your address. You should receive your order shortly.</h3>
                                <div className="modal-btn">
                                    <button type="submit" className="btn btn-primary" onClick={handleShippingModel.bind(this, false)}>Ok</button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                    <div className="modal-dialog modal-xl">
                        <Modal show={orderModel && modelOrder === 1} onHide={false} centered>
                            <Modal.Body className="text-center">
                                <p>
                                    We noticed your order was placed in a country that we do not service, please contact support to discuss your order.
                                </p>
                            </Modal.Body>
                        </Modal>
                    </div>
                    <div className="modal-dialog modal-xl">
                        <Modal show={incompleteModel && modelOrder === 3} onHide={false} centered>
                            <Modal.Body className="text-center">
                                <h3>Please provide missing information.</h3>
                                <div className="modal-btn">
                                    <button type="submit" className="btn btn-primary" onClick={loginModels.bind(this, true)}>Ok</button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                    <div className="modal-dialog modal-xl">
                        <Modal show={invalidAddressModel && modelOrder === 4} onHide={false} centered>
                            <Modal.Body className="text-center">
                                <p style={{textAlign: "center", padding: "0px 5%"}}>
                                    There is an issue with the address you submitted. <br />
                                    We will not ship your address <br />without the following:
                                </p>

                                <p>
                                <ol style={{textAlign: "left", padding: "0px 15%"}}>
                                    <li>Building/House/Apartment Number</li>
                                    <li>Street Name</li>
                                    <li>Postal Code</li>
                                </ol>
                                </p>
                                <p style={{padding: "0px 5%"}}><b>
                                    Please update and save your address so that we can ship your order.
                                </b></p>
                                <div className="modal-btn">
                                    <button type="submit" className="btn btn-primary" onClick={() => {setInvalidAddressModel(false)}}>OK</button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default UserDashboard;

import React from 'react';

class UnPaidOrder extends React.Component {

    constructor(props) {
        super(props);
        const userId = localStorage.getItem("id");
        this.state = {};
    }

    render() {
        return (
            <section id="document-section1">
                <div  className="document-section order-processing">
                    <h3>Digital Documents</h3>
                    <div className="digital-document">
                        <p>If you think there has been an error and you<br/>
                        have successfully completed payment.Please<br/>
                        click the link below.</p>
                        <div>
                            <a href="https://support.e-ita.org/support/home" className="documents-btn btn btn-success">
                                Contact Support
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default UnPaidOrder;

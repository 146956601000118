import axios from "axios";
import { Row, Col, Form, Button, Alert, Image } from "react-bootstrap";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import CountrySelect from "react-bootstrap-country-select";
import "./../../../../../../node_modules/react-bootstrap-country-select/docs/css/react-bootstrap-country-select.css";
import { countryList } from "../../../../../common/country-list";
import UserContext from "../../../../../store/user-context";
import { API } from "../../../../../route/services";

const DriverInformation = (props) => {
  const userId = props.userId;
  const userContext = useContext(UserContext);

  const [alert, setAlert] = useState(false);

  const [countryValue, setCountryValue] = useState("");
  const [countryError, setCountryError] = useState("");

  const [licenceValue, setLicenceValue] = useState("");
  const [licenceError, setLicenceError] = useState("");

  const [alreadyShipped, setAlreadyShipped] = useState(false);

  const [checkBox, setCheckBox] = useState({
    A: false,
    B: false,
    C: false,
    D: false,
    all: false,
  });

  function handleCountryChange(e) {
    if (e && e.id) {
      setCountryValue(e.name);
    }
  }

  function handleLicenceChange(e) {
    setLicenceValue(e.target.value);
  }

  function vehicalBikeChange(e) {
    setCheckBox({ ...checkBox, A: !checkBox.A });
  }

  function vehicalCarChange(e) {
    setCheckBox({ ...checkBox, B: !checkBox.B });
  }
  function vehicalTruckChange(e) {
    setCheckBox({ ...checkBox, C: !checkBox.C });
  }
  function vehicalBusChange(e) {
    setCheckBox({ ...checkBox, D: !checkBox.D });
  }
  function vehicalAllChange(e) {
    setCheckBox({ ...checkBox, all: !checkBox.all });
  }

  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
    }, 9000);
  });

  useEffect(() => {
    axios
      .get(`${API.getUserDriverInfo}/${userId}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        mode: "no-cors",
      })
      .then((response) => {
        setCountryValue(response.data.driver_license_country);
        setLicenceValue(response.data.driver_license_no);
        setCheckBox({
          A: response.data.vehicle_Motorcycle,
          B: response.data.vehicle_Car,
          C: response.data.vehicle_Semi_Truck,
          D: response.data.vehicle_bus,
          all: response.data.Others_and_trailers,
        });
        setAlreadyShipped(response.data.shipped_bool);
      })
      .catch((error) => {
        console.log("get driver info error:", error.response);
      });
  }, []);

  async function submitDriverInfo() {
    if (!countryValue) {
      return setCountryError("country is required");
    }
    if (!licenceValue) {
      setCountryError("");
      return setLicenceError("Licence number is required");
    }
    if(countryValue === "Other") {
      return props.countryModel(true, "driver's license country of issue");
    }
    setCountryError("");
    setLicenceError("");
    if (!alreadyShipped) {
      await axios
        .put(
          `${API.putUserDriverInfo}/${userId}/`,
          {
            driver_license_country: countryValue,
            driver_license_no: licenceValue,
            vehicle_Motorcycle: checkBox.A,
            vehicle_Car: checkBox.B,
            vehicle_Semi_Truck: checkBox.C,
            vehicle_bus: checkBox.D,
            Others_and_trailers: checkBox.all,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            mode: "no-cors",
          }
        )
        .then((response) => {
          setAlert(true);
          userContext.completeProfileHandler();
          //function calling : banner change on dashboard
          if (props.apiCall) {
            props.apiCall();
          }
          if(props.isShipped){
            props.shippedModel();
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.href="/login";
          }
          console.log("post driver info error:", error.response);
        });
    } else {
      props.modelShow();
    }
  }

  return (
    <>
      <section className="driver-info-section">
        <h3>Driver Information</h3>
        <Form onSubmit={submitDriverInfo} className="form" noValidate>
          <Row>
            {countryList.length > 0 && (
              <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                <Form.Group className="form-group">
                  <Form.Label>Driver's License Country of issue</Form.Label>
                  <CountrySelect
                    name="country"
                    placeholder={countryValue}
                    value={countryValue}
                    onChange={handleCountryChange}
                    countries={countryList}
                    className={countryValue === "Other" ? "country-warn" : ""}
                  />
                  {countryError && (
                    <small className="text-danger">{countryError}</small>
                  )}
                </Form.Group>
              </Col>
            )}

            <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
              <Form.Group className="form-group">
                <Form.Label>Driver's License Number</Form.Label>
                <Form.Control
                  type="string"
                  className="form-control"
                  name="licenceNumber"
                  placeholder={licenceValue}
                  onChange={handleLicenceChange}
                  value={licenceValue}
                ></Form.Control>
                {licenceError && (
                  <small className="text-danger">{licenceError}</small>
                )}
              </Form.Group>
            </Col>
            <div className="select-type">
              <div className="select-whitical">
                <div>
                  <input
                    className="select-address-type"
                    type="checkbox"
                    id="inputOne"
                    name="A"
                    value={checkBox.A}
                    checked={checkBox.A}
                    onChange={vehicalBikeChange}
                  />
                  <label htmlFor="inputOne"></label>
                </div>
                <p>A: Motor-cycle</p>
              </div>
              <div className="select-whitical">
                <div>
                  <input
                    className="select-address-type"
                    type="checkbox"
                    id="inputThree"
                    name="B"
                    value={checkBox.B}
                    checked={checkBox.B}
                    onChange={vehicalCarChange}
                  />
                  <label htmlFor="inputThree"></label>
                </div>
                <p>B: Car</p>
              </div>
              <div className="select-whitical">
                <div>
                  <input
                    className="select-address-type"
                    type="checkbox"
                    id="inputFive"
                    value={checkBox.C}
                    checked={checkBox.C}
                    name="C"
                    onChange={vehicalTruckChange}
                  />
                  <label htmlFor="inputFive"></label>
                </div>
                <p>C: Semi-Truck</p>
              </div>
              <div className="select-whitical">
                <div>
                  <input
                    className="select-address-type"
                    type="checkbox"
                    id="inputTwo"
                    name="D"
                    value={checkBox.D}
                    checked={checkBox.D}
                    onChange={vehicalBusChange}
                  />
                  <label htmlFor="inputTwo"></label>
                </div>
                <p>D: Bus</p>
              </div>
              <div className="select-whitical">
                <div>
                  <input
                    className="select-address-type"
                    type="checkbox"
                    id="inputFour"
                    value={checkBox.all}
                    checked={checkBox.all}
                    name="all"
                    onChange={vehicalAllChange}
                  />
                  <label htmlFor="inputFour"></label>
                </div>
                <p>E: B, C, or D + Trailer</p>
              </div>
            </div>
          </Row>

          {alert && (
            <div className="alert-message">
              <Alert className="alert-success">
                <Image
                  src={
                    require("./../../../../../assets/images/success.svg")
                      .default
                  }
                  alt="Document"
                  className="me-2"
                />
                Driver Information updated successfully
              </Alert>
            </div>
          )}

          <div className="d-flex justify-content-end">
            <Button
              className="btn btn-success mt-3 mb-1"
              type="button"
              onClick={submitDriverInfo}
            >
              {" "}
              Save{" "}
            </Button>
          </div>
        </Form>
      </section>
    </>
  );
};
export default DriverInformation;

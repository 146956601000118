export function shippingErrors(values) {

  let errors = {};
  if (!values.address) {
    errors.address = 'this field required';
  }

  if (!values.address2) {
    errors.address2 = 'this field required';
  }

  if (!values.city) {
    errors.city = 'this field required';
  }

  if (!values.state) {
    errors.state = 'this field required';
  }

  if (!values.country) {
    errors.country = 'this field required';
  }

  if (!values.zipcode) {
    errors.zipcode = 'this field required';
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = 'this field required';
  }

  return errors;

}


export function personalInfoErrors(values) {

  let errors = {};
  if (!values.firstname.trim()) {
    errors.firstname = 'First name required';
  }
  if (!values.lastname) {
    errors.lastname = 'Last name required';
  }
  if (!values.email) {
    errors.email = 'Email address required';
  }

  if (!values.dob) {
    errors.dob = 'Date of Birth required';
  }
  if (!values.placeofbirth) {
    errors.placeofbirth = 'Place of Birth is required';
  }
  if(values.placeofbirth === "Other" && (values.placeofbirthother === null || !values.placeofbirthother.trim())){
    errors.placeofbirthother = 'Other is required';
  }

  return errors;

}


export function driverInfoErrors(values) {

  let errors = {};

  if (!values.country) {
    errors.country = 'Country required';
  }
  if (values.country === "Other" && (values.countryOther === null || !values.countryOther.trim())) {
    errors.countryOther = 'Other is required';
  }
  if (!values.licenceNumber) {
    errors.licenceNumber = 'Licence number is required';
  }

  return errors;
}


export function sendInvoiceErrors(values) {

  let errors = {};

  if (!values.validity) {
    errors.validity = 'validity required';
  }

  return errors;
}




import { useState, useEffect } from 'react';

const useForm = (callback, validate) => {
  const [values, setValues] = useState({
    firstname: '',
    lastname: '',
    password: '',
    password2: ''
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = e => {
    setErrors("")
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    setErrors(validate(values));
    setIsSubmitting(true);
  };

  useEffect(
    () => {
      if (Object.keys(errors).length === 0 && isSubmitting) {
        callback();
      }
    },
    [errors]
  );

  return { handleChange, handleSubmit, values, errors };
};

export default useForm;









// import { useState, useEffect } from 'react';

// const useForm = (callback, validate) => {
//   const [values, setValues] = useState({
//     firstname: '',
//     lastname: '',
//     password: '',
//     password2: ''
//   });



//   const [errors, setErrors] = useState({});
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   const handleChange = e => {
//     const { name, value } = e.target;
//     // setIsSubmitting(false);
//     setValues({
//       ...values,
//       [name]: value
//     });
//   };

//   const handleSubmit = e => {
//     e.preventDefault();

//     setErrors(validate(values));
//     setIsSubmitting(true);
//   };

//   useEffect(
//     () => {
//       if (Object.keys(errors).length === 0 && isSubmitting) {
//         if (callback) {
//           callback()
//         }
//       }
//     },
//     [errors]
//   );

//   return { handleChange, handleSubmit, values, errors };
// };

// export default useForm;
import React from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import moment from "moment-timezone";
import { API } from "../../../../route/services";

let table = ""
class AdminDashboard extends React.Component {
  componentDidMount() {
    const currentPage = localStorage.getItem('olCurrentPage')
    var changeValue = (id) => {
      this.props.dataChangehandler(id);
    };

    setInterval(() => {
      // console.log("reload", this.props.isReload);
      if (this.props.isReload) {
        table.ajax.reload(null, false);
        this.props.reloadOff();
      }
    }, 1000);
    table = $("#dataTable").DataTable({
      processing: true,
      paging: true,
      pageLength: 25,
      lengthChange: false,
      searching: {
        regex: false,
      },
      serverSide: true,
      select: true,
      order: [[0, "desc"]],
      ajax: {
        url: `${API.adminOrderTable}?format=datatables`,
        totalUsers: "data.iTotalRecords",
        iDisplayLength: 25,
        defaultContent: "",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        error: function (error) {
          if (error.status === 401) {
            localStorage.clear();
            window.location.href="/login";
          }
        }
        // dataSrc:''
      },
      columns: [
        {
          data: "id",
          name: "id",
          searchable: true,
        },
        {
          data: "first_name",
          name: "user__first_name",
          searchable: true,
          render: function (data, type, row) {
            return  row.first_name.replace(new RegExp("(?:\\b|_)([a-z])", "g"), function($1) {
              return $1.toUpperCase();
            });
          }
        },
        {
          data: "last_name",
          name: "user__last_name",
          searchable: true,
          render: function (data, type, row) {
           return  row.last_name.replace(new RegExp("(?:\\b|_)([a-z])", "g"), function($1) {
              return $1.toUpperCase();
            });
          }
        },
        {
          data: "created",
          name: "created",
          searchable: true,
          render: function(data, type, row) {
            if (row.created !== null && row.created !== "") {
              var utcDateTime = moment(row.created).tz("America/Los_Angeles").format(
                "YYYY-MM-DD hh:mm A"
              );
              return utcDateTime;
            } else {
              return ``;
            }
          },
        },
        {
          data: "order_status",
          name: "order_status",
        },
        {
          data: "email",
          name: "user__email",
          searchable: true,
          visible: false,
        },
      ],
      createdRow: (row, data, dataIndex) => {
        if (data.id == this.props.orderId) {
          localStorage.setItem("orderId", data.id);
          $(row).addClass("dt-tr-selected");
        }
        if(data.order_status === 'Japan/SK/China'){
          $(row).addClass("bg-jsc");
        }else if(data.order_status === 'Headshot'){
          $(row).addClass("bg-hs");
        }else if(data.order_status === 'Incomplete'){
          $(row).addClass("bg-ic");
        } else if(data.order_status === 'Completed'){
          $(row).addClass("bg-c");
        } else if(data.order_status === 'Suspended') {
          $(row).addClass("bg-suspended");
        } else if(data.order_status === 'Unpaid') {
          $(row).addClass("bg-unpaid");
        }

      },
    });
    setTimeout(() => {
      if (currentPage !== undefined && currentPage !== null && !isNaN(parseInt(currentPage))) {
        table.page(parseInt(currentPage)).draw('page');
      }
    }, 500)

    $(document).ready(function() {
      $("#dataTable tbody").on("click", "tr", function() {
        var data = table.row(this).data();

        if ($(this).hasClass("dt-tr-selected")) {
          // $(this).removeClass("dt-tr-selected");
        } else {
          table.$("tr.dt-tr-selected").removeClass("dt-tr-selected");
          $(this).addClass("dt-tr-selected");
        }

        changeValue(data.id);
        localStorage.setItem("orderId", data.id);
      });
      $("#dataTable").css("cursor", "pointer");
    });
  }

  componentDidUpdate() {
    if (table) {
      setTimeout(() => {
        localStorage.setItem('olCurrentPage', table.page())
      }, 1000)
    }
  }

  render() {
    return (
      <>
        <div className="MainDiv">
          <table
            id="dataTable"
            className="display table-responsive"
            // style={{ cursor: pointer }}
          >
            <thead>
              <tr id="tr">
                <th>Id</th>
                <th>First</th>
                <th>Last</th>
                <th>Time Created</th>
                <th>Status</th>
                {/* <th>Shipped</th> */}
              </tr>
            </thead>
            <tbody></tbody>
            {/* <tfoot>
              <tr>
                <th>Id</th>
                <th>First</th>
                <th>Last</th>
                <th>Time Created</th>
                <th>Status</th>
                <th>Shipped</th>
              </tr>
            </tfoot> */}
          </table>
        </div>
      </>
    );
  }
}

export default AdminDashboard;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthContextProvider } from './store/auth-context';
import { UserContextProvider } from './store/user-context';

ReactDOM.render(
  <>
    <AuthContextProvider >
      <UserContextProvider>
        <App />
      </UserContextProvider>
    </AuthContextProvider >
  </>,
  document.getElementById('root')
);
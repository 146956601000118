import React, {useCallback, useEffect} from "react";
import AdminSidebar from "./../../adminsidebar/AdminSidebar";
import {Row, Col, Container, Image, Alert, Button} from "react-bootstrap";
import axios from "axios";
import Datatable from "./datatable/Datatable";
import UsersDetail from "./userdetails/UsersDetail";
import {API} from "../../../route/services";
import Loader from "./../loader/Loader";
import {useState} from "react";
import $ from "jquery";
import {CountryList} from "../../../common/country-list"
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";

function AdminShipping() {
    const [loader, setLoader] = useState(false);
    const [userId, setUserId] = useState(false);
    const [isPrepDialogOpen, setIsPrepDialogOpen] = useState(false);
    const [alertPStatus, setAlertPStatus] = useState(false);
    const [width, setwith] = useState(window.innerWidth);
    const [shfOrderId, setShfOrderId] = useState(null);
    const [data, setData] = useState({
        date_shipped: "",
        order: "",
        order_not_arrived_form: false,
        phone_number: "",
        shipping_address: "",
        shipping_address2: "",
        shipping_city: "",
        shipping_country: "",
        shipping_country_other: "",
        shipping_option: "",
        shipping_state: "",
        shipping_zipcode: "",
        tracking_id: "",
        validate_address: false,
        shipCheck: false,
        is_apo: false
    });

    const [portalData, setPortalData] = useState({
        phone_number: "",
        shipping_address: "",
        shipping_address2: "",
        shipping_city: "",
        shipping_country: "",
        shipping_state: "",
        shipping_zipcode: "",
        user: "",
    });

    useEffect(() => {
        CountryList();
    });

    function dataClickChangehandler(id, userId, shfOrderId) {
        setShfOrderId(shfOrderId);
        setUserId(userId);
        setLoader(true);
        setData({});
        let isOrderArrived = false;
        axios.get(`${API.adminUsersShippingAddressDetails}/${id}/`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            mode: "no-cors",
        }).then((response) => {
            setData(response.data);
            setLoader(false);
        }).catch((error) => {
            console.log("axios error:", error.response);
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href="/login";
            }
        });

        axios.get(`${API.adminPortalShippingAddressDetails}/${userId}/`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            mode: "no-cors",
        }).then((response) => {
            setPortalData(response.data);
            setLoader(false);
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href="/login";
            }
            console.log("axios error:", error.response);
        });
    }
    const toggleMenu = useCallback( () => {
            $(".sidebar").toggleClass("sidebarshow");
            $(".dashboard-data").toggleClass("dashboard-data-show");
        }
    , []);

    function changeUserShipping(id) {
        axios.get(`${API.userShippingDetail}/${id}/`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            mode: "no-cors",
        }).then((response) => {
            let className = response.data.status.replace(/ /g,'');
            response.data.status = response.data.status === "DHL" ? "FedEx" : response.data.status;
            $("#dts-" + id).removeClass().addClass('dt-status option-' + className.toLowerCase()).text(response.data.status);
            $("#dt-" + id).removeClass().addClass('dt-status status-' + response.data.shipping_status.toLowerCase()).text(response.data.shipping_status);
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href="/login";
            }
        });
    }

    async function generatePrepLabels() {
        setIsPrepDialogOpen(true);
        await axios.post(`${API.generatePrepLabels}`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            }
        ).then((response) => {
            setTimeout(() => {
                setIsPrepDialogOpen(false);
                window.location.reload();
            }, 3000);
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href="/login";
            } else {
                setIsPrepDialogOpen(false);
                setAlertPStatus(true);
                console.log(error);
            }
        });
    }

    return (
        <>
            <AdminSidebar></AdminSidebar>
            <div className="dashboard-data admin-wrapper">
                <div className="document-section d-flex align-items-center justify-content-between pt-2 pb-2 ps-3 pe-3">
                    <Row className="w-100">
                        <Col className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="d-flex w-100 prep-content">
                                <h3 className="p-0 m-0">Admin Shipping</h3>
                                <div className="prep-button text-center">
                                    <Button variant="primary" onClick={generatePrepLabels.bind(this)}>Prep Labels</Button>
                                </div>
                                {width < 768 &&
                                <p className="menuicon mb-0" onClick={toggleMenu}>
                                    <Image
                                        src={require("../../../assets/images/menu.svg").default}
                                        alt="menu"
                                        className="d-block d-md-none d-lg-none"
                                    />
                                </p>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
                <Container fluid>
                    <Row>
                        <Col className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                            <Datatable dataChangehandler={dataClickChangehandler}/>
                        </Col>

                        <Col className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                            {loader && (
                                <div className="ship-loader">
                                    <Loader/>
                                </div>
                            )}
                            {data.order && <UsersDetail data={data} portalData={portalData} userId={userId} shfOrderId={shfOrderId} parentCallback={changeUserShipping}/>}
                        </Col>
                    </Row>
                </Container>
            </div>

            {alertPStatus &&
            <div className="alert-message">
                <Alert className="alert-danger">
                    <Image src={require("../../../assets/images/not-received.svg").default} alt="Document" className="me-2"/>
                    Something went wrong, Please try after sometime.
                </Alert>
            </div>
            }
            <Dialog open={isPrepDialogOpen} onClose={false} maxWidth={'sm'} fullWidth={true}>
                <DialogTitle>
                    Please Wait...
                </DialogTitle>
                <DialogContent dividers>
                    <div className="prep-load">
                        <div className="photoloader"><Loader/></div>
                    </div>
                    <p className="m-0 text-center">Generating Prep Labels</p>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default AdminShipping;

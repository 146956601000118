import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

function ShippingUSPSOvernight(props) {
  return (
    <>
      <section
        className="delivery-section sc-mt"
        id="ShippingUSPSOvernight"
        // style={{ display: "none" }}
      >
        {/*<h3>IF Shipping to US and USPS Tracking, USPS 2-Day, USPS Overnight</h3>*/}
        <div className="Shipping-document">
          <p className="text-white mb-0">Your order has been shipped</p>
            {props.trackingId !== "" &&
            <h3 className="text-white">
                Your Tracking Number is{" "}
                <span className="text-warning">
              {" "}
                    {props.trackingId || "-------"}
            </span>
            </h3>
            }
          <Image
            className="deliver-document"
            src={
              require("./../../../../../assets/images/usps-tracking.png")
                .default
            }
            alt="USPS"
          />
         <a href="https://tools.usps.com/go/TrackConfirmAction_input" className="text-white text-decoration-none">
            Use USPS to track
          </a>
        </div>
      </section>
    </>
  );
}

export default ShippingUSPSOvernight;

import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";


function InternationallyDHL() {
    return (
        <>
           <section
                className="delivery-section sc-mt"
                id="InternationallyDHL"
                // style={{ display: "none" }}
              >
                {/*<h3>IF Shipping Internationally and DHL</h3>*/}
                <div className="Shipping-document">
                  <p className="text-white mb-0">
                    Your order will be shipped in shortly!
                  </p>
                  <h3 className="text-white">
                    We will provide tracking as soon as it is available.
                  </h3>
                  <Image
                    className="deliver-document"
                    src={require("./../../../../../assets/images/document.png").default}
                    alt="Document"
                  />
                  <Link to="/tracking"></Link>
                </div>
              </section> 
        </>
    )
}

export default InternationallyDHL;
import React, {useEffect, useState} from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Alert,
  Image,
} from "react-bootstrap";
import useOrderTrack from "./orderTrackHook";
import validateShipping from "./validateShippingInfo";
import CountrySelect from "react-bootstrap-country-select";
import { CountryList, shippingCountry } from "../../../common/country-list";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { API } from "../../../route/services";

function Ordertrack() {
  const history = useHistory();
  const [country, setCountryValue] = useState("");
  const [countryError, setCountryError] = useState("");
  const apiUrl = process.env.REACT_APP_SERVER_URL;
  const userId = localStorage.getItem("id");
  const [alert, setAlert] = useState(false);
  const [isLoadCountry, setCountry] = useState(false);

  useEffect(() => {
    CountryList();
    if(shippingCountry.length === 0) {
      setTimeout(() => {
        setCountry(true);
      }, 1000);
    } else {
      setCountry(true);
    }
  });


  function handleCountryChange(e) {
    if (e && e.id) {
      setCountryValue(e.name);
    }
  }

  const {
    changeOrderTrack,
    handleTrackSubmit,
    ordervalue,
    ordererrors,
  } = useOrderTrack(submitOrderInfo, validateShipping);

  async function submitOrderInfo(e) {
    if (!country) {
      return setCountryError("country is required");
    }
    setCountryError("");
    if (!Object.keys(validateShipping(ordervalue)).length) {
      setAlert(true);
      await axios
        .post(
          `${API.usersOrderQuerySumbit}`,
          {
            id: userId,
            shipping_address: ordervalue.address,
            shipping_address2: ordervalue.address2,
            shipping_city: ordervalue.city,
            shipping_state: ordervalue.state,
            shipping_country: country,
            shipping_zipcode: ordervalue.zipcode,
            phone_number: ordervalue.phonenumber,
            description: ordervalue.description,
          },

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            mode: "no-cors",
          }
        )
        .then((response) => {
          setAlert(true);
          history.push("/user/dashboard");
        })
        .catch((error) => {
          console.log("post order not arrived error", error);
        });
    }
  }

  return (
    <>
      <Container>
        <section className="processing-section">
          <div className="processing-title">
            <h3>Order hasn't arrived</h3>
            <p>we'll help you figure this out.</p>
          </div>
          <p className="text-center">
            Sometimes the post office can take a few days longer than expected
            to deliver an order. you should recelve order by ______.
          </p>
          <p className="text-center">
            For now, let's make sure that the address you have on file is
            correct.
          </p>
          <Row className="justify-content-center">
            <Col className="col-12 col-sm-12 col-md-10 col-lg-10">
              <div className="ordertrack-section">
                <p>
                  Please provide your shipping address so we can verify it
                  against our records.
                </p>
                <Form onSubmit={(e) => handleTrackSubmit(e)}>
                  <Row>
                    <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <Form.Group className="form-group">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          type="text"
                          name="address"
                          className="form-control"
                          onChange={changeOrderTrack}
                          value={ordervalue.address}
                          placeholder=""
                        ></Form.Control>
                        {ordererrors.address && (
                          <small className="text-danger">
                            This field is required
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                    <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <Form.Group className="form-group">
                        <Form.Label>Address 2</Form.Label>
                        <Form.Control
                          type="text"
                          name="address2"
                          className="form-control"
                          onChange={changeOrderTrack}
                          value={ordervalue.address2}
                          placeholder=""
                        ></Form.Control>
                        {ordererrors.address2 && (
                          <small className="text-danger">
                            This field is required
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                    <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Group className="form-group">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          name="city"
                          className="form-control"
                          onChange={changeOrderTrack}
                          value={ordervalue.city}
                          placeholder=""
                        ></Form.Control>
                        {ordererrors.city && (
                          <small className="text-danger">
                            This field is required
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                    <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Group className="form-group">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type="text"
                          name="state"
                          className="form-control"
                          onChange={changeOrderTrack}
                          value={ordervalue.state}
                          placeholder=""
                        ></Form.Control>
                        {ordererrors.state && (
                          <small className="text-danger">
                            This field is required
                          </small>
                        )}
                      </Form.Group>
                    </Col>

                     {isLoadCountry && shippingCountry.length > 0 && (
                    <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Group className="form-group">
                        <Form.Label>Country</Form.Label>
                        <CountrySelect
                          name="country"
                          placeholder={country}
                          // value={country}
                          onChange={handleCountryChange}
                          countries={shippingCountry}
                        />
                        {countryError && (
                          <p className="text-danger">{countryError}</p>
                        )}
                      </Form.Group>
                    </Col>
                     )}
                    <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <Form.Group className="form-group">
                        <Form.Label>ZIP Code</Form.Label>
                        <Form.Control
                          type="text"
                          name="zipcode"
                          className="form-control"
                          onChange={changeOrderTrack}
                          value={ordervalue.zipcode}
                          placeholder=""
                        ></Form.Control>
                        {ordererrors.zipcode && (
                          <small className="text-danger">
                            This field is required
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                    <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <Form.Group className="form-group">
                        <Form.Label>Phone number</Form.Label>
                        <Form.Control
                          type="text"
                          name="phonenumber"
                          className="form-control"
                          onChange={changeOrderTrack}
                          value={ordervalue.phonenumber}
                          placeholder=""
                        ></Form.Control>
                        {ordererrors.phonenumber && (
                          <small className="text-danger">
                            This field is required
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                    <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <Form.Group className="form-group">
                        <Form.Label>
                          Please provide and additional information.
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          name="description"
                          onChange={changeOrderTrack}
                          value={ordervalue.description}
                          rows={3}
                        />
                        {/* <small className="text-danger">
                          This field is required
                        </small> */}
                      </Form.Group>
                    </Col>
                  </Row>
                  {alert && (
                    <div className="alert-message">
                      <Alert className="alert-success">
                        <Image
                          src={
                            require("../../../assets/images/success.svg")
                              .default
                          }
                          alt="Document"
                          className="me-2"
                        />
                        Order Query submited successfully
                      </Alert>
                    </div>
                  )}
                  <div className="d-flex justify-content-end">
                    <Button
                      type="submit"
                      className="btn btn-primary ordertrack-button"
                      onClick={submitOrderInfo}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </section>
      </Container>
    </>
  );
}

export default Ordertrack;

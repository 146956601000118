import React, {useEffect, useState} from "react";
import {Row, Col, Form, Button, Alert, Image, Modal} from "react-bootstrap";
import CountrySelect from "react-bootstrap-country-select";
import {shippingCountry} from "../../../../common/country-list";
import axios from "axios";
import {API} from "../../../../route/services";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import Loader from "../../../../user/component/loader/Loader";
import ImageResize from "../../../../user/component/userdashboard/carriersection/uploadphoto/photo";

const UsersDetail = (props) => {
    const [data, setData] = useState(props.data);
    const [userId, setUserId] = useState(props.userId);
    const [portalData, setPortalData] = useState(props.portalData);
    const [alert, setAlert] = useState({success: false, invalid: false, mailSend: false});
    const [alertPStatus, setAlertPStatus] = useState(false);
    const [addbtnDisabled, setaddbtnDisabled] = useState(false);
    const [deleteEntry, setIsDeleteEntry] = useState({isDeleteEntry: false, disabled: false});
    const [deleteModel, setDeleteModel] = useState(false);
    const [deleteEntryError, setDeleteEntryError] = useState(false);
    const [deleteEntryErrorMsg, setDeleteEntryErrorMsg] = useState("");
    const [refundApo, setRefundApo] = useState(false);
    const [refundApoAlert, setAlertRefundApo] = useState({isError: false, success: false, msg: ''});

    function onChangeColor(event) {
        setData({...data, shipping_option: event.target.value});
    }

    function changeShipCheckHandler(e) {
        setData({...data, shipCheck: !data.shipCheck});
    }

    useEffect(() => {
        setTimeout(() => {
            setAlert({success: false, invalid: false});
            setAlertPStatus(false);
            setDeleteEntryError(false);
            setAlertRefundApo({isError: false, success: false, msg: ''})
        }, 9000);
    });

    async function changeShippingData(status) {
        let apiUrl = '';
        let apiData = {};
        if (status === 'save') {
            apiUrl = API.userShippingData + '/' + data.order + '/';
            apiData = {
                "order": data.order,
                "shipping_address": data.shipping_address,
                "shipping_address2": data.shipping_address2,
                "shipping_city": data.shipping_city,
                "shipping_state": data.shipping_state,
                "shipping_country": data.shipping_country,
                "shipping_country_other": data.shipping_country === "Other" ? data.shipping_country_other : "",
                "shipping_zipcode": data.shipping_zipcode,
                "date_shipped": data.date_shipped,
                "shipping_option": data.shipping_option,
                "tracking_id": data.tracking_id,
                "phone_number": data.phone_number,
                "is_apo": data.is_apo
            };
        } else {
            apiUrl = API.userShippingStatus + '/' + data.order + '/';
            apiData = {shipping_status: status}
        }
        await axios.put(apiUrl, apiData,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            }
        ).then((response) => {
            setAlert({success: true});
            onUpdate(data.order);
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href="/login";
            } else {
                setAlert({...alert, invalid: true});
                console.log(error);
            }
        });
    }

    function sendAddressMail(status){
        setaddbtnDisabled(true);
        axios.post(`${API.addressMail}${data.order}/`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            }
        ).then((response) => {
            setAlert({mailSend: true});
            setaddbtnDisabled(false);
            onUpdate(data.order);
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href="/login";
            } else {
                setAlertPStatus(true);
                setaddbtnDisabled(false);
                console.log(error);
            }
        });
    }

    function DeleteEntry(){
        if (props.shfOrderId !== null) {
            setIsDeleteEntry({...deleteEntry, disabled: true});
            axios.delete(`${API.deleteEntry}${props.shfOrderId}/`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    mode: "no-cors",
                }
            ).then((response) => {
                setIsDeleteEntry({disabled: false, isDeleteEntry: true});
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/login";
                } else {
                    if (error.response.data && error.response.data.detail) {
                        setDeleteEntryErrorMsg(error.response.data.detail)
                    } else {
                        setDeleteEntryErrorMsg("Something went wrong!");
                    }
                    setDeleteEntryError(true);
                    setIsDeleteEntry({disabled: false, isDeleteEntry: false});
                }
            });
        }
    }

    function handleRefundApo(){
        setRefundApo(true);
        axios.post(`${API.refundAPO}${data.order}/`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            }
        ).then((response) => {
            setRefundApo(false);
            if ('details' in response.data && response.data.details) {
                var message = response.data.details
            } else {
                var message = "Refunded successfully."
            }
            setAlertRefundApo({...refundApoAlert, success: true, msg: message});

            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }).catch((error) => {
            setRefundApo(false);
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href="/login";
            } else {
                if (error.response.data && error.response.data.detail) {
                    setAlertRefundApo({...refundApoAlert, msg: error.response.data.detail, isError: true});
                } else {
                    if ('error' in error.response.data ) {
                        if (error.response.data.error.split('-').length > 1) {
                            var message = error.response.data.error.split('-')[1]
                        } else {
                            var message = error.response.data.error
                        }
                    } else {
                        var message = "Something went wrong!"
                    }
                    setAlertRefundApo({...refundApoAlert, msg: message, isError: true});
                }
                setTimeout(() => {}, 3000);
                console.log(error);
            }
        });
    }

    const onUpdate = (id) => {
        props.parentCallback(id);
    };

    function handleDeleteModel(show) {
        if(!show) {
            setDeleteModel(false);
            DeleteEntry();
        } else {
            setDeleteModel(true);
        }
    }

    function handleApo(event) {
        setData({...data, is_apo: !data.is_apo});
    }

    return (
        <>
            <section className="shipping-info-section">
                <Row>
                    <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="shipping-button-info text-end shipping-btn-d">
                            <Button variant="danger" className="btn-font mb-2" style={{marginRight: "5px"}} onClick={changeShippingData.bind(this, 'hold')}>Hold</Button>
                            <Button variant="success" className="btn-font mb-2" onClick={changeShippingData.bind(this, 'ready')}>Unhold</Button>
                            <Button variant="primary" className="btn-font mb-2" onClick={sendAddressMail.bind(this, 'hold')} disabled={addbtnDisabled}>Address</Button>
                            <Button variant="success" className="btn-font mb-2" onClick={changeShippingData.bind(this, 'save')}>Save</Button>
                            <Button className="btn-font mb-2 btn-apo" disabled={refundApo} onClick={handleRefundApo}>Refund APO</Button>
                            <Button className="btn-font mb-2 btn-del" disabled={deleteEntry.disabled} onClick={handleDeleteModel.bind(this, true)}>Delete Entry</Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <Form className="form" method="post">
                            <div className="order-shipping-info">
                                <p>Shipping Information</p>
                            </div>
                            <Row className="first-row">
                                <Col className="col-12 col-sm-9 col-md-9 col-lg-9 pr-0">
                                    <Form.Group className="form-group">
                                        <div className="d-flex align-items-center mb-2">
                                            {/*    <Form.Check aria-label="option 1" className="me-2" name="checkDLShow" value={data.shipCheck} checked={data.shipCheck} onChange={changeShipCheckHandler}/>*/}
                                            <Form.Label className="m-0 p-0">{" "}Shipping option</Form.Label>
                                        </div>

                                        <Form.Control as="select" aria-label="Default select example" onChange={onChangeColor} value={data.shipping_option}>
                                            <option value="">--Select Shipping Option--</option>
                                            <option value="DHL">FedEx</option>
                                            <option value="USPS Free">USPS Free</option>
                                            <option value="USPS First Class">USPS First Class</option>
                                            <option value="USPS 2 Day">USPS 2-Day</option>
                                            <option value="USPS Overnight">USPS Overnight</option>
                                            <option value="First Class International">
                                                First Class International
                                            </option>
                                            <option value="AUS Post">AUS Post</option>
                                            {/*For Royal Mail*/}
                                            {/*<option value="RM">RM</option>*/}
                                            {/*<option value="RM Tracked">RM Tracked</option>*/}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-3 col-md-3 col-lg-3">
                                    <Form.Group className="form-group apo-check-box">
                                        <div className="d-flex align-items-center">
                                            <Form.Check
                                                aria-label="option 1"
                                                className="me-2"
                                                name="apo"
                                                value={data.is_apo}
                                                checked={data.is_apo}
                                                onChange={handleApo}
                                            />
                                            <Form.Label className="m-0 p-0">APO</Form.Label>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Date Shipped</Form.Label>
                                        <Form.Control type="date" className="form-control" name="state" value={data.date_shipped !== null ? data.date_shipped : ""}
                                                      onChange={(e) => setData({...data, date_shipped: e.target.value})}/>
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control type="text" className="form-control" name="address" value={data.shipping_address}
                                                      onChange={(e) => setData({...data, shipping_address: e.target.value})}/>
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Address 2</Form.Label>
                                        <Form.Control type="text" className="form-control" name="address2" value={data.shipping_address2}
                                                      onChange={(e) => setData({...data, shipping_address2: e.target.value})}/>
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control type="text" className="form-control" name="city" value={data.shipping_city}
                                                      onChange={(e) => setData({...data, shipping_city: e.target.value})}/>
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control type="text" className="form-control" name="state" value={data.shipping_state}
                                                      onChange={(e) => setData({...data, shipping_state: e.target.value})}/>
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>ZIP Code</Form.Label>
                                        <Form.Control type="text" className="form-control" name="zipcode" value={data.shipping_zipcode}
                                                      onChange={(e) => setData({...data, shipping_zipcode: e.target.value})}/>
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Country </Form.Label>
                                        <CountrySelect name="country" placeholder={data.shipping_country} countries={shippingCountry}
                                                       onChange={(e) => {
                                                           if (e && e.id) {
                                                               setData({...data, shipping_country: e.name});
                                                           }
                                                       }} className={data.shipping_country === "Other" ? "other-country" : ""}/>
                                    </Form.Group>
                                </Col>
                                {data.shipping_country === "Other" &&
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Other</Form.Label>
                                        <Form.Control type="text" className="form-control" name="other" value={data.shipping_country_other}
                                                      onChange={(e) => setData({...data, shipping_country_other: e.target.value})}/>
                                    </Form.Group>
                                </Col>
                                }
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control type="number" className="form-control" name="number" value={data.phone_number}
                                                      onChange={(e) => setData({...data, phone_number: e.target.value})}/>
                                    </Form.Group>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Tracking</Form.Label>
                                        <Form.Control type="string" className="form-control" name="number" value={data.tracking_id}
                                                      onChange={(e) => setData({...data, tracking_id: e.target.value})}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>

                       {/* {data.order_not_arrived_form && (
                            <Form className="form" method="post">
                                <div className="order-shipping-info text-end">
                                    <div className="shipping-button-info mt-4 mb-4">
                                        <Button variant="primary">Valid</Button>
                                        <Button variant="primary">Invalid</Button>
                                    </div>
                                </div>
                                <Row className="second-row">
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <Form.Group className="form-group">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control type="text" className="form-control" name="address" value={portalData.shipping_address}/>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <Form.Group className="form-group">
                                            <Form.Label>Address 2</Form.Label>
                                            <Form.Control type="text" className="form-control" name="address2" value={portalData.shipping_address2}/>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-6">
                                        <Form.Group className="form-group">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control type="text" className="form-control" name="city" value={portalData.shipping_city}/>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-6">
                                        <Form.Group className="form-group">
                                            <Form.Label>State</Form.Label>
                                            <Form.Control type="text" className="form-control" name="state" value={portalData.shipping_state}/>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-6">
                                        <Form.Group className="form-group">
                                            <Form.Label>ZIP Code</Form.Label>
                                            <Form.Control type="text" className="form-control" name="zipcode" value={portalData.shipping_zipcode}/>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-6">
                                        <Form.Group className="form-group">
                                            <Form.Label>Country </Form.Label>
                                            <CountrySelect name="country" placeholder={portalData.shipping_country} countries={shippingCountry}/>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-6">
                                        <Form.Group className="form-group">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control type="number" className="form-control" name="number" value={portalData.phone_number}/>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-6">
                                        <Form.Group className="form-group">
                                            <Form.Label>Tracking</Form.Label>
                                            <Form.Control type="string" className="form-control" name="number" value={data.tracking_id}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        )}*/}
                    </Col>
                    <Col className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <ImageResize userId={userId} adminUser={true} ordeId={data.order} statusChange={onUpdate}/>
                    </Col>
                </Row>
            </section>

            <div className="modal-dialog modal-xl">
                <Modal show={deleteModel} onHide={false} centered>
                    <Modal.Body className="text-center">
                        <h3>Are you sure you want to delete this.</h3>
                        <div className="modal-btn">
                            <button type="submit" className="btn btn-primary" onClick={handleDeleteModel.bind(this, false)}>Ok</button>
                            <button type="submit" className="btn btn-danger btn btn-primary" onClick={() => {setDeleteModel(false);setIsDeleteEntry({disabled: false, isDeleteEntry: false})}}>Cancel</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

            {alert.success && (
                <div className="alert-message">
                    <Alert className="alert-success">
                        <Image src={require("./../../../../assets/images/success.svg").default} alt="Document" className="me-2"/>
                        Shipping Information updated successfully
                    </Alert>
                </div>
            )}

            {alert.invalid && (
            <div className="alert-message">
                <Alert className="alert-danger">
                    <Image src={require("./../../../../assets/images/not-received.svg").default} alt="Document" className="me-2"/>
                    Please Enter a valid Information
                </Alert>
            </div>
            )}

            {alert.mailSend && (
                <div className="alert-message">
                    <Alert className="alert-success">
                        <Image src={require("./../../../../assets/images/success.svg").default} alt="Document" className="me-2"/>
                        Mail sent successfully
                    </Alert>
                </div>
            )}

            {alertPStatus &&
            <div className="alert-message">
                <Alert className="alert-danger">
                    <Image src={require("./../../../../assets/images/not-received.svg").default} alt="Document" className="me-2"/>
                    Something went wrong, Please try after sometime.
                </Alert>
            </div>
            }
            {deleteEntry.isDeleteEntry &&
            <div className="alert-message">
                <Alert className="alert-success">
                    <Image src={require("./../../../../assets/images/success.svg").default} alt="Document" className="me-2"/>
                    Shipping record deleted successfully.
                </Alert>
            </div>
            }
            {refundApoAlert.success &&
            <div className="alert-message">
                <Alert className="alert-success">
                    <Image src={require("./../../../../assets/images/success.svg").default} alt="Document" className="me-2"/>
                    {refundApoAlert.msg}
                </Alert>
            </div>
            }
            {refundApoAlert.isError &&
            <div className="alert-message">
                <Alert className="alert-danger">
                    <Image src={require("./../../../../assets/images/not-received.svg").default} alt="Document" className="me-2"/>
                    {refundApoAlert.msg}
                </Alert>
            </div>
            }
            {deleteEntryError &&
            <div className="alert-message">
                <Alert className="alert-danger">
                    <Image src={require("./../../../../assets/images/not-received.svg").default} alt="Document" className="me-2"/>
                    {deleteEntryErrorMsg}
                </Alert>
            </div>
            }
        </>
    );
};
export default UsersDetail;

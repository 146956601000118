import {Link, useHistory} from "react-router-dom";
import {Image, Button, Modal} from "react-bootstrap";
import axios from "axios";
import {API} from "../../../../../route/services";
import Loader from "../../../../../admin/component/loader/Loader";
import React, {useState} from "react";

function Banner31(props) {
    const userId = localStorage.getItem("id");
    const history = useHistory();

    const [modelMsg, setModelMsg] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [showModel, setShowModel] = useState(false);

    function submitHandler(value) {
        // setModelMsg('Processing. Please wait.');
        setLoading(true);
        setShowModel(true);
        axios.post(`${API.banner31OrderNotReceived}/`,{},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            }
        ).then((response) => {
            setModelMsg('Please check your email for more information from our support team.');
            setLoading(false);
        }).catch((error) => {
            setShowModel(false);
            setLoading(false);
            setModelMsg('');
        });
    }

    return (
        <>
            <section className="delivery-section sc-mt" id="ShippedUSPSOvernight">
                <div className="Shipping-document-banner">
                    <div className="shipping-content-details">

                        <p className="text-white" style={{padding: "5px 50px"}}>
                            Your documents are in transit. The shipping option you chose
                            does not include a tracking number and may take up to {props.isOrderInternational ? 20 : 10} business days to arrive.
                        </p>
                        <h3 className="text-white banner-31-title">Expect your documents to arrive by <br/>{props.arrivalDate}</h3>
                        <br />
                        {
                            props.getMomentObj(new Date()).isAfter(props.getMomentObj(props.arrivalDate))
                            && (<p className="text-white" style={{padding: "5px 50px"}}>
                                    Please click button below if your documents have not arrived.
                                </p>)
                            || (
                                <p className="text-white" style={{padding: "5px 50px"}}>
                                    If you need your documents to arrive sooner, please select an
                                    expedited shipping option by using the link below.
                                </p>
                            )
                        }
                        {
                            props.getMomentObj(new Date()).isAfter(props.getMomentObj(props.arrivalDate))
                            && (<div className="banner-31-btn">
                                <div>
                                    <Button
                                        type="button"
                                        className="btn btn-warning"
                                        onClick={submitHandler.bind(this, true)}
                                    >
                                        My Documents have not arrived
                                    </Button>
                                </div>
                            </div>)
                            || (
                                <div className="banner-31-btn">
                                    <div>
                                        <Button
                                            type="button"
                                            className="btn btn-warning"
                                            onClick={() => history.push({pathname: "/user/upgradeshipping"})}
                                        >
                                            Add Expedited Shipping
                                        </Button>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                </div>
                {
                    (
                        <div className="modal-dialog modal-xl">
                            <Modal show={showModel} onHide={false} centered>
                                <Modal.Body className="text-center">
                                    <h3>{modelMsg}</h3>
                                    {isLoading
                                        ? <Loader/>
                                        :(<div className="modal-btn">
                                            <Button type="submit"
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                        setLoading(false);
                                                        setModelMsg('');
                                                        setShowModel(false);
                                                    }} >
                                                OK
                                            </Button>
                                        </div>)
                                    }

                                </Modal.Body>
                            </Modal>
                        </div>
                    )
                }
            </section>
        </>
    );
}

export default Banner31;
import { Image } from "react-bootstrap";

function DocumentProcessing() {
  return (
    <>
      <section className="document-section order-processing">
        <h3>Digital Documents</h3>
        <div className="digital-document pt-5 pb-5">
          <p>Your documents will be ready in just a moment. </p>
          <Image
            src={
              require("./../../../../../assets/images/order-processing.svg")
                .default
            }
            alt="order processing"
            className="pe-3"
          />
        </div>
      </section>
    </>
  );
}

export default DocumentProcessing;

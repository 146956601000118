import { Link, useHistory } from "react-router-dom";
import { Image, Button, Alert } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import UserContext from "../../../../../store/user-context";
import { API } from "../../../../../route/services";

function UrgentlyProcessed(props) {
  const userId = localStorage.getItem("id");
  const apiUrl = process.env.REACT_APP_SERVER_URL;
  const [country, setCountry] = useState(true);
  const history = useHistory();
  const [alert, setAlert] = useState(false);

  const context = useContext(UserContext);
  const completeContext = context.isCompleteProfile;

  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
    }, 5000);
  });

  useEffect(() => {
    axios.get(`${API.userAllDetailCheckFilled}/${userId}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      mode: "no-cors",
    }).then((response) => {
      setCountry(response.data.complete_flag);
    }).catch((error) => {
      console.log("digital payment page error:", error.response);
    });
  }, []);

  async function submitHandler(e) {
    history.push({ pathname: "/user/urgentprocessingship", state: userId });
  }


  return (
    <>
      <section className="document-section urgently-processed">
        <div className="d-flex d-md-block d-lg-block align-items-center justify-content-between">
          <h3>Digital Documents</h3>

          <Link className="menuicon">
            <Image
              src={require("./../../../../../assets/images/menu.svg").default}
              alt="menu"
              className="d-block d-md-none d-lg-none"
            />
          </Link>
        </div>
        <div className="digital-document pt-5 pb-5">
          <p>Your order will be ready in the usual order processing window</p>
          <h3>Would you like to have your order urgently processed?</h3>
          <div className="d-block d-md-flex d-lg-flex align-items-center justify-content-between px-3">
            <Image
              src={require("./../../../../../assets/images/rabit.svg").default}
              alt="Rabit"
              className="pe-3"
            />
            {alert && (
              <div className="alert-message">
                <Alert className="alert-danger">
                  <Image
                    src={
                      require("./../../../../../assets/images/not-received.svg")
                        .default
                    }
                    alt="Document"
                    className="me-2"
                  />
                  {props.isUrgentPayment ? "Please First Fill Shipping Address" : "Please place an order to upgrade it to urgent"}
                </Alert>
              </div>
            )}
            <Button
              type="button"
              className="btn btn-success"
              onClick={submitHandler}
            >
              Yes, Please!
            </Button>
          </div>
        </div>
      </section>
    </>
  );
}

export default UrgentlyProcessed;

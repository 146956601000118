import { useState } from "react";

const useForm = (callback, validate) => {
  const [values, setValues] = useState({
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  });
  const [passerrors, setErrors] = useState({});

  const handlePasswordChange = (e) => {
    setErrors("")
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(values));
  };

  return { handlePasswordChange, handlePasswordSubmit, values, passerrors };
};

export default useForm;

import React from 'react';
import ReactCrop from 'react-image-crop';
import { Image, Alert } from "react-bootstrap";
import 'react-image-crop/dist/ReactCrop.css';
import Loader from '../../loader/Loader';
import CropModal from './cropeModel';
import { loadModels, getFullFaceDescription } from "./face";
import axios from 'axios';
import { API } from '../../../../route/services';


class ImageResizeByAdmin extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      src: null,
      crop: {},
      image: null,
      isComplete: false,
      error: false,
      isLoad: false,
      isClicked: false,
      openCropModal: false,
      croppedImageUrl: null,
      alert: false,
      isButton: false,
      avtar: require("./../../../../assets/images/avatar.png").default,
      userId: this.props.userId
    };
    this.setAlertTimer()
  }

  setAlertTimer() {
    setInterval(() => {
      this.setState({ alert: false })
    }, 3000)
  }

  onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState({ src: null })
      const reader = new FileReader();
      reader.addEventListener('load', async () => {
        this.setState({
          src: reader.result,
          isComplete: false,
          isLoad: true,
          croppedImageUrl: null,
          image: null
        })
      })
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };


  clearBtnHandler = async () => {
    this.setState({
      src: null,
      crop: {},
      image: null,
      isComplete: false,
      error: false,
      isLoad: false,
      isClicked: false,
      openCropModal: false,
      croppedImageUrl: null,
      isButton: false,
    })
  }

  DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

  unloadImageHandler = async () => {
    this.setState({ isLoad: true })
    var formData = new FormData();
    const data = this.DataURIToBlob(this.state.base64)
    formData.append("profile_photo", data, 'image.jpg');

    axios({
      // url: `${url}/auth/user-profile/${this.state.userId}/`,
      url: `${API.profileImageGetUpdate}/${this.state.userId}/`,
      method: "PUT",
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    })
      .then((response) => {
        console.log(response)
        this.setState({
          alert: true,
          isActive: true,
          croppedImageUrl: null,
          avtar: response.data.profile_photo,
          isButton: false,
          isLoad: false
        })
      })
      .catch((er) => {
        console.log(er);
      });
  }

  setbase64 = async (base64) => {
    this.setState({
      base64: base64
    })
  }

  setCropModal = async () => {
    this.setState({
      openCropModal: false
    })
  }

  componentDidMount = async () => {
    await loadModels()
    axios
      .get(
        `${API.profileImageGetUpdate}/${this.state.userId}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          mode: "no-cors",
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.profile_photo) {
          this.setState({
            avtar: response.data.profile_photo,
            isButton: false,
          })
        }
      })
      .catch((error) => {
        console.log("axios error:", error.response);
      });
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = async (image) => {
    this.imageRef = image;
    await getFullFaceDescription(this.state.src).then(async (fullDesc) => {
      // console.log(fullDesc, !!fullDesc.length);
      if (!!fullDesc.length) {
        this.setState({
          error: false,
          crop: {
            unit: 'px',
            width: fullDesc[0].detection.box._width + 50,
            height: fullDesc[0].detection.box._height + 50,
            x: fullDesc[0].detection.box._x - 25,
            y: fullDesc[0].detection.box._y - 25,
          },
          isButton: true,
        });
        return this.makeClientCrop(this.state.crop);
      }
      else {
        return this.setState({ error: true, isComplete: true, isLoad: false, isButton: false })
      }
    });
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = async (crop, percentCrop) => {
    this.setState({ crop, image: this.state.src, src: null })
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl, isComplete: true, isLoad: false });
    }
  }

  setCroppedImageUrlCallback = async (croppedImageUrl) => {
    this.setState({
      croppedImageUrl: croppedImageUrl
    })
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';


    ctx.drawImage(
      image,
      this.state.crop.x,
      this.state.crop.y,
      this.state.crop.width,
      this.state.crop.height,
      0,
      0,
      this.state.crop.width,
      this.state.crop.height ,
    )

    this.setState({ base64: canvas.toDataURL('image/jpeg') })

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        },
        'image/jpeg',
        1
      );
    });
  }

  render() {
    const { crop, croppedImageUrl, src, error, isComplete, isLoad, image, openCropModal, alert, isClicked, isButton, avtar } = this.state;

    return (
      <>
        <div className="avatar-profile" >
          <div className="upload-pic">
            {!isLoad && <Image src={avtar} alt="Avatar" />}
            <input type="file" accept="image/*" onChange={this.onFileChange} />
            {isLoad && <div className="photoloader"><Loader /></div>}
            {
              !isComplete && !croppedImageUrl
                ? (src && !croppedImageUrl && crop &&
                  <ReactCrop
                    src={src}
                    crop={crop}
                    ruleOfThirds
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />)
                : !error && croppedImageUrl &&
                <div className="crop-image" >
                  < img alt="Crop"
                    style={{ maxWidth: '100%' }}
                    src={croppedImageUrl}
                    onClick={() => {
                      this.setState({ isClicked: true, openCropModal: true })
                    }}
                  />
                </div>
            }
            {
              isClicked && <CropModal
                src={image}
                setCroppedImageUrl={this.setCroppedImageUrlCallback}
                openCropModal={openCropModal}
                setCropModal={this.setCropModal}
                base64={this.setbase64}
              />
            }
          </div>
        </div>

        {
          alert && (
            <div className="alert-message">
              <Alert className="alert-success">
                <Image
                  src={
                    require("../../../../assets/images/success.svg")
                      .default
                  }
                  alt="Document"
                  className="me-2"
                />
                Image updated successfully
              </Alert>
            </div>
          )
        }
        <div className="avatar-btn">
          {isButton && !isLoad && <button className="btn btn-outline-primary" onClick={this.clearBtnHandler}>Clear</button>}
          {isButton && !isLoad && <button className="btn btn-primary" onClick={this.unloadImageHandler}>Save</button>}
        </div>
        {!isLoad && error && <p className="error-message">Upload A Clear Photo of Your Face</p>}
      </>
    )
  }
}

export default ImageResizeByAdmin;




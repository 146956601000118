import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({ component: Component, ...rest }) => {
  const authentication = localStorage.getItem("id");
  const isAdmin = localStorage.getItem("isAdmin");
  return (<
    Route {...rest}
    render={
      props => {
        if (!authentication) {
          return <Component {...rest} {...props}
          />
        } else {
          return <Redirect to={ 
            {
              pathname: isAdmin ? '/admin/order' : '/user/dashboard',
              state: {
                from: props.location
              },
            }
          }
          />
        }
      }
    }
  />
  )
}

export default PublicRoute;
import axios from "axios";
import { Row, Col, Form, Button, Alert, Image } from "react-bootstrap";
import useShipping from "./useShipping";
import validateShipping from "./validateShippingInfo";
import React, { useEffect, useContext } from "react";
import { useState } from "react";
import CountrySelect from "react-bootstrap-country-select";
import { shippingCountry, CountryList } from "../../../../../common/country-list";
import UserContext from "../../../../../store/user-context";
import { API } from "../../../../../route/services";

const ShippingAddress = (props) => {
  const userId = localStorage.getItem("id");
  const userContext = useContext(UserContext);

  const [country, setCountryValue] = useState("");

  const [countryError, setCountryError] = useState("");
  const [alreadyShipped, setAlreadyShipped] = useState(false);
  const [isValidate] = useState(props.isValidate !== false);
  const [isDigitalUser] = useState(props.digitalUser !== true);

  if (shippingCountry.length <= 0){
    CountryList()
  }

  function handleCountryChange(e) {
    if (e && e.id) {
      setCountryValue(e.name);
    }
  }

  const {
    handleShippingChange,
    handleShippingSubmit,
    shipvalue,
    shippingerrors,
  } = useShipping(submitShippingInfo, validateShipping);

  const [value, setValue] = useState(shipvalue);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
    }, 9000);
  });

  useEffect(() => {
    axios
      .get(`${API.getUserShippingInfo}/${userId}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        mode: "no-cors",
      })
      .then((response) => {
        setValue(
          (shipvalue.address = response.data.shipping_address),
          (shipvalue.address2 = response.data.shipping_address2),
          (shipvalue.city = response.data.shipping_city),
          (shipvalue.state = response.data.shipping_state),
          (shipvalue.zipcode = response.data.shipping_zipcode),
          (shipvalue.phonenumber = response.data.phone_number)
        );
        setCountryValue(response.data.shipping_country);
        setAlreadyShipped(response.data.shipped_bool);
      })
      .catch((error) => {
        console.log("get shipping address error:", error.response);
      });
  }, []);

  async function submitShippingInfo() {
    if (props.isUser === true) {
      await axios
          .put(
              `${API.getUserShippingInfo}/${userId}/`,
              {
                shipping_address: shipvalue.address,
                shipping_address2: shipvalue.address2,
                shipping_city: shipvalue.city,
                shipping_state: shipvalue.state,
                shipping_country: country,
                shipping_zipcode: shipvalue.zipcode,
                phone_number: shipvalue.phonenumber,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
              }
          )
          .then((response) => {
            setAlert(true);
            userContext.completeProfileHandler();

            onUpdate();
            //function calling : banner change on dashboard
            if (props.apiCall) {
              props.apiCall();
            }
            if (props.isShipped) {
              props.shippedModel();
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log("post shipping address error:", error.response);
          });
    } else {
      if (!country) {
        return setCountryError("country is required");
      }
      if(!isDigitalUser && country === "Other") {
        setCountryError("");
      }
      if (isDigitalUser && country === "Other") {
        return props.countryModel(true, "shipping country");
      }
      setCountryError("");
      if (!isValidate || !Object.keys(validateShipping(shipvalue)).length || !isDigitalUser) {
        if (!alreadyShipped) {
          //function calling : admin side change address
          if (props.reload) {
            props.reload();
          }
          await axios
              .put(
                  `${API.getUserShippingInfo}/${userId}/`,
                  {
                    shipping_address: shipvalue.address,
                    shipping_address2: shipvalue.address2,
                    shipping_city: shipvalue.city,
                    shipping_state: shipvalue.state,
                    shipping_country: country,
                    shipping_zipcode: shipvalue.zipcode,
                    phone_number: shipvalue.phonenumber,
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    mode: "no-cors",
                  }
              )
              .then((response) => {
                setAlert(true);
                if (!isDigitalUser) {
                  if (props.handleAddress) {
                    props.handleAddress();
                  }
                } else {
                  userContext.completeProfileHandler();

                  onUpdate();
                  //function calling : banner change on dashboard
                  if (props.apiCall) {
                    props.apiCall();
                  }
                  if (props.isShipped) {
                    props.shippedModel();
                  }
                }
              })
              .catch((error) => {
                if (error.response && error.response.status === 401) {
                  localStorage.clear();
                  window.location.href = "/login";
                }
                console.log("post shipping address error:", error.response);
              });
        } else {
          //function calling : popup open on dashboard
          if (props.modelShow) {
            props.modelShow();
          }
        }
      }
    }
  }

  const onUpdate = () => {
    props.parentCallback(true);
  };

  return (
    <>
      <section
        className="personal-info-section"
        id="ShippingAddress"
        style={{ display: "block" }}
      >
        <h3>Shipping Address</h3>
        <Form
          onSubmit={(e) => handleShippingSubmit(e)}
          className="form"
          noValidate
          method="post"
        >
          <Row>
            <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
              <Form.Group className="form-group">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  name="address"
                  value={shipvalue.address}
                  onChange={handleShippingChange}
                ></Form.Control>
                {(isValidate && isDigitalUser && props.isUser !== true && shippingerrors.address) && (
                  <p className="text-danger">{shippingerrors.address}</p>
                )}
              </Form.Group>
            </Col>
            <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
              <Form.Group className="form-group">
                <Form.Label>Address 2</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  name="address2"
                  value={shipvalue.address2}
                  onChange={handleShippingChange}
                ></Form.Control>
                {(isValidate && isDigitalUser && props.isUser !== true && shippingerrors.address2) && (
                  <p className="text-danger">{shippingerrors.address2}</p>
                )}
              </Form.Group>
            </Col>
            <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
              <Form.Group className="form-group">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  name="city"
                  value={shipvalue.city}
                  onChange={handleShippingChange}
                ></Form.Control>
                {(isValidate && isDigitalUser && props.isUser !== true && shippingerrors.city) && (
                  <p className="text-danger">{shippingerrors.city}</p>
                )}
              </Form.Group>
            </Col>
            <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
              <Form.Group className="form-group">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  name="state"
                  value={shipvalue.state}
                  onChange={handleShippingChange}
                ></Form.Control>
                {(isValidate && isDigitalUser && props.isUser !== true && shippingerrors.state) && (
                  <p className="text-danger">{shippingerrors.state}</p>
                )}
              </Form.Group>
            </Col>
             {shippingCountry.length > 0 && (
            <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
              <Form.Group className="form-group">
                <Form.Label>Country</Form.Label>
                {/* {countryList.length > 0 && ( */}
                <CountrySelect
                  name="country"
                  placeholder={country}
                  value={country}
                  onChange={handleCountryChange}
                  countries={shippingCountry}
                  className={country === "Other" ? "country-warn" : ""}
                />
                {/* )} */}
                {(isValidate && props.isUser !== true && countryError) && <p className="text-danger">{countryError}</p>}
              </Form.Group>
            </Col>
             )}
            <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
              <Form.Group className="form-group">
                <Form.Label>ZIP Code</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  name="zipcode"
                  value={shipvalue.zipcode}
                  onChange={handleShippingChange}
                ></Form.Control>
                {(isValidate && isDigitalUser && props.isUser !== true && shippingerrors.zipcode) && (
                  <p className="text-danger">{shippingerrors.zipcode}</p>
                )}
              </Form.Group>
            </Col>
            <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
              <Form.Group className="form-group">
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  type="string"
                  className="form-control"
                  name="phonenumber"
                  value={shipvalue.phonenumber}
                  onChange={handleShippingChange}
                ></Form.Control>
                {(isValidate && isDigitalUser && props.isUser !== true && shippingerrors.phonenumber) && (
                  <p className="text-danger">{shippingerrors.phonenumber}</p>
                )}
              </Form.Group>
            </Col>
          </Row>
          {alert && (
            <div className="alert-message">
              <Alert className="alert-success">
                <Image
                  src={
                    require("./../../../../../assets/images/success.svg")
                      .default
                  }
                  alt="Document"
                  className="me-2"
                />
                Shipping Information updated successfully
              </Alert>
            </div>
          )}
          <div className="d-flex justify-content-end">
            <Button
              className="btn btn-success mt-3 mb-1"
              type="submit"
              onClick={submitShippingInfo}
            >
              {" "}
              Save{" "}
            </Button>
          </div>
        </Form>
      </section>
    </>
  );
};

export default ShippingAddress;

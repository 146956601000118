import { useState } from "react";

const useShipping = (callback, validateShipping) => {
  const [shipvalue, setValues] = useState({
    address: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    phonenumber: "",

  });
  const [shippingerrors, setErrors] = useState({});

  const handleShippingChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...shipvalue,
      [name]: value,
    });
  };

  const handleShippingSubmit = (e) => {
    e.preventDefault();

    setErrors(validateShipping(shipvalue));
  };

  return { handleShippingChange, handleShippingSubmit, shipvalue, shippingerrors };
};

export default useShipping;

import React, { useState } from "react";
import { Alert, Form, Image } from "react-bootstrap";
import useForm from "./useForm";
import validate from "./validateInfo";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { API } from "../../../route/services";
import Loader from "../loader/Loader";

const Resetpassword = ({ submitForm }) => {
  const history = useHistory();
  const [error, setError] = useState("");
  const [alert, setAlet] = useState(false);
  const [load, setLoad] = useState(false);

  const { handleChange, handleSubmit, values, errors } = useForm(
    submitForm,
    validate
  );

  async function submitHandler() {
    if (!Object.keys(validate(values)).length) {
      setLoad(true);

      const url = new URL(window.location.href);
      const data = url.search.split("?")[1];
      const splitStringUrl = data.split("&");

      const uid = splitStringUrl[2].split("=")[1];
      const token = splitStringUrl[3].split("=")[1];

      await axios
        .post(
          `${API.passwordResetThrewEmail}`,
          {
            password: values.password,
            token: token,
            uidb64: uid,
          },
          { timeout: 5000 },
          {
            headers: {
              "access-control-allow-origin": "*",
              "Content-Type": "application/json",
            },
            mode: "no-cors",
          }
        )
        .then((response) => {
          setLoad(false);
          setAlet(true);
          setTimeout(() => {
            history.push("/login");
          }, 2000);
        })
        .catch((error) => {
          setLoad(false);
          console.log("reset password error:", error.response);
          setError("token is unvalid or expired");
        });
    }
  }

  return (
    <div className="form-wrapper">
      <div className="login-form">
        <Image
          src={require("./../../../assets/images/logo.png").default}
          alt="Eita-app"
          className="w-auto p-4"
        />
        <div className="login-page">
          <Form onSubmit={handleSubmit} className="form" noValidate>
            <h3>Reset password</h3>
            <p>Please enter your new password</p>
            <Form.Group className="form-group">
              <Form.Control
                type="password"
                name="password"
                placeholder="Enter your password"
                required="true"
                value={values.password}
                onChange={handleChange}
              ></Form.Control>
              {errors.password && <p>{errors.password}</p>}
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Control
                type="password"
                name="password2"
                placeholder="Enter your Re type password"
                required="true"
                value={values.password2}
                onChange={handleChange}
              ></Form.Control>
              {error && <p>{error}</p>}
              {errors.password2 && <p>{errors.password2}</p>}
            </Form.Group>
            {alert && (
              <div className="alert-message">
                <Alert className="alert-success">
                  <Image
                    src={
                      require("./../../../assets/images/success.svg").default
                    }
                    alt="Document"
                    className="me-2"
                  />
                  Password updated successfully
                </Alert>
              </div>
            )}
            {load && <Loader />}
            {!load && (
              <button
                className="btn btn-primary mt-3 mb-1"
                type="submit"
                onClick={submitHandler}
              >
                {" "}
                Reset password{" "}
              </button>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Resetpassword;

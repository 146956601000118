import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
export default class CropModal extends Component {
  state = {
    crop: {
      unit: "%",
      width: 30,
      aspect: 1 / 1
    },
    base64: null,
    croppedImageUrl: null
  };

  handleClickOpen = () => {
    this.setState({ openModal: true });
  };

  handleClose = () => {
    this.setState({ openModal: false });
  };
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  onCropComplete = async crop => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "admin-profile-" + new Date().getTime() + ".jpg"
      );
      console.log(croppedImageUrl);
      this.setState({ croppedImageUrl: URL.createObjectURL(croppedImageUrl) });
      // this.props.setCroppedImageUrl(URL.createObjectURL(croppedImageUrl));
    }
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    this.setState({
      base64: canvas.toDataURL('image/jpeg')
    })

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          blob.name = fileName;
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  }
  render() {
    const { openCropModal, setCropModal, setCroppedImageUrl, src, base64 } = this.props;
    return (
      <div>
        <Dialog
          open={openCropModal}
          onClose={() => setCropModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Crop Image"}</DialogTitle>
          <DialogContent>
            <ReactCrop
              src={src}
              crop={this.state.crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setCropModal(false);
                // setCroppedImageUrl(null);
              }}
              color="primary"
            >
              cancel
            </Button>
            <Button
              onClick={() => {
                setCropModal(false);
                setCroppedImageUrl(this.state.croppedImageUrl)
                base64(this.state.base64);
              }}
              color="primary"
              autoFocus
            >
              done
            </Button>
          </DialogActions>
        </Dialog>
      </div >
    );
  }
}

import { useState } from "react";

const useOrderTrack = (callback, validateShipping) => {
  const [ordervalue, setValues] = useState({
    address: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    phonenumber: "",
    description: "",
  });
  const [ordererrors, setErrors] = useState({});

  const changeOrderTrack = (e) => {
    const { name, value } = e.target;
    setValues({
      ...ordervalue,
      [name]: value,
    });
  };

  const handleTrackSubmit = (e) => {
    e.preventDefault();

    setErrors(validateShipping(ordervalue));
  };

  return {
    changeOrderTrack,
    handleTrackSubmit,
    ordervalue,
    ordererrors,
  };
};

export default useOrderTrack;

import { Link, useHistory } from "react-router-dom";
import { Image, Button } from "react-bootstrap";

function IFShippedtoFirstClassNotArrived(props) {
  const history = useHistory();
  function orderNotArrived() {
    history.push({pathname:"/user/reshipping", maxDate:props.maxDate});
  }
  return (
    <>
      <section
        className="delivery-section sc-mt"
        id="ShippedUSPS"
        // style={{ display: "none" }}
      >
        {/*<h3>IF Shipped to US and USPS</h3>*/}
        <div className="Shipping-document">
          <p className="text-white mb-0">Your order should arrive by now</p>
          <h3 className="text-white">
            If your order has not arrived --please click below{" "}
            {/* <span className="text-warning">July 23, 2021</span> */}
          </h3>
          <Image
            className="deliver-document w-25"
            src={
              require("./../../../../../assets/images/internationally.png")
                .default
            }
            alt="USPS"
          />
          {/* <Link to="/ordertrack"> */}
          <Button
            type="button"
            className="btn btn-warning"
            onClick={orderNotArrived}
          >
            My order has not arrived
          </Button>
          {/* </Link> */}
        </div>
      </section>
    </>
  );
}

export default IFShippedtoFirstClassNotArrived;

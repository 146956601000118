export default function validateInfo(values) {
  let errors = {};
  var newRegPasswsord = new RegExp(/^[a-zA-Z]$/)


  if (!values.username) {
    errors.username = 'User name required';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 6) {
    errors.password = 'Password needs to be minimum 6 characters';
  }


  return errors;
}

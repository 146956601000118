import React, { useContext, useEffect, useState } from "react";
import {
  CardElement,
  StripeProvider,
  Elements,
  injectStripe,
} from "react-stripe-elements";
import { Row, Col, Container, Form, Image } from "react-bootstrap";
import axios from "axios";
import $ from "jquery";
import UserContext from "../../../store/user-context";
import { SavedCardDetail } from "./Carddetail";
import Loader from "../loader/Loader";
import { Redirect } from "react-router-dom";
import { API } from "../../../route/services";
import SweetAlert from "react-bootstrap-sweetalert";

let payProcessFee = 0;

class _CardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripeError: "",
      isLoad: false,
      isRedirect: false,
      isTransactionFailed: false,
      isTransactionSuccess: false,
    };
  }

  static contextType = UserContext;

  handleUserInput(e) {
    this.setState({
      stripeError: "",
    });
  }

  renderRedirect = () => {
    if (this.state.isRedirect) {
      return <Redirect to="/user/dashboard" />;
    }
  };

  render() {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();

          this.setState({ isLoad: true });
          const paymentService = this.context.payment;
          const amount = this.context.amount;

          this.props.stripe.createToken().then(async (payload) => {
            const userId = localStorage.getItem("id");
            const processFee = payProcessFee !== 0 ? payProcessFee : localStorage.getItem("process");

            this.setState({isTransactionFailed: false, isTransactionSuccess: false});
            if (payload.token) {
              this.setState({ stripeError: "" });
              await axios
                .post(
                  `${API.usersShippingPayment}`,
                  {
                    amount: amount,
                    id: userId,
                    token: payload.token.id,
                    description: "",
                    shipping_option: paymentService.serviceName,
                    tracking: paymentService.price == 0 ? 0 : 1,
                    user_ip_location: payload.token.client_ip,
                    flag: 1,
                    plan_type: "",
                    validity_years: "",
                    process_fee: parseFloat(processFee),
                    // plan_price: "",
                    ship_flag : 0
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    mode: "no-cors",
                  }
                )
                .then((response) => {

                  this.setState({isTransactionSuccess: true});
                  setTimeout(() => {
                    this.setState({ isLoad: false, isRedirect: true });
                  }, 2000);
                  // window.location.replace("/user/dashboard");
                })
                .catch((error) => {
                  if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    window.location.href="/login";
                  }
                  this.setState({ isTransactionFailed: true, isLoad: false });
                  console.log("payment post service error:", error);
                });
            } else {
              this.setState({
                stripeError: payload.error.message,
                isLoad: false,
              });
            }
          });
        }}
      >
        <CardElement />
        {this.state.stripeError && (
          <p className="text-left payment-error text-danger">
            {this.state.stripeError}
          </p>
        )}
        {this.state.isLoad && (
          <div className="payment-loader">
            <Loader />
          </div>
        )}
        {this.renderRedirect()}
        {this.state.isTransactionSuccess && (
            <SweetAlert success title="Payment Success" onConfirm={() => this.setState({isTransactionSuccess:false, isLoad: false, isRedirect: true})}>
              Your payment has been received
            </SweetAlert>
        )}

        {this.state.isTransactionFailed && (
            <SweetAlert danger title="Transaction failed" confirmBtnText="Try again" onConfirm={() => this.setState({isTransactionFailed: false})}>
              Transaction Failed , Please Try again
            </SweetAlert>
        )}


        {!this.state.isLoad && (
          <button
            className="btn btn-success shipping-button"
            type="submit"
            // onClick={submitHandler}
          >
            Pay
          </button>
        )}
      </form>
    );
  }
}
const CardForm = injectStripe(_CardForm);

class Checkout extends React.Component {
  render() {
    return (
      <div>
        <Elements className="form-control">
          <CardForm />
        </Elements>
      </div>
    );
  }
}

function AddShipping() {
  const userId = localStorage.getItem("id");
  const stripekey = process.env.REACT_API_STRIPE_KEY;
  const [checked, setChecked] = useState(0);
  const [data, setData] = useState([]);
  const [upgradePrice, setUpgradePrice] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [proccessFee, setprocessFee] = useState(0);
  const [cardDetail, setCardDetail] = useState({ card_flag: 0 });
  const [isLoad, setLoad] = useState(true);
  const [payData, setPayData] = useState({});
  const userContext = useContext(UserContext);

  function apicalling() {
    axios
      .get(`${API.usersPaymentShippingOption}/${userId}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        mode: "no-cors",
      })
      .then((response) => {
        setData(response.data.data);
        setUpgradePrice(response.data.selected_amount);
        setprocessFee(response.data.process_fee);
        payProcessFee = response.data.process_fee;
        localStorage.setItem("process", response.data.process_fee);
        const payData = response.data.data[0];
        setPayData(payData);
        setTotalAmount(
          response.data.data[0].price +
            response.data.process_fee -
            response.data.selected_amount
        );
        userContext.amountHandler(
          response.data.data[0].price +
            response.data.process_fee -
            response.data.selected_amount
        );
        userContext.paymentHandler(payData);
        setLoad(false);
      })
      .catch((error) => {
        console.log("get shipping option error:", error.response);
      });

    axios
      .post(
        `${API.usersPaymentCardDeails}/${userId}/`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          mode: "no-cors",
        }
      )
      .then((response) => {
        setCardDetail(response.data[0]);
        userContext.cardHandler(response.data[0]);
      })
      .catch((error) => {
        console.log("post user card details error:", error.response);
      });
  }

  useEffect(() => {
    apicalling();
  }, []);

  function radioChangeHandler(id) {
    setChecked(id);
    const payData = data[id];
    setPayData(payData);
    setTotalAmount(payData.price + proccessFee - upgradePrice);

    userContext.paymentHandler(payData);
    userContext.amountHandler(payData.price + proccessFee - upgradePrice);
  }

  function resetCardDetail() {
    setCardDetail({ card_flag: 0 });
  }

  const serviceOfCountry = data.map((value, index) => {
    let image;
    if (value.image === 1) {
      image = require("./../../../assets/images/usps.svg").default;
    } else if (value.image === 2) {
      image = require("./../../../assets/images/fedex_logo.png").default;
    } else if (value.image === 3) {
      image = require("./../../../assets/images/internationally.png").default;
    } else if (value.image === 4) {
      image = require("./../../../assets/images/aus-post.svg").default;
    }
    // For Royal Mail
    // else if (value.image === 5) {
    //   image = require("./../../../assets/images/royal-mail-logo-3.png").default;
    // }
    else {
      image = require("./../../../assets/images/fedex_logo.png").default;
    }
    return (
      <>
        <div className="usps-tracking">
          <div>
            <span class="custom_radio">
              <input
                type="radio"
                id={value.serviceName}
                name="Tracking"
                checked={index === checked ? true : false}
                onChange={() => radioChangeHandler(index)}
              />
              <label for={value.serviceName}></label>
            </span>
            <Image src={image} alt="usps" />
            <label className="option-text">{value.serviceName === "DHL" ? "FedEx" : value.serviceName}</label>
          </div>
          <span className="text-end">
            {value.price === 0 ? "FREE" : "$" + value.price}
          </span>
        </div>
      </>
    );
  });

  return (
    <>
      <Container>
        <section className="processing-section">
          <div className="processing-title">
            <h3>Reship Documents</h3>
            
            <p>Get the corrected documents delivered to you.</p>
            <div></div>
          </div>
          <Form>
            <div className="payment-stripe">
              <Row>
                <Col className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="processing-section-title">
                    <p>Reshipment</p>
                    <ul>
                      <li>
                        Printed Booklet and ID Card &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        <span className="courier-price text-end">
                          ${proccessFee.toFixed(2)}
                        </span>
                      </li>
                    </ul>
                  </div>
                  {isLoad && (
                    <div className="payment-loader">
                      <Loader />
                    </div>
                  )}
                  {!isLoad && (
                    <div className="processing-section-title">
                      <p>Tracking</p>
                      {serviceOfCountry}
                    </div>
                  )}
                  {!isLoad && (
                    <div className="payment-section">
                      <p>Payment</p>

                      <div className="stripe-payment">
                        <div className="AppWrapper text-center">
                          {cardDetail.card_flag == 1 && (
                            <SavedCardDetail
                              resetCardDetail={resetCardDetail}
                              processFee={proccessFee}
                            />
                          )}
                          {cardDetail.card_flag == 0 && (
                            <StripeProvider
                              apiKey={
                                process.env.REACT_APP_REACT_API_STRIPE_KEY
                              }
                            >
                              <Checkout />
                            </StripeProvider>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
                <Col className="col-12 col-sm-12 col-md-12 col-lg-6">
                  {!isLoad && (
                    <div className="summary-section">
                      <p>SUMMARY</p>
                      <ul className="processing border-bottom-0">
                        <li className="p-0">Tracking </li>
                        <li className="p-0">${payData.price !== undefined ? payData.price.toFixed(2) : ''}</li>
                      </ul>
                      <ul className="processing">
                        <li className="p-0">Printed Booklet and ID Card</li>
                        <li className="p-0">+${proccessFee.toFixed(2)}</li>
                      </ul>
                      <ul className="ps-4 pe-4">
                        <li className="p-0">Total</li>
                        <li className="p-0">${totalAmount.toFixed(2)}</li>
                      </ul>
                      <h6>
                        <Image
                          src={
                            require("./../../../assets/images/payment.svg")
                              .default
                          }
                          alt="Payment"
                        />{" "}
                        Safe & Secure Payment
                      </h6>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Form>
        </section>
      </Container>
    </>
  );
}

export default AddShipping;

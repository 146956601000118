import React from "react";
import { Tab, Tabs, Image } from "react-bootstrap";
import $, { data } from "jquery";
import axios from "axios";
import UserContext from "../../../store/user-context";
import { API } from "../../../route/services";

class PlanRenewal extends React.Component {
  static contextType = UserContext;
  state = {
    title1: "PRINT + DIGITAL BUNDLE",
    title2: "DIGITAL ONLY",
    data1: [
      {
        validity: 1,
        standard_price: 45,
        discounted_price: 36,
        discount_rate: 20,
      },
      {
        validity: 2,
        standard_price: 55,
        discounted_price: 0,
        discount_rate: 0,
      },
      {
        validity: 3,
        standard_price: 65,
        discounted_price: 44,
        discount_rate: 32.31,
      },
    ],
    data2: [
      {
        validity: 1,
        standard_price: 45,
        discounted_price: 36,
        discount_rate: 20,
      },
      {
        validity: 2,
        standard_price: 55,
        discounted_price: 0,
        discount_rate: 0,
      },
      {
        validity: 3,
        standard_price: 65,
        discounted_price: 44,
        discount_rate: 32.31,
      },
    ],
  };

  componentDidMount() {
    var title = this.state.title1;
    var title2 = this.state.title2;

    var printValidity1 = this.state.data1[0].validity;
    var printValidity2 = this.state.data1[1].validity;
    var printValidity3 = this.state.data1[2].validity;

    var digitalValidity1 = this.state.data2[0].validity;
    var digitalValidity2 = this.state.data2[1].validity;
    var digitalValidity3 = this.state.data2[2].validity;

    axios
      .get(
        `${API.usersPlanRenewalDetails}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          mode: "no-cors",
        }
      )
      .then((response) => {
        let data1 = [];
        let data2 = [];
        let printInd = "";
        let digitalInd = "";
        if(response.data[0].title === "PRINT + DIGITAL BUNDLE") {
          printInd = 0;
          digitalInd = 1;
        } else {
          printInd = 1;
          digitalInd = 0;
        }
        response.data[printInd].data.map((row) => {
          if(row.validity === 1) {
            data1[0] = row;
          } else if(row.validity === 2) {
            data1[1] = row;
          } else if(row.validity === 3) {
            data1[2] = row;
          }
        });
        response.data[digitalInd].data.map((row) => {
          if(row.validity === 1) {
            data2[0] = row;
          } else if(row.validity === 2) {
            data2[1] = row;
          } else if(row.validity === 3) {
            data2[2] = row;
          }
        });
        this.setState({
          title1: response.data[printInd].title,
          data1: data1,
          title2: response.data[digitalInd].title,
          data2: data2,
        });
      })
      .catch((error) => {
        console.log("get plan types error:", error);
      });

    //print renew documents
    $("#PriceSectionOne").click(function() {
      var price = $("#PriceSectionOne")
        .children("#price")
        .text()
        .replace("$", "");
      localStorage.setItem("price", parseFloat(price));
      localStorage.setItem("validity", printValidity1);

      localStorage.setItem("option", title);
      $(this).toggleClass("active-price");
      $(".price-section.best-value-price").toggleClass("add-class");
      $("#PriceSectionTwo").removeClass("active-price");
      $("#PriceSectionThree").removeClass("active-price");
      window.location.replace("/user/planrenewal/print");
    });

    $("#PriceSectionTwo").click(function() {
      var price = $("#PriceSectionTwo")
        .children("#price")
        .text()
        .replace("$", "");
      localStorage.setItem("price", parseFloat(price));
      localStorage.setItem("validity", printValidity3);

      localStorage.setItem("option", title);
      $(this).toggleClass("active-price");
      $(".price-section.best-value-price").toggleClass("add-class");
      $("#PriceSectionOne").removeClass("active-price");
      $("#PriceSectionThree").removeClass("active-price");
      window.location.replace("/user/planrenewal/print");
    });

    $("#PriceSectionThree").click(function() {
      var price = $("#PriceSectionThree")
        .children("#price")
        .text()
        .replace("$", "");
      localStorage.setItem("price", parseFloat(price));
      localStorage.setItem("validity", printValidity2);

      localStorage.setItem("option", title);
      $(this).toggleClass("active-price");
      $(".price-section.best-value-price").toggleClass("add-class");
      $("#PriceSectionTwo").removeClass("active-price");
      $("#PriceSectionOne").removeClass("active-price");
      window.location.replace("/user/planrenewal/print");
    });

    //Digital renew documents
    $("#priceDigitalOne").click(function() {
      var price = $("#priceDigitalOne")
        .children("#price")
        .text()
        .replace("$", "");
      localStorage.setItem("price", parseFloat(price));
      localStorage.setItem("validity", digitalValidity1);
      localStorage.setItem("option", title2);
      $(this).toggleClass("active-price");
      $(".price-section.best-value-price").toggleClass("add-class");
      $("#PriceSectionTwo").removeClass("active-price");
      $("#PriceSectionThree").removeClass("active-price");
      window.location.replace("/user/planrenewal/digital");
    });

    $("#priceDigitalTwo").click(function() {
      var price = $("#priceDigitalTwo")
        .children("#price")
        .text()
        .replace("$", "");
      localStorage.setItem("price", parseFloat(price));
      localStorage.setItem("validity", digitalValidity3);

      localStorage.setItem("option", title2);
      $(this).toggleClass("active-price");
      $(".price-section.best-value-price").toggleClass("add-class");
      $("#PriceSectionOne").removeClass("active-price");
      $("#PriceSectionThree").removeClass("active-price");
      window.location.replace("/user/planrenewal/digital");
    });

    $("#priceDigitalThree").click(function() {
      var price = $("#priceDigitalThree")
        .children("#price")
        .text()
        .replace("$", "");
      localStorage.setItem("price", parseFloat(price));
      localStorage.setItem("validity", digitalValidity2);

      localStorage.setItem("option", title2);
      $(this).toggleClass("active-price");
      $(".price-section.best-value-price").toggleClass("add-class");
      $("#PriceSectionTwo").removeClass("active-price");
      $("#PriceSectionOne").removeClass("active-price");
      window.location.replace("/user/planrenewal/digital");
    });
  }

  render() {
    return (
      <>
        <section className="paln-renewal">
          <div className="renewal-wrapper">
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3 justify-content-center border-bottom-0"
            >
              <Tab
                eventKey="home"
                title={this.state.title1}
                className="print-digital"
              >
                <div className="renewal-image text-center">
                  <Image
                    className="w-50"
                    src={
                      require("./../../../../src/assets/images/document.png")
                        .default
                    }
                    alt="Document"
                  />
                </div>
                <div className="digital-bundle-price">
                  <div
                    className="price-section"
                    id="PriceSectionOne"
                    onClick={this.handler}
                  >
                    <h3 id="validity">
                      Valid for {this.state.data1[0].validity} Years
                    </h3>
                    {this.state.data1[0].discounted_price !== 0 && (
                      <h5>
                        <del>${this.state.data1[0].standard_price}</del>
                      </h5>
                    )}
                    <h1 id="price">
                      $
                      {this.state.data1[0].discounted_price !== 0
                        ? this.state.data1[0].discounted_price
                        : this.state.data1[0].standard_price}
                    </h1>
                    {this.state.data1[0].discounted_price !== 0 && (
                      <p>{this.state.data1[0].discount_rate}% OFF</p>
                    )}
                    <a href="#" className="btn btn-primary">
                      Select
                    </a>
                  </div>
                  <div
                    className="price-section best-value-price"
                    id="PriceSectionTwo"
                  >
                    <p className="best-price">
                      <Image
                        src={
                          require("./../../../../src/assets/images/star-fill.svg")
                            .default
                        }
                        alt="Document"
                      />{" "}
                      BEST VALUE
                    </p>
                    <h3 id="validity">
                      Valid for {this.state.data1[2].validity} Years
                    </h3>
                    {this.state.data1[2].discounted_price !== 0 && (
                      <h5>
                        <del>${this.state.data1[2].standard_price}</del>
                      </h5>
                    )}
                    <h1 id="price">
                      $
                      {this.state.data1[2].discounted_price !== 0
                        ? this.state.data1[2].discounted_price
                        : this.state.data1[2].standard_price}
                    </h1>
                    {this.state.data1[2].discounted_price !== 0 && (
                      <p>{this.state.data1[2].discount_rate}% OFF</p>
                    )}
                    <a hre="#" className="btn btn-primary">
                      Select
                    </a>
                  </div>
                  <div className="price-section" id="PriceSectionThree">
                    <h3 id="validity">
                      Valid for {this.state.data1[1].validity} Years
                    </h3>
                    {this.state.data1[1].discounted_price !== 0 && (
                      <h5>
                        <del>${this.state.data1[1].standard_price}</del>
                      </h5>
                    )}
                    <h1 id="price">
                      $
                      {this.state.data1[1].discounted_price !== 0
                        ? this.state.data1[1].discounted_price
                        : this.state.data1[1].standard_price}
                    </h1>
                    {this.state.data1[1].discounted_price !== 0 && (
                      <p>{this.state.data1[1].discount_rate}% OFF</p>
                    )}
                    <a hre="#" className="btn btn-primary">
                      Select
                    </a>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="profile"
                title={this.state.title2}
                className="digital"
              >
                <div className="renewal-image text-center">
                  <Image
                    className="w-50"
                    src={
                      require("./../../../../src/assets/images/document.png")
                        .default
                    }
                    alt="Document"
                  />
                </div>
                <div className="digital-bundle-price">
                  <div className="price-section" id="priceDigitalOne">
                    <h3 id="validity">
                      Valid for {this.state.data2[0].validity} Years
                    </h3>
                    {this.state.data2[0].discounted_price !== 0 && (
                      <h5>
                        <del>${this.state.data2[0].standard_price}</del>
                      </h5>
                    )}
                    <h1 id="price">
                      $
                      {this.state.data2[0].discounted_price !== 0
                        ? this.state.data2[0].discounted_price
                        : this.state.data2[0].standard_price}
                    </h1>
                    {this.state.data2[0].discounted_price !== 0 && (
                      <p>{this.state.data2[0].discount_rate}% OFF</p>
                    )}
                    <a hre="#" className="btn btn-primary">
                      Select
                    </a>
                  </div>
                  <div className="price-section bg-white" id="priceDigitalTwo">
                    <p className="best-price">
                      <Image
                        src={
                          require("./../../../../src/assets/images/star-fill.svg")
                            .default
                        }
                        alt="Document"
                      />{" "}
                      BEST VALUE
                    </p>
                    <h3 id="validity">
                      Valid for {this.state.data2[2].validity} Years
                    </h3>
                    {this.state.data2[2].discounted_price !== 0 && (
                      <h5>
                        <del>${this.state.data2[2].standard_price}</del>
                      </h5>
                    )}
                    <h1 id="price">
                      $
                      {this.state.data2[2].discounted_price !== 0
                        ? this.state.data2[2].discounted_price
                        : this.state.data2[2].standard_price}
                    </h1>
                    {this.state.data2[2].discounted_price !== 0 && (
                      <p>{this.state.data2[2].discount_rate}% OFF</p>
                    )}
                    <a hre="#" className="btn btn-primary">
                      Select
                    </a>
                  </div>
                  <div className="price-section" id="priceDigitalThree">
                    <h3 id="validity">
                      Valid for {this.state.data2[1].validity} Years
                    </h3>
                    {this.state.data2[1].discounted_price !== 0 && (
                      <h5>
                        <del>${this.state.data2[1].standard_price}</del>
                      </h5>
                    )}
                    <h1 id="price">
                      $
                      {this.state.data2[1].discounted_price !== 0
                        ? this.state.data2[1].discounted_price
                        : this.state.data2[1].standard_price}
                    </h1>
                    {this.state.data2[1].discounted_price !== 0 && (
                      <p>{this.state.data2[1].discount_rate}% OFF</p>
                    )}
                    <a hre="#" className="btn btn-primary">
                      Select
                    </a>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </section>
      </>
    );
  }
}

export default PlanRenewal;

import { Link, useHistory } from "react-router-dom";
import { Button, Image } from "react-bootstrap";

function DocumentsExpire(props) {
  const history = useHistory();
  async function submitHandler(e) {
    history.push({ pathname: "/user/planrenewal" });
  }
  return (
    <>
      <section
        className="ship-document-section sc-mt"
        // style={{ display: "none" }}
      >
        {/*<h3>After documents expire.</h3>*/}
        <div className="Shipping-document">
          <p className="text-white mb-0">Your documents have expire.</p>
          <h3 className="text-white">We offer renewals at a {parseFloat(props.shippingDiscount)}% discount.</h3>
          <div className="d-block d-md-block d-lg-flex align-items-center justify-content-between">
            <Image
              className="w-50 deliver-document"
              src={
                require("./../../../../../assets/images/document.png").default
              }
              alt="Document"
            />
            <ul className="list-unstyled text-start">
              <li className="text-white pt-2 pb-2">
                <Image
                  src={
                    require("./../../../../../assets/images/done.svg").default
                  }
                  alt="Done"
                />{" "}
                Shipping Worldwide
              </li>
              <li className="text-white pt-2 pb-2">
                <Image
                  src={
                    require("./../../../../../assets/images/done.svg").default
                  }
                  alt="Done"
                />{" "}
                Internationally Recognized IDL Booklet
              </li>
              <li className="text-white pt-2 pb-2">
                <Image
                  src={
                    require("./../../../../../assets/images/done.svg").default
                  }
                  alt="Done"
                />{" "}
                Identification Card
              </li>
              <li className="text-white pt-2 pb-2">
                <Image
                  src={
                    require("./../../../../../assets/images/done.svg").default
                  }
                  alt="Done"
                />{" "}
                Free Replacements
              </li>
            </ul>
          </div>

          {/* <Link to=""> */}
          <Button
            type="button"
            className="btn btn-warning"
            onClick={submitHandler}
          >
            Renew today
          </Button>
          {/* </Link> */}
        </div>
      </section>
    </>
  );
}

export default DocumentsExpire;

import React, { useContext, useEffect, useState } from "react";
import {
  CardElement,
  StripeProvider,
  Elements,
  injectStripe,
} from "react-stripe-elements";
import { Row, Col, Container, Form, Image, Alert } from "react-bootstrap";
import axios from "axios";
import $ from "jquery";
import UserContext from "../../../store/user-context";
import { SavedCardDetail } from "./Carddetail";
import Loader from "../loader/Loader";
import ShippingAddress from "../userdashboard/informationsection/shippingaddress/ShippingAddress";
import {Redirect, useHistory} from "react-router-dom";
import { API } from "../../../route/services";
import SweetAlert from "react-bootstrap-sweetalert";

let payProcessFee = 0;

class _CardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripeError: "",
      isLoad: false,
      isRedirect: false,
            isTransactionFailed: false,
            isTransactionSuccess: false,
    };
  }

  static contextType = UserContext;

  handleUserInput(e) {
        this.setState({stripeError: ""});
  }

  renderRedirect = () => {
    if (this.state.isRedirect) {
      return <Redirect to="/user/dashboard" />;
    }
  };

  render() {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();

          this.setState({ isLoad: true });
          const paymentService = this.context.payment;
          const amount = this.context.amount;

          this.props.stripe.createToken().then(async (payload) => {
            const userId = localStorage.getItem("id");
            const processFee = payProcessFee !== 0 ? payProcessFee : localStorage.getItem("process");
                    this.setState({isTransactionFailed: false, isTransactionSuccess: false});
            if (payload.token) {
              this.setState({ stripeError: "" });
              await axios
                .post(
                  `${API.usersShippingPayment}`,
                  {
                    amount: amount,
                    id: userId,
                    token: payload.token.id,
                    description: "your payment done succefully",
                    shipping_option: paymentService.serviceName,
                    tracking: paymentService.price == 0 ? 0 : 1,
                    user_ip_location: payload.token.client_ip,
                    flag: 1,
                    plan_type: "",
                    validity_years: "",
                    process_fee: parseFloat(processFee),
                    ship_flag : 1
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    mode: "no-cors",
                  }
                )
                .then((response) => {
                            this.setState({isTransactionSuccess: true});
                  setTimeout(() => {
                    this.setState({ isLoad: false, isRedirect: true });
                  }, 2000);
                  // window.location.replace("/user/dashboard");
                        }).catch((error) => {
                      if (error.response && error.response.status === 401) {
                          localStorage.clear();
                          window.location.href="/login";
                      }
                            this.setState({isTransactionFailed: true});
                  this.setState({ isLoad: false });
                  console.log("post payment service error:", error);
                });
            } else {
              this.setState({
                stripeError: payload.error.message,
                isLoad: false,
              });
            }
          });
        }}
      >
        <CardElement />
        {this.state.stripeError && (
          <p className="text-left payment-error text-danger">
            {this.state.stripeError}
          </p>
        )}
        {this.state.isLoad && (
          <div className="payment-loader">
            <Loader />
          </div>
        )}
        {this.renderRedirect()}
                {this.state.isTransactionSuccess && (
                    <SweetAlert success title="Payment Success" onConfirm={() => this.setState({isTransactionSuccess:false, isLoad: false, isRedirect: true})}>
              Your payment has been received
            </SweetAlert>
        )}

                {this.state.isTransactionFailed && (
                    <SweetAlert danger title="Transaction failed" confirmBtnText="Try again" onConfirm={() => this.setState({isTransactionFailed: false})}>
              Transaction Failed , Please Try again
            </SweetAlert>
        )}
        {!this.state.isLoad && (
                    <button className="btn btn-success shipping-button" type="submit">Pay</button>
        )}
      </form>
    );
  }
}
const CardForm = injectStripe(_CardForm);

class Checkout extends React.Component {
  render() {
    return (
      <div>
        <Elements className="form-control">
          <CardForm />
        </Elements>
      </div>
    );
  }
}

function ReShipping(props) {
  const userId = localStorage.getItem("id");
  const stripekey = process.env.REACT_API_STRIPE_KEY;
  const [checked, setChecked] = useState(0);
  const [data, setData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [proccessFee, setprocessFee] = useState(0);
  const [cardDetail, setCardDetail] = useState({ card_flag: 0 });
  const [isLoad, setLoad] = useState(true);
  const [payData, setPayData] = useState({});
  const [step, setStep] = useState(3);
  const userContext = useContext(UserContext);
    const [shippingData, setShippingData] = useState({
        address: "", address2: "", country: "", city: "", state: "", zipcode: "", phonenumber: ""
    });

    const history = useHistory();

  function apicalling() {
    axios
      .get(
        `${API.usersPaymentShippingOption}/${userId}/`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          mode: "no-cors",
        }
      )
      .then((response) => {
        setLoad(false);
        setData(response.data.data);
        setprocessFee(response.data.process_fee);
        payProcessFee = response.data.process_fee;
        localStorage.setItem("process", response.data.process_fee);
        const payData = response.data.data[0];
        setPayData(payData);
        setTotalAmount(
          response.data.data[0].price +
            response.data.process_fee
        );
        userContext.amountHandler(
          response.data.data[0].price +
            response.data.process_fee
        );
        userContext.paymentHandler(payData);
      })
      .catch((error) => {
          if (error.response && error.response.status === 401) {
              localStorage.clear();
              window.location.href="/login";
          }
        console.log("post shipping option error:", error.response);
      });

    axios
      .post(
        `${API.usersPaymentCardDeails}/${userId}/`,
        {},

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          mode: "no-cors",
        }
      )
      .then((response) => {
        setCardDetail(response.data[0]);
        userContext.cardHandler(response.data[0]);
      })
      .catch((error) => {
          if (error.response && error.response.status === 401) {
              localStorage.clear();
              window.location.href="/login";
          }
        console.log("post card details error:", error.response);
      });
  }

    useEffect(() => {
        apicalling();
        getShippingAddress();
    }, []);

    function getShippingAddress() {
        axios
            .get(`${API.getUserShippingInfo}/${userId}/`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            })
            .then((response) => {
                setShippingData(
                    {address: response.data.shipping_address,
                        address2: response.data.shipping_address2,
                        country: response.data.shipping_country,
                        city: response.data.shipping_city,
                        state: response.data.shipping_state,
                        zipcode: response.data.shipping_zipcode,
                        phonenumber: response.data.phone_number}
                );
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    window.location.href="/login";
                }
                console.log("get shipping address error:", error.response);
            });
    }

    function handleShippingModel(status) {
        axios.post(`${API.usersOrderQuerySumbit}`,
            {'address_status': status},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            }
        ).then((response) => {
            if (status === "invalid") {
                setStep(1);
                window.scrollTo(0,0);
            } else {
                history.push({pathname: "/user/dashboard", shpping: true});
            }
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href="/login";
            }
            console.log(error);
        });
    }

  function radioChangeHandler(id) {
    setChecked(id);
    const payData = data[id];
    setPayData(payData);
    setTotalAmount(payData.price + proccessFee);
    userContext.paymentHandler(payData);
    userContext.amountHandler(payData.price + proccessFee);
  }

  function resetCardDetail() {
    setCardDetail({ card_flag: 0 });
  }

  function reload() {
    setStep(2);
    // window.location.reload();
  }

  const serviceOfCountry = data.map((value, index) => {
    let image;
    if (value.image === 1) {
      image = require("./../../../assets/images/usps.svg").default;
    } else if (value.image === 2) {
      image = require("./../../../assets/images/fedex_logo.png").default;
    } else if (value.image === 3) {
      image = require("./../../../assets/images/internationally.png").default;
    } else if (value.image === 4) {
      image = require("./../../../assets/images/aus-post.svg").default;
    }
    // For Royal Mail
    // else if (value.image === 5) {
    //   image = require("./../../../assets/images/royal-mail-logo-3.png").default;
    // }
    else {
      image = require("./../../../assets/images/fedex_logo.png").default;
    }
    return (
      <>
        <div className="usps-tracking">
          <div>
            <span class="custom_radio">
              <input
                type="radio"
                id={value.serviceName}
                name="Tracking"
                checked={index === checked}
                onChange={() => radioChangeHandler(index)}
              />
                        <label for={value.serviceName}></label>
            </span>
            <Image src={image} alt="usps" />
            <label className="option-text">{value.serviceName === "DHL" ? "FedEx" : value.serviceName}</label>
          </div>
          <span className="text-end process-price">
            {value.price === 0 ? "FREE" : "$" + value.price}
          </span>
        </div>
      </>
        )
  });

  return (
    <>
      <Container>
        <section className="processing-section">
            {(step === 1 || step === 2) &&
            <React.Fragment>
                <div className="processing-title" style={{maxWidth: "747px"}} id="step1">
                    <h2 style={{textAlign: "center"}}>Reship my documents to the correct address.</h2>
                    <div/>
                </div>
                <div style={{textAlign: "center", fontSize: "18px", width: "75%", margin: "auto"}}>
                    <p>It looks like an incorrect address was used to apply for your order and your order has already been shipped. In order to have your documents shipped to the correct address, you
                        will have to purchase additional shipping.</p>
                    <p>Our apologies for the inconvenience. We work diligently to insure that customers can check their order information before shipment.</p>
                </div>
            </React.Fragment>
            }

            {step === 3 &&
                <React.Fragment>
                    <div className="processing-title">
                        <h2 style={{textAlign: "center"}}>Order hasn't arrived.</h2>
                        <p>We'll help you figure this out.</p>
                        <div/>
                    </div>
                    <div style={{textAlign: "center", fontSize: "18px"}}>
                        <p>Sometimes the post office can take a few days longer than expected to deliver an order.</p>
                        <p>You should receive your order by {props.location.maxDate !== undefined ? props.location.maxDate : "_____"}.</p>
                        <p>For now, let's make sure that the address you have on file is correct.</p>
                    </div>

                    <Row className="dashboard-data justify-content-center mt-0 ms-0">
                        <Col className="col-12 col-sm-12 col-md-12 col-lg-8">
                            <section className="personal-info-section" id="ShippingAddress" style={{ display: "block" }}>
                                <Row style={{padding: "0 10px"}}>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <Form.Group className="form-group">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control type="text" className="form-control" name="address" value={shippingData.address} disabled/>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                                        <Form.Group className="form-group">
                                            <Form.Label>Address 2</Form.Label>
                                            <Form.Control type="text" className="form-control" name="address2" value={shippingData.address2} disabled/>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3">
                                        <Form.Group className="form-group">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control type="text" className="form-control" name="city" value={shippingData.city} disabled/>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3">
                                        <Form.Group className="form-group">
                                            <Form.Label>State</Form.Label>
                                            <Form.Control type="text" className="form-control" name="state" value={shippingData.state} disabled/>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3">
                                        <Form.Group className="form-group">
                                            <Form.Label>Country</Form.Label>
                                            <Form.Control type="text" className="form-control" name="country" value={shippingData.country} disabled/>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3">
                                        <Form.Group className="form-group">
                                            <Form.Label>ZIP Code</Form.Label>
                                            <Form.Control type="text" className="form-control" name="zipcode" value={shippingData.zipcode} disabled/>
                                        </Form.Group>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                                        <Form.Group className="form-group">
                                            <Form.Label>Phone number</Form.Label>
                                            <Form.Control type="text" className="form-control" name="phonenumber" value={shippingData.phonenumber} disabled/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className="modal-btn model-btn mt-3 mb-3">
                                    <div>
                                        <button type="submit" className="btn btn-warning" style={{padding: "6px 30px"}} onClick={handleShippingModel.bind(this, "valid")}>Yes, this is my address</button>
                                    </div>
                                    <div className="model-btn-b">
                                        <button type="submit" className="btn btn-danger" style={{color: "black"}} onClick={handleShippingModel.bind(this, "invalid")}>No, this address is incorrect</button>
                                    </div>
                                </div>
                            </section>
                        </Col>
                    </Row>
                </React.Fragment>
            }

          {(step === 1 || step === 2) &&
          <Row className="dashboard-data justify-content-center mt-0 ms-0">
            <Col className="col-12 col-sm-12 col-md-12 col-lg-10">
              <ShippingAddress userId={userId} reload={reload} isValidate={false}/>
            </Col>
          </Row>
          }

          {(step === 1 || step === 2) &&
          <Row className="justify-content-center">
            <Col className="col-12 col-sm-12 col-md-12 col-lg-10">
              <Form>
                <div className="payment-stripe">
                  <Row>
                    <Col className="col-12 col-sm-12 col-md-12 col-lg-6">
                      <div className="processing-section-title">
                        <p>Reshipment Fee</p>
                        <ul>
                          <li>
                              Printed Booklet and ID Card &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                            <span className="courier-price text-end">
                              ${proccessFee.toFixed(2)}
                            </span>
                          </li>
                        </ul>
                      </div>
                      {isLoad && (
                          <div className="payment-loader">
                            <Loader/>
                          </div>
                      )}
                      {!isLoad && (
                          <div className="processing-section-title">
                            <p>Tracking</p>
                            {serviceOfCountry}
                          </div>
                      )}
                      {!isLoad && (
                          <div className="payment-section">
                            <p>Payment</p>

                            <div className="stripe-payment">
                              <div className="AppWrapper text-center">
                                {cardDetail.card_flag == 1 && (
                                    <SavedCardDetail
                                        resetCardDetail={resetCardDetail}
                                        processFee={proccessFee}
                                    />
                                )}
                                {cardDetail.card_flag == 0 && (
                                    <StripeProvider
                                        apiKey={
                                          process.env.REACT_APP_REACT_API_STRIPE_KEY
                                        }
                                    >
                                      <Checkout/>
                                    </StripeProvider>
                                )}
                              </div>
                            </div>
                          </div>
                      )}
                    </Col>
                    <Col className="col-12 col-sm-12 col-md-12 col-lg-6">
                      {!isLoad && (
                          <div className="summary-section">
                            <p>SUMMARY</p>
                            <ul className="processing border-bottom-0">
                              <li className="p-0">Tracking</li>
                              <li className="p-0">${payData.price !== undefined ? payData.price.toFixed(2) : ''}</li>
                            </ul>
                            <ul className="processing">
                              <li className="p-0">Printed Booklet and ID Card</li>
                              <li className="p-0">+${proccessFee.toFixed(2)}</li>
                            </ul>
                            <ul className="ps-4 pe-4">
                              <li className="p-0">Total</li>
                              <li className="p-0">${totalAmount.toFixed(2)}</li>
                            </ul>
                            <h6>
                              <Image
                                  src={
                                    require("./../../../assets/images/payment.svg")
                                        .default
                                  }
                                  alt="Payment"
                              />{" "}
                              Safe & Secure Payment
                            </h6>
                          </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
          }
        </section>
      </Container>
    </>
  );
}

export default ReShipping;

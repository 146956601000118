import { useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col, Form, Button, Alert, Image } from "react-bootstrap";
import usePersonal from "./usePersonal";
import CountrySelect from "react-bootstrap-country-select";
import { countryList } from "../../../../../common/country-list";
import validate from "./validatePersonalInfo";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import UserContext from "../../../../../store/user-context";
import { API } from "../../../../../route/services";

const PersonalInformation = (props) => {
  const userId = props.userId;
  const orderId = localStorage.getItem("orderId");
  const userContext = useContext(UserContext);

  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");

  const {
    handlePersonalChange,
    handlePersonalSubmit,
    values,
    errors,
  } = usePersonal(submitPersonalInfo, validate);

  const [value, setValue] = useState(values);
  const [alreadyShipped, setAlreadyShipped] = useState(false);
  const [pobvalue, setpobvalue] = useState("");
  const [pobError, setpobError] = useState("");
  const [dobError, setdobError] = useState("");
  const [alert, setAlert] = useState(false);

  function handleCountryChange(e) {
    if (e && e.id) {
      setpobvalue(e.name);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
    }, 9000);
  });

  useEffect(() => {
    axios
      .get(`${API.getUserPerosnalInfo}/${userId}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        mode: "no-cors",
      })
      .then((response) => {
        setValue(
          ((values.firstname = response.data.first_name),
          (values.lastname = response.data.last_name),
          (values.email = response.data.email),
          (values.dob = response.data.date_of_birth))
        );
        setpobvalue(response.data.place_of_birth);
        setAlreadyShipped(response.data.shipped_bool);
      })
      .catch((error) => {
        console.log("get perosnal info error:", error.response);
      });
  }, []);

  async function submitPersonalInfo() {
    if (!pobvalue) {
      return setpobError("Place of Birth is required");
    }
    if(props.isAdmin === 0 && pobvalue === "Other") {
      return props.countryModel(true, "place of birth");
    }
    setpobError("");
    setdobError("");
    if (!Object.keys(validate(values)).length) {
      if (!alreadyShipped) {
        await axios
          .put(
            `${API.putUserPerosnalInfo}/${userId}/`,
            {
              first_name: values.firstname,
              last_name: values.lastname,
              email: values.email,
              date_of_birth: values.dob,
              place_of_birth: pobvalue,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              mode: "no-cors",
            }
          )
          .then((response) => {
            setAlert(true);
            setpobError("");
            userContext.completeProfileHandler();
            //function calling : banner change on dashboard
            if (props.apiCall) {
              props.apiCall();
            }

            //function calling : admin side datatable reload
            if (props.adminReloadDatatableHandler) {
              props.adminReloadDatatableHandler(orderId);
            }
            if(props.isAdmin === 0 && props.isShipped) {
              props.shippedModel();
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              localStorage.clear();
              window.location.href="/login";
            } else {
              console.log("post perosnal info error:", error.response);
              setdobError(error.response.data.error[0]);
            }
          });
      } else {
        props.modelShow();
      }
    }
  }

  return (
    <>
      <section className="personal-info-section">
        <h3>{`${
          props.isAdmin === 0 ? "Personal Information" : "Customer Information"
        }`}</h3>
        <Form
          onSubmit={(e) => handlePersonalSubmit(e)}
          className="form"
          noValidate
        >
          <Row>
            <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
              <Form.Group className="form-group">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  name="firstname"
                  placeholder={firstname}
                  value={values.firstname}
                  readOnly={true}
                  onChange={handlePersonalChange}
                ></Form.Control>
                {errors.firstname && (
                  <small className="text-danger">{errors.firstname}</small>
                )}
              </Form.Group>
            </Col>
            <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
              <Form.Group className="form-group">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastname"
                  className="form-control"
                  placeholder={lastname}
                  value={values.lastname}
                  readOnly={true}
                  onChange={handlePersonalChange}
                ></Form.Control>
                {errors.lastname && (
                  <small className="text-danger">{errors.lastname}</small>
                )}
              </Form.Group>
            </Col>
            <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
              <Form.Group className="form-group">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  className="form-control"
                  value={values.email}
                  onChange={handlePersonalChange}
                  readOnly={props.isAdmin === 0 ? false : true}
                ></Form.Control>
                {errors.email && (
                  <small className="text-danger">{errors.email}</small>
                )}
              </Form.Group>
            </Col>
            <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
              <Form.Group className="form-group">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  name="dob"
                  className="form-control"
                  value={values.dob}
                  max={new Date().toISOString().slice(0, 10)}
                  onChange={handlePersonalChange}
                ></Form.Control>
                {(dobError || errors.dob) && (
                  <small className="text-danger">
                    {dobError || errors.dob}
                  </small>
                )}
              </Form.Group>
            </Col>
            {countryList.length > 0 && (
              <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                <Form.Group className="form-group">
                  <Form.Label>Place of Birth</Form.Label>
                  <CountrySelect
                    name="country"
                    placeholder={pobvalue}
                    // value={pobvalue}
                    onChange={handleCountryChange}
                    countries={countryList}
                    className={(props.isAdmin === 0 && pobvalue === "Other") ? "country-warn" : ""}
                  />
                  {pobError && (
                    <small className="text-danger">{pobError}</small>
                  )}
                </Form.Group>
              </Col>
            )}
          </Row>

          {alert && (
            <div className="alert-message">
              <Alert className="alert-success">
                <Image
                  src={
                    require("./../../../../../assets/images/success.svg")
                      .default
                  }
                  alt="Document"
                  className="me-2"
                />
                {props.isAdmin === 0
                  ? "Personal Information updated successfully"
                  : "Customer Information updated successfully"}
              </Alert>
            </div>
          )}

          <div className="d-flex justify-content-end">
            <Button
              className="btn btn-success mt-3 mb-1"
              type="submit"
              onClick={submitPersonalInfo}
            >
              {" "}
              Save{" "}
            </Button>
          </div>
        </Form>
      </section>
    </>
  );
};
export default PersonalInformation;

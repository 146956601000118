import { Link } from "react-router-dom";
import { Image, Button } from "react-bootstrap";

function DocumentExpired() {
  return (
    <>
      <section className="document-section">
        <div className="d-flex d-md-block d-lg-block align-items-center justify-content-between">
          <h3>Digital Documents</h3>
          <Link className="menuicon">
            <Image
              src={require("./../../../../../assets/images/menu.svg").default}
              alt="menu"
              className="d-block d-md-none d-lg-none"
            />
          </Link>
        </div>

        <div className="digital-document pt-5 pb-5">
          <p>Your document have expired.</p>
          <h3>Would you like to renew your document at a discount?</h3>
          <Link to="./planrenewal">
            <Button type="button" className="btn btn-success">
              Yes, Please!
            </Button>
          </Link>
        </div>
      </section>
    </>
  );
}

export default DocumentExpired;

import React from 'react';
import {Row, Col, Image, Alert, Modal} from "react-bootstrap";
import {Dialog, DialogTitle, DialogContent, DialogActions} from "@material-ui/core";
import Loader from './../../../loader/Loader';
import {loadModels, getFullFaceDescription} from "./face";
import axios from 'axios';
import UserContext from '../../../../../store/user-context';
import {API} from '../../../../../route/services';
import ImageEditorRc from 'react-cropper-image-editor';
import heic2any from "heic2any";
import $ from "jquery";
import fileDownload from 'js-file-download'
import CustomLoader from "react-loader-spinner";

class ImageResize extends React.Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.userId,
            adminUser: this.props.adminUser,
            originalImage: null,
            fileName: "",
            croppedImage: null,
            isProcessedImage: false,
            isFound: false,
            isCropDialogOpen: false,
            isError: false,
            isLoadMain: false,
            isLoad: false,
            isAlertSuccess: false,
            isAlertError: false,
            isToolDisable: false,
            AddHeadShot: false,
            supportedFormat: ['jpg', 'jpeg', 'png', 'heic', 'heif', 'JPG', 'JPEG', 'PNG', 'HEIC', 'HEIF'],
            isProccess: false
        };
        this.setAlertTimer()
    }

    componentDidMount = async () => {
        //await loadModels();
        this.getProfileImage();
    };

    getProfileImage = () => {
        axios.get(`${API.profileImageGetUpdate}/${this.state.userId}/`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            }
        ).then((response) => {
            if (response.data.profile_photo) {
                let img = response.data.profile_photo;
                let filneName = response.data.profile_photo_name !== undefined ? response.data.profile_photo_name : "";
                this.setState({croppedImage: img, originalImage: img, fileName: filneName, isFound: true});
                if (!response.data.is_headshot_processed) {
                    this.setState({croppedImage: null, isProcessedImage: false});
                    if (!this.state.adminUser) {
                        this.setState({AddHeadShot: true});
                    }
                }else{
                    setTimeout(() => {
                        if (!this.state.adminUser && this.props.orderModel !== 1) {
                            this.props.handlemodelOrder(3);
                        }
                    },500);
                    this.setState({isProcessedImage:true});
                }
            }else {
                this.setState({croppedImage: null, originalImage: null, isFound: true, isProcessedImage:false});
                if (!this.state.adminUser) {
                    this.setState({AddHeadShot: true});
                }
            }
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href="/login";
            }
            console.log("get profile image error:", error.response);
        });
    };

    setAlertTimer() {
        setInterval(() => {
            this.setState({isAlertSuccess: false, isAlertError: false})
        }, 3000);

        setInterval(() => {
            this.getProfileImage();
        }, 300000)
    }

    selectProfileImage = async (e) => {
        this.setState({isCropDialogOpen: true, isLoad: true, isToolDisable: true});
        if (e.target.files && e.target.files.length > 0) {
            let file = e.target.files[0];
            let fileExtension = this.getFileExtension(e.target.files[0].name);
            if (this.state.supportedFormat.includes(fileExtension)) {
                this.setState({isFormatError: false});
                if (fileExtension === 'heic' || fileExtension === 'heif') {
                    let blobURL = URL.createObjectURL(e.target.files[0]);
                    let blobRes = await fetch(blobURL);
                    let blob = await blobRes.blob();
                    let blobImage = await heic2any({blob, 'toType': 'jpg'});
                    let base64Image = await this.convertBlobToBase64(blobImage);
                    this.checkProfileImage('select', blobImage, base64Image);
                } else {
                    const reader = new FileReader();
                    reader.addEventListener('load', async () => {
                        let blobImage = this.dataURIToBlob(reader.result);
                        let base64Image = reader.result;
                        this.checkProfileImage('select', blobImage, base64Image)
                    });
                    reader.readAsDataURL(e.target.files[0]);
                    e.target.value = null;
                }
            } else {
                this.setState({isFormatError: true, isLoad: false});
            }
        }
    };

    convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });

    getFileExtension(filename) {
        return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0].toLowerCase() : undefined;
    }

    handleCropDialogOpen = () => {
        this.setState({AddHeadShot: false, isCropDialogOpen: true, originalImage: this.state.croppedImage});
    };

    handleNoticeDialogOpen = () => {
        this.setState({AddHeadShot: false});
        if (!this.state.adminUser) {
            this.props.handlemodelOrder(3);
        }
    };

    handleCropDialogClose = () => {
        this.setState({isCropDialogOpen: false, isFormatError: false, isError: false, isToolDisable: false, isLoad: false});
        setTimeout(() => {
            $('body').removeAttr('style');
        }, 300);
        if (!this.state.adminUser) {
            this.props.handlemodelOrder(3);
        }
    };

    imageZoom(percent) {
        if(this.refs.cropper !== undefined) {
            this.refs.cropper.zoom(percent);
        }
    }

    imageRotate(deg) {
        if(this.refs.cropper !== undefined) {
            this.refs.cropper.rotate(deg);
        }
    }

    imageFlipX() {
        if(this.refs.cropper !== undefined) {
            const dom = this.refs.flipX;
            let scale = dom.getAttribute('data-scale');
            scale = scale ? -scale : -1;
            this.refs.cropper.scaleX(scale);
            dom.setAttribute('data-scale', scale);
        }
    }

    imageFlipY() {
        if(this.refs.cropper !== undefined) {
            const dom = this.refs.flipY;
            let scale = dom.getAttribute('data-scale');
            scale = scale ? -scale : -1;
            this.refs.cropper.scaleY(scale);
            dom.setAttribute('data-scale', scale);
        }
    }

    imageReset() {
        if(this.refs.cropper !== undefined) {
            this.refs.cropper.reset();
        }
    }

    imageBrowse() {
        this.refs.input.click();
    }

    checkProfileImage = async (type, blobImage, base64Image) => {

        if (this.state.adminUser) {

            this.setState({originalImage: base64Image, isError: false, isLoad: false, isToolDisable: false});

        } else {

            let imageName = 'profile-' + this.state.userId + '.png';
            var formData = new FormData();
            formData.append("profile_photo", blobImage, imageName);
            formData.append("validate_only", true);

            axios({
                url: `${API.profileImageGetUpdate}/${this.state.userId}/`,
                method: "PUT",
                data: formData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            }).then((response) => {
                this.setState({originalImage: base64Image, isError: false, isLoad: false, isToolDisable: false});
            }).catch((error) => {
                this.setState({isError: true, isLoad: false, isToolDisable: false});
            });
        }

        /*await getFullFaceDescription(image).then(async (fullDesc) => {
            if (!!fullDesc.length) {
                if (type === 'select') {
                    this.setState({isCropDialogOpen: true, isLoad: false, originalImage: image, isToolDisable: false});
                } else {
                    this.uploadImageHandler();
                }
                this.setState({isError: false});
            } else {
                this.setState({isCropDialogOpen: true, isError: true, isLoad: false, isToolDisable: this.state.originalImage === null ? true : false})
            }
        });*/
    };

    dataURIToBlob(dataURI) {
        const splitDataURI = dataURI.split(',');
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], {type: mimeString})
    }

    validateImageHandler = async () => {
        this.setState({isLoad: true, isToolDisable: true});
        let cropCanvasData = this.refs.cropper.getCroppedCanvas();
        let image = cropCanvasData.toDataURL('image/jpg');
        this.checkProfileImage('upload', image);
    };

    uploadImageHandler = async () => {
        if(this.refs.cropper !== undefined && this.refs.cropper.getCroppedCanvas() !== null) {
            this.setState({isLoad: true, isToolDisable: true, isError: false});
            let cropData = this.refs.cropper.getCroppedCanvas().toDataURL();
            const data = this.dataURIToBlob(cropData);
            let imageName = 'profile-' + this.state.userId + '.png';
            var formData = new FormData();
            formData.append("profile_photo", data, imageName);

            axios({
                url: `${API.profileImageGetUpdate}/${this.state.userId}/`,
                method: "PUT",
                data: formData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            }).then((response) => {
                this.setState({isAlertSuccess: true, originalImage: cropData, croppedImage: cropData, isLoad: false, isCropDialogOpen: false, isToolDisable: false, isProcessedImage: true});
                setTimeout(() => {
                    $('body').removeAttr('style');
                    if (!this.state.adminUser && this.props.isShipped) {
                        this.props.apiCall();
                        this.props.shippedModel();
                    }
                }, 300);
                if(this.props.ordeId !== undefined) {
                    this.props.statusChange(this.props.ordeId);
                }
                this.context.completeProfileHandler();
                if (!this.state.adminUser) {
                    this.props.handlemodelOrder(3);
                }
                if(this.state.adminUser === true && this.props.reloadHandler !== undefined) {
                    this.props.reloadHandler();
                }
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    window.location.href="/login";
                } else if (error.response.status === 400) {
                    this.setState({isError: true});
                } else {
                    this.setState({isAlertError: true});
                }
                this.setState({isLoad: false, isToolDisable: false});
            });
        }
    };

    handleImageError = () => {
        this.setState({croppedImage: null, originalImage: null, isFound: true, isProcessedImage:false});
        if (!this.state.adminUser) {
            this.props.handlemodelOrder(2);
            this.setState({AddHeadShot: true});
        }
    };

    handleClick = () => {
        this.setState({isProccess: true});
        axios.get(`${API.profileImageGetUpdate}/${this.state.userId}/`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
            }
        ).then((response) => {
            axios.get(response.data.profile_photo, {
                responseType: 'blob'
            }).then((res) => {
                fileDownload(res.data, response.data.profile_photo_name);
                this.setState({isProccess: false});
            }).catch((error) => {
                this.setState({isProccess: false});
            });
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.href="/login";
            }
            console.log("get profile image error:", error.response);
        });
    };

    render() {
        const {isError, isLoad, isAlertSuccess, isAlertError, originalImage, croppedImage, isCropDialogOpen, isToolDisable, adminUser, isFormatError, AddHeadShot, isFound, isProcessedImage, isProccess} = this.state;

        return (
            <section className="upload-photo-section">
                {adminUser &&
                <h3>Profile Picture</h3>
                }
                <Row className="align-items-center">
                    <Col className={!adminUser ? 'col-12 col-sm-12 col-md-12 col-lg-5' : 'col-12 col-sm-12 col-md-12 col-lg-12'}>
                        <div className="avatar-profile">
                            {croppedImage === null && isFound &&
                            <div className="upload-pic no-avatar">
                                <input type="file" accept="image/*,.heic,.heif" onChange={this.selectProfileImage}/>
                            </div>
                            }
                            {croppedImage !== null && isFound &&
                            <div className="upload-pic" onClick={this.handleCropDialogOpen.bind(this)}>
                                    <img alt="Crop" style={{maxWidth: '100%'}} src={croppedImage}
                                         onError={this.handleImageError}/>
                                </div>
                            }
                            {adminUser &&  isFound &&
                            <div className="btn-dl">
                                <button type="button" onClick={this.handleClick} disabled={isProccess}
                                        className="btn btn-success btn-download-hs">{!isProccess ? 'DOWNLOAD HEADSHOT' :
                                    <CustomLoader type="ThreeDots" color="#007B96" transform="translate(-50%,-50%)" height={20} width={160}/>}
                                </button>
                            </div>
                            }

                            {/*{adminUser && isFound && originalImage !== null &&*/}
                            {/*    // <div className="btn-dl">*/}
                            {/*    // <button className="btn-download-link" onClick={this.downloadProfileFile()}>Download</button>*/}
                            {/*    // </div>*/}
                            {/*    <div className="btn-dl">*/}
                            {/*        <button type="button" onClick={this.handleClick} className="btn btn-success">DOWNLOAD DOCUMENT</button>*/}
                            {/*    </div>*/}
                            {/*}*/}
                        </div>
                    </Col>

                    {!adminUser &&
                    <Col className="col-12 col-sm-12 col-md-12 col-lg-7">
                        <div className="photo-upload-details text-center">
                            <p>This is how your photo will appear on your documents.</p>
                            <p>Click photo to open our photo editor</p>
                        </div>
                    </Col>
                    }

                    <Dialog open={isCropDialogOpen}  maxWidth={'sm'} fullWidth={true}>
                        <DialogTitle>
                            <span className="crop-title">
                                Crop Headshot
                                <button type="button" className="tool-close m-0" onClick={this.handleCropDialogClose.bind(this)}>
                                    <svg viewBox="0 0 24 24"><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
                                </button>
                            </span>
                        </DialogTitle>
                        <DialogContent dividers>
                            <div className="img-crop-box-content">
                                {isLoad && <div className="photoloader"><Loader/></div>}
                                {/*aspectRatio={1 / 1}*/}
                                {originalImage !== null &&
                                <ImageEditorRc ref='cropper' style={{height: 400, width: 400}} src={originalImage} aspectRatio={null} className="img-crop-box"
                                               guides={false} rotatable={true} imageName='image name with extension to download' saveImage={this.handleIdCropImage} responseType='blob/base64'
                                               movable={true}  zoomable={true} dragMode='move' autoCrop={true} cropBoxMovable={true} cropBoxResizable={true}
                                />
                                }

                                {originalImage === null &&
                                <div>
                                    <div className="no-avatar-crop" onClick={this.imageBrowse.bind(this)}/>
                                    <p className="error-message m-0 text-center">Upload A Clear Photo of Your Face</p>
                                </div>
                                }

                                {originalImage !== null && isError &&
                                <div>
                                    <p className="error-message m-0 text-center">Upload A Clear Photo of Your Face</p>
                                </div>
                                }

                                {isFormatError &&
                                <p className="error-message m-0 text-center">Upload Photo with this format jpg, jpeg, png, heic or heif</p>
                                }
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <div className="img-crop-action">
                                <button type="button" className="tool-button-name" onClick={this.imageBrowse.bind(this)}>Upload</button>
                                <div className="img-crop-tool">
                                    <button type="button" className="tool-button" onClick={this.imageZoom.bind(this, -0.2)} disabled={isToolDisable}>
                                        <svg viewBox="0 0 24 24">
                                            <path
                                                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z"/>
                                        </svg>
                                    </button>
                                    <button type="button" className="tool-button" onClick={this.imageZoom.bind(this, 0.2)} disabled={isToolDisable}>
                                        <svg viewBox="0 0 24 24">
                                            <path
                                                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
                                            <path d="M12 10h-2v2H9v-2H7V9h2V7h1v2h2v1z"/>
                                        </svg>
                                    </button>
                                    <button type="button" className="tool-button" onClick={this.imageRotate.bind(this, -90)} disabled={isToolDisable}>
                                        <svg viewBox="0 0 24 24">
                                            <path
                                                d="M7.11 8.53 5.7 7.11C4.8 8.27 4.24 9.61 4.07 11h2.02c.14-.87.49-1.72 1.02-2.47zM6.09 13H4.07c.17 1.39.72 2.73 1.62 3.89l1.41-1.42c-.52-.75-.87-1.59-1.01-2.47zm1.01 5.32c1.16.9 2.51 1.44 3.9 1.61V17.9c-.87-.15-1.71-.49-2.46-1.03L7.1 18.32zM13 4.07V1L8.45 5.55 13 10V6.09c2.84.48 5 2.94 5 5.91s-2.16 5.43-5 5.91v2.02c3.95-.49 7-3.85 7-7.93s-3.05-7.44-7-7.93z"/>
                                        </svg>
                                    </button>
                                    <button type="button" className="tool-button" onClick={this.imageRotate.bind(this, 90)} disabled={isToolDisable}>
                                        <svg viewBox="0 0 24 24">
                                            <path
                                                d="M15.55 5.55 11 1v3.07C7.06 4.56 4 7.92 4 12s3.05 7.44 7 7.93v-2.02c-2.84-.48-5-2.94-5-5.91s2.16-5.43 5-5.91V10l4.55-4.45zM19.93 11c-.17-1.39-.72-2.73-1.62-3.89l-1.42 1.42c.54.75.88 1.6 1.02 2.47h2.02zM13 17.9v2.02c1.39-.17 2.74-.71 3.9-1.61l-1.44-1.44c-.75.54-1.59.89-2.46 1.03zm3.89-2.42 1.42 1.41c.9-1.16 1.45-2.5 1.62-3.89h-2.02c-.14.87-.48 1.72-1.02 2.48z"/>
                                        </svg>
                                    </button>
                                    <button type="button" ref="flipX" className="tool-button" onClick={this.imageFlipX.bind(this)} disabled={isToolDisable}>
                                        <svg viewBox="0 0 24 24">
                                            <path
                                                d="M15 21h2v-2h-2v2zm4-12h2V7h-2v2zM3 5v14c0 1.1.9 2 2 2h4v-2H5V5h4V3H5c-1.1 0-2 .9-2 2zm16-2v2h2c0-1.1-.9-2-2-2zm-8 20h2V1h-2v22zm8-6h2v-2h-2v2zM15 5h2V3h-2v2zm4 8h2v-2h-2v2zm0 8c1.1 0 2-.9 2-2h-2v2z"/>
                                        </svg>
                                    </button>
                                    <button type="button" ref="flipY" className="tool-button" onClick={this.imageFlipY.bind(this)} disabled={isToolDisable}>
                                        <svg viewBox="0 0 24 24">
                                            <path d="M8 19h3v4h2v-4h3l-4-4-4 4zm8-14h-3V1h-2v4H8l4 4 4-4zM4 11v2h16v-2H4z"/>
                                        </svg>
                                    </button>
                                    <button type="button" ref="flipY" className="tool-button m-0" onClick={this.imageReset.bind(this)} disabled={isToolDisable}>
                                        <svg viewBox="0 0 24 24">
                                            <path
                                                d="M12 5V2L8 6l4 4V7c3.31 0 6 2.69 6 6 0 2.97-2.17 5.43-5 5.91v2.02c3.95-.49 7-3.85 7-7.93 0-4.42-3.58-8-8-8zm-6 8c0-1.65.67-3.15 1.76-4.24L6.34 7.34C4.9 8.79 4 10.79 4 13c0 4.08 3.05 7.44 7 7.93v-2.02c-2.83-.48-5-2.94-5-5.91z"/>
                                        </svg>
                                    </button>
                                    <input className="d-none" ref="input" type="file" name="image" accept="image/*,.heic,.heif" onChange={this.selectProfileImage}/>
                                </div>
                                <button type="button" className="tool-button-name" onClick={this.uploadImageHandler.bind(this)} disabled={isToolDisable}>Apply</button>
                            </div>
                        </DialogActions>
                    </Dialog>

                    {AddHeadShot && this.props.orderModel !== 1 &&
                    <div className="modal-dialog modal-xl">
                        <Modal show={true} onHide={false} centered>
                            <Modal.Body className="text-center">
                                <h3>Add a headshot</h3>
                                <br/>
                                <h6><b>Note:</b> your application will not be processed until a headshot is added</h6>
                                <div className="modal-btn">
                                    <button type="submit" className="btn btn-primary" onClick={this.handleCropDialogOpen}>Ok</button>
                                    <button type="submit" className="btn btn-danger btn btn-primary" onClick={this.handleNoticeDialogOpen}>Exit</button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                    }
                </Row>

                {isAlertSuccess && (
                    <div className="alert-message">
                        <Alert className="alert-success">
                            <Image src={require("./../../../../../assets/images/success.svg").default} alt="Document" className="me-2"/>
                            Image updated successfully
                        </Alert>
                    </div>
                )}

                {isAlertError && (
                    <div className="alert-message">
                        <Alert className="alert-danger">
                            <Image src={require("./../../../../../assets/images/not-received.svg").default} alt="Document" className="me-2"/>
                            Something went wrong, Please try after sometime.
                        </Alert>
                    </div>
                )}
            </section>
        )
    }
}

export default ImageResize;
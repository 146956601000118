import axios from "axios";
import { API } from "../route/services";

export const COUNTRY_LIST = [
	{
		id: 'US',
		name: 'United States of America',
		flag: 'US'
	},
	{
		id: 'CA',
		name: 'Canada',
		flag: 'CA'
	},
	{
		id: 'CN',
		name: 'China',
		flag: 'CN'
	},
	{
		id: 'FO',
		name: 'Faroe Islands',
		flag: 'FO'
	},
	{
		id: 'GG',
		name: 'Guernsey',
		flag: 'GG'
	},
	{
		id: 'JE',
		name: 'Jersey',
		flag: 'JE'
	},
	{
		id: 'MT',
		name: 'Malta',
		flag: 'MT'
	},
	{
		id: 'AM',
		name: 'Armenia',
		flag: 'AM'
	},
	{
		id: 'BH',
		name: 'Bahrain',
		flag: 'BH'
	},
	{
		id: 'EG',
		name: 'Egypt',
		flag: 'EG'
	},
	{
		id: 'IL',
		name: 'Israel',
		flag: 'IL'
	},
	{
		id: 'JO',
		name: 'Jordan',
		flag: 'JO'
	},
	{
		id: 'KW',
		name: 'Kuwait',
		flag: 'KW'
	},
	{
		id: 'LB',
		name: 'Lebanon',
		flag: 'LB'
	},
	{
		id: 'OM',
		name: 'Oman',
		flag: 'OM'
	},
	{
		id: 'QA',
		name: 'Qatar',
		flag: 'QA'
	},
	{
		id: 'SY',
		name: 'Syrian Arab Republic',
		flag: 'SY'
	},
	{
		id: 'TR',
		name: 'Turkey',
		flag: 'TR'
	},
	{
		id: 'AE',
		name: 'United Arab Emirates',
		flag: 'AE'
	},
	{
		id: 'YE',
		name: 'Yemen',
		flag: 'YE'
	},
	{
		id: 'BD',
		name: 'Bangladesh',
		flag: 'BD'
	},
	{
		id: 'IN',
		name: 'India',
		flag: 'IN'
	},
	{
		id: 'ID',
		name: 'Indonesia',
		flag: 'ID'
	},
	{
		id: 'MO',
		name: 'Macao',
		flag: 'MO'
	},
	{
		id: 'MY',
		name: 'Malaysia',
		flag: 'MY'
	},
	{
		id: 'PH',
		name: 'Philippines',
		flag: 'PH'
	},
	{
		id: 'LK',
		name: 'Sri Lanka',
		flag: 'LK'
	},
	{
		id: 'TH',
		name: 'Thailand',
		flag: 'TH'
	},
	{
		id: 'VN',
		name: 'Viet Nam',
		flag: 'VN'
	},
	{
		id: 'MX',
		name: 'Mexico',
		flag: 'MX'
	},
	{
		id: 'CY',
		name: 'Cyprus',
		flag: 'CY'
	},
	{
		id: 'SA',
		name: 'Saudi Arabia',
		flag: 'SA'
	},
	{
		id: 'AR',
		name: 'Argenita',
		flag: 'AR'
	},
	{
		id: 'BZ',
		name: 'Beliz',
		flag: 'BZ'
	},
	{
		id: 'BO',
		name: 'Bolivia',
		flag: 'BO'
	},
	{
		id: 'BR',
		name: 'Brazil',
		flag: 'BR'
	},
	{
		id: 'CL',
		name: 'Chile',
		flag: 'CL'
	},
	{
		id: 'HR',
		name: 'Croatia',
		flag: 'HR'
	},
	{
		id: 'EC',
		name: 'Ecuador',
		flag: 'EC'
	},
	{
		id: 'SV',
		name: 'El Salvador',
		flag: 'SV'
	},
	{
		id: 'GF',
		name: 'French Guiana',
		flag: 'GF'
	},
	{
		id: 'GT',
		name: 'Guatemala',
		flag: 'GT'
	},
	{
		id: 'GY',
		name: 'Guyana',
		flag: 'GY'
	},
	{
		id: 'HN',
		name: 'Honduras',
		flag: 'HN'
	},
	{
		id: 'PA',
		name: 'Panama',
		flag: 'PA'
	},
	{
		id: 'PY',
		name: 'Paraguay',
		flag: 'PY'
	},
	{
		id: 'PE',
		name: 'Peru',
		flag: 'PE'
	},
	{
		id: 'SR',
		name: 'Suriname',
		flag: 'SR'
	},
	{
		id: 'UY',
		name: 'Uruguay',
		flag: 'UY'
	},
	{
		id: 'VE',
		name: 'Venezuela',
		flag: 'VE'
	},
	{
		id: 'CO',
		name: 'Colombia',
		flag: 'CO'
	},
	{
		id: 'CR',
		name: 'Costa Rica',
		flag: 'CR'
	},
	{
		id: 'CZ',
		name: 'Czech Republic',
		flag: 'CZ'
	},
	{
		id: 'EE',
		name: 'Estonia',
		flag: 'EE'
	},
	{
		id: 'GE',
		name: 'Georgia',
		flag: 'GE'
	},
	{
		id: 'HU',
		name: 'Hungary',
		flag: 'HU'
	},
	{
		id: 'LV',
		name: 'Latvia',
		flag: 'LV'
	},
	{
		id: 'LT',
		name: 'Lithuania',
		flag: 'LT'
	},
	{
		id: 'JP',
		name: 'Japan',
		flag: 'JP'
	},
	{
		id: 'KP',
		name: 'Korea (Republic of)',
		flag: 'KP'
	},
	{
		id: 'HK',
		name: 'Hong Kong',
		flag: 'HK'
	},
	{
		id: 'SG',
		name: 'Singapore',
		flag: 'SG'
	},
	{
		id: 'TW',
		name: 'Taiwan',
		flag: 'TW'
	},
	{
		id: 'BE',
		name: 'Belgium',
		flag: 'BE'
	},
	{
		id: 'FR',
		name: 'France',
		flag: 'FR'
	},
	{
		id: 'DE',
		name: 'Germany',
		flag: 'DE'
	},
	{
		id: 'IE',
		name: 'Ireland',
		flag: 'IE'
	},
	{
		id: 'IT',
		name: 'Italy',
		flag: 'IT'
	},
	{
		id: 'LU',
		name: 'Luxemburg',
		flag: 'LU'
	},
	{
		id: 'MC',
		name: 'Monaco',
		flag: 'MC'
	},
	{
		id: 'NL',
		name: 'Netherlands',
		flag: 'NL'
	},
	{
		id: 'PT',
		name: 'Portugal',
		flag: 'PT'
	},
	{
		id: 'ES',
		name: 'Spain',
		flag: 'ES'
	},
	{
		id: 'GB',
		name: 'United Kingdom of Great Britain and Northern Ireland',
		flag: 'GB'
	},
	{
		id: 'AW',
		name: 'Aruba',
		flag: 'AW'
	},
	{
		id: 'BS',
		name: 'Bahamas',
		flag: 'BS'
	},
	{
		id: 'CU',
		name: 'Cuba',
		flag: 'CU'
	},
	{
		id: 'HT',
		name: 'Haiti',
		flag: 'HT'
	},
	{
		id: 'JM',
		name: 'Jamaica',
		flag: 'JM'
	},
	{
		id: 'AT',
		name: 'Austria',
		flag: 'AT'
	},
	{
		id: 'FI',
		name: 'Finland',
		flag: 'FI'
	},
	{
		id: 'GR',
		name: 'Greece',
		flag: 'GR'
	},
	{
		id: 'GL',
		name: 'Greenland',
		flag: 'GL'
	},
	{
		id: 'IS',
		name: 'Iceland',
		flag: 'IS'
	},
	{
		id: 'NO',
		name: 'Norway',
		flag: 'NO'
	},
	{
		id: 'SE',
		name: 'Sweden',
		flag: 'SE'
	},
	{
		id: 'CH',
		name: 'Switzerland',
		flag: 'CH'
	},
	{
		id: 'AU',
		name: 'Australia',
		flag: 'AU'
	},
	{
		id: 'NZ',
		name: 'New Zealand',
		flag: 'NZ'
	},
	{
		id: 'DK',
		name: 'Denmark',
		flag: 'DK'
	},
	{
		id: 'In',
		name: 'Other',
		flag: 'OT'
	},
]
export var countryList = [];
export var shippingCountry = [];

export const CountryList = function CountryList() {

	axios
		.get(
			API.getCountryList,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
				mode: "no-cors",
			}
		)
		.then((response) => {
			shippingCountry = response.data.filter((row) => {if(row.is_shipping_available){return row}else{return null}});
			countryList = response.data;
		})
		.catch((error) => {
			if (error.response && error.response.status === 401) {
				localStorage.clear();
				window.location.href="/login";
			}
			console.log("country list error:", error);
		});
}

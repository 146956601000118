import React from "react";
import { Image, Alert } from "react-bootstrap";
import axios from "axios";
import $ from "jquery";
import UserContext from "../../../../store/user-context";
import Loader from "../../loader/Loader";
import { Redirect } from "react-router-dom";
import { API } from "../../../../route/services";
import SweetAlert from "react-bootstrap-sweetalert";

export class SavedCardDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripeError: "",
      isLoad: false,
      isTransactionFailed: false,
      isTransactionSuccess: false,
      ip: $.getJSON("https://api.ipify.org?format=json", (data) => {
        this.setState({ ip: data.ip });
      }),
    };
  }
  static contextType = UserContext;

  handleUserInput(e) {
    this.setState({
      stripeError: "",
    });
  }

  renderRedirect = () => {
    if (this.state.isRedirect) {
      return <Redirect to="/user/dashboard" />;
    }
  };

  deleteHandler = (e) => {
    e.preventDefault();
    const userId = localStorage.getItem("id");
    this.setState({ isLoad: true });
    axios
      .post(
        `${API.userDeleteCardDetail}/${userId}/`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          mode: "no-cors",
        }
      )
      .then((response) => {
        this.setState({ isLoad: false });
        this.props.resetCardDetail();
      })
      .catch((error) => {
        console.log("post delete card error:", error);
      });
  };

  render() {
    let image;
    if (this.context.cardDetail.card_type == "Visa") {
      image = require("./../../../../assets/images/visa.svg").default;
    } else if (this.context.cardDetail.card_type == "Rupay") {
      image = require("./../../../../assets/images/master-card.png").default;
    } else if (this.context.cardDetail.card_type == "MasterCard") {
      image = require("./../../../../assets/images/master-card.png").default;
    }

    return (
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          const userId = localStorage.getItem("id");
          const price = localStorage.getItem("price");
          const planType = localStorage.getItem("option");
          const validity = localStorage.getItem("validity");
          const processFee = localStorage.getItem("proceess") || 0;
          // const price = localStorage.getItem("price");

          this.setState({ isLoad: true });
          const paymentService = this.context.payment;


          this.setState({isTransactionFailed: false, isTransactionSuccess: false});

          await axios
            .post(
              `${API.usersShippingPayment}`,
              {
                amount: parseFloat(price),
                id: userId,
                token: "0",
                description: "description",
                shipping_option: "",
                tracking: paymentService.price == 0 ? 0 : 1,
                user_ip_location: this.state.ip,
                flag: 2,
                plan_type: planType,
                validity_years: validity,
                process_fee: parseFloat(processFee),
                plan_price: price,
                ship_flag:2
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
              }
            )
            .then((response) => {

              this.setState({isTransactionSuccess: true});
              // window.location.replace("/user/dashboard");
              localStorage.removeItem("price");
              localStorage.removeItem("option");
              setTimeout(() => {
                this.setState({ isLoad: false, isRedirect: true });
              }, 2000);
            })
            .catch((error) => {

              this.setState({ isTransactionFailed: true, isLoad: false });
              console.log("post payment service error:", error);
            });
        }}
      >
        <div className="card-delete">
          <div className="card-details">
            <p>
              <Image src={image} alt="card" /> **** **** ****{" "}
              {this.context.cardDetail.card_digits}
            </p>
          </div>
          <div className="card-btn-delete">
            <a className="btn btn-danger" onClick={this.deleteHandler}>
              Delete
            </a>
          </div>
        </div>
        {this.state.isLoad && (
          <div className="payment-loader">
            <Loader />
          </div>
        )}
        {this.renderRedirect()}
        {this.state.isTransactionSuccess && (
              <SweetAlert success title="Payment Success" onConfirm={() => this.setState({isTransactionSuccess:false, isLoad: false, isRedirect: true})}>
              Your payment has been received
            </SweetAlert>
        )}

        {this.state.isTransactionFailed && (
              <SweetAlert danger title="Transaction failed" confirmBtnText="Try again" onConfirm={() => this.setState({isTransactionFailed: false})}>
              Transaction Failed , Please Try again
            </SweetAlert>
        )}
        {!this.state.isLoad && (
          <button
            className="btn btn-success shipping-button"
            type="submit"
            // onClick={submitHandler}
          >
            Pay
          </button>
        )}
      </form>
    );
  }
}


export default function validateInfo(values) {
  let errors = {};
  var newRegPasswsord = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/)


  if (!values.password) {
    errors.password = 'Password is required';
  } else if (!newRegPasswsord.test(values.password)) {
    errors.password = 'Please enter a valid password containing at least minimum 6 character , 1 letter and 1 digit';
  }

  if (!values.password2) {
    errors.password2 = 'Password is required';
  } else if (values.password2 !== values.password) {
    errors.password2 = 'Passwords do not match';
  }



  return errors;
}
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AdminRoute = ({ component: Component, ...rest }) => {
  const authentication = localStorage.getItem("id");
  const isAdmin = localStorage.getItem("isAdmin");

  return (<
    Route {...rest}
    render={
      props => {
        if (authentication && isAdmin) {
          return <Component {...rest} {...props}
          />
        } else {
          return <Redirect to={
            {
              pathname: '/page-not-found',
              state: {
                from: props.location
              },
            }
          }
          />
        }
      }
    }
  />
  )
}

export default AdminRoute;
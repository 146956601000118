function Banner29() {
  return (
    <>
      <section className="delivery-section sc-mt" id="ShippedUSPSOvernight">
        <div className="Shipping-document-banner">
          <div className="shipping-content-details">
            <p className="text-white mb-0">
              The address you submitted on your application was correct
            </p>
            <h3 className="text-white">
              Your order wiil be shipped free of charge with tracking
            </h3>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner29;

import { Link, useHistory } from "react-router-dom";
import { Image, Button } from "react-bootstrap";

function Banner27() {
  const userId = localStorage.getItem("id");
  const url = process.env.REACT_APP_SERVER_URL;
  const history = useHistory();

  async function submitHandler(e) {
    history.push({ pathname: "/user/reshipatdiscount", state: userId });
  }
  return (
    <>
      <section className="delivery-section sc-mt" id="ShippedUSPSOvernight">
        {/*<h3>IF Shipped to US and USPS Tracking, USPS 2-Day, USPS Overnight</h3>*/}
        <div className="Shipping-document-banner review-submit-btn">
          <div className="shipping-content-details">
            <p className="text-white mb-0">
              Thank you for providing your full mailing address - we have
              confirmed that it is a valid address and matches up with our
              records.
            </p>
            <p className="text-white mb-0">
              We do understand that you need your documents and have been
              working internally to provide an alternative.
            </p>

            <p className="text-white mb-0">
              We have found that FedEx hasa higher success rate of delivery and we
              strongly recommend r-shippingyour documents using one ofthese two
              methods.
            </p>
            <p className="text-white mb-0">
              Registered mail can again take upto 10 business days. FedEx is
              guaranteed within 3 business days. We do not receive any profit
              from the cost of shipping ~ we pass on the lowest possible price
              directly to you. Which is why, while we cannot re-ship these
              documents and cover the cost of Registered Mail or of FedEx - we are
              wiling to contribute a portion
            </p>
            <div>
              <Button
                type="button"
                className="btn btn-warning"
                onClick={submitHandler}
              >
                Reship my documents at a Discount
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner27;

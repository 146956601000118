import React from "react";
import Loader from "react-loader-spinner";
export default class App extends React.Component {
  //other logic
  render() {
    return (
      <Loader
        type="ThreeDots"
        color="#007B96"
        transform="translate(-50%,-50%)"
        height={80}
        width={80}
        // timeout={50000}
      />
    );
  }
}
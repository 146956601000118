
export default function validateInfo(values) {
  let errors = {};

  var newRegPasswsord = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/)

  // if (!values.oldpassword) {
  //   errors.oldpassword = 'Old password is required';
  // }

  if (!values.newpassword) {
    errors.newpassword = 'New password is required';
  } else if (!newRegPasswsord.test(values.newpassword)) {
    errors.newpassword = 'Please enter a valid password containing at least minimum 6 character , 1 letter and 1 digit';
  }
  if (!values.confirmpassword) {
    errors.confirmpassword = 'Confirm password is required';
  } else if (values.confirmpassword !== values.newpassword) {
    errors.confirmpassword = 'Passwords do not match';
  }

  return errors;
}
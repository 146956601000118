import React, {useCallback} from "react";
import axios from "axios";
import {
  Row,
  Col,
  Form,
  Button,
  Alert,
  Image,
  Container,
} from "react-bootstrap";
import validate from "./validateInfo";
import { useEffect } from "react";
import { useState } from "react";
import Sidebar from "./../../usersidebar/UserSidebar";
import useForm from "./useForm";
import Loader from "./../loader/Loader";
import { API } from "../../../route/services";

import $ from "jquery";

const Setting = (props) => {
  const userId = localStorage.getItem("id");

  const {
    handlePasswordChange,
    handlePasswordSubmit,
    values,
    passerrors,
  } = useForm(submitPasswordChangeInfo, validate);

  const [error, setError] = useState("");
  const [isLoad, setLoader] = useState(false);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
    }, 15000);
  });

  const toggleMenu = useCallback(() => {
    $(".sidebar").toggleClass("sidebarshow");
    $(".dashboard-data").toggleClass("dashboard-data-show");
  }, []);

  async function submitPasswordChangeInfo() {
    setError("");
    if (!Object.keys(validate(values)).length) {
      setLoader(true);
      await axios
        .put(
          `${API.usersChangePassword}/${userId}/`,
          {
            // old_password: values.oldpassword,
            password: values.newpassword,
            password2: values.confirmpassword,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          values.confirmpassword = "";
          // values.oldpassword = "";
          values.newpassword = "";
          setAlert(true);
          setLoader(false);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.href="/login";
          } else {
            setLoader(false);
            console.log("put change password error:", error);
          }
          // setError(error.response.data.old_password.old_password);
        });
    }
  }
  return (
    <>
      <Sidebar />
      <div className="dashboard-data">
        <Container fluid>
          <Row>
            <Col className="col-12 col-sm-12 col-md-10 col-lg-6">
              <section className="document-section">
                <div className="d-flex align-items-center justify-content-between pt-2 pb-2 ps-3 pe-3">
                  <h3 className="p-0 m-0">Setting</h3>
                  <p className="menuicon mb-0" onClick={toggleMenu}>
                    <Image
                      src={require("../../../assets/images/menu.svg").default}
                      alt="menu"
                      className="d-block d-md-none d-lg-none"
                    />
                  </p>
                </div>

                <div className="setting-section">
                  <Form
                    onSubmit={(e) => handlePasswordSubmit(e)}
                    className="form"
                    noValidate
                  >
                    <Row>
                      {/*<Col className="col-12 col-sm-12 col-md-12 col-lg-12">*/}
                      {/*  <Form.Group className="form-group">*/}
                      {/*    <Form.Label>Old password</Form.Label>*/}
                      {/*    <Form.Control*/}
                      {/*      type="password"*/}
                      {/*      name="oldpassword"*/}
                      {/*      value={values.oldpassword}*/}
                      {/*      onChange={handlePasswordChange}*/}
                      {/*    ></Form.Control>*/}
                      {/*    {error && (*/}
                      {/*      <small className="text-danger">{error}</small>*/}
                      {/*    )}*/}
                      {/*    {passerrors.oldpassword && (*/}
                      {/*      <small className="text-danger">*/}
                      {/*        {passerrors.oldpassword}*/}
                      {/*      </small>*/}
                      {/*    )}*/}
                      {/*  </Form.Group>*/}
                      {/*</Col>*/}
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <Form.Group className="form-group">
                          <Form.Label>New password</Form.Label>
                          <Form.Control
                            type="password"
                            name="newpassword"
                            value={values.newpassword}
                            onChange={handlePasswordChange}
                          ></Form.Control>
                          {passerrors.newpassword && (
                            <small className="text-danger">
                              {passerrors.newpassword}
                            </small>
                          )}
                        </Form.Group>
                      </Col>

                      <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <Form.Group className="form-group">
                          <Form.Label>Confirm password</Form.Label>
                          <Form.Control
                            type="password"
                            name="confirmpassword"
                            value={values.confirmpassword}
                            onChange={handlePasswordChange}
                          ></Form.Control>
                          {passerrors.confirmpassword && (
                            <small className="text-danger">
                              {passerrors.confirmpassword}
                            </small>
                          )}
                        </Form.Group>
                      </Col>

                      {alert && (
                        <div className="alert-message  w-auto">
                          <Alert className="alert-success">
                            <Image
                              src={
                                require("./../../../assets/images/success.svg")
                                  .default
                              }
                              alt="Document"
                              className="me-2"
                            />
                            Password updated successfully
                          </Alert>
                        </div>
                      )}
                      {isLoad && (
                        <div className="loader">
                          <Loader />
                        </div>
                      )}
                      <div className="d-flex justify-content-end">
                        {!isLoad && (
                          <Button
                            type="submit"
                            className="btn btn-success save-btnn"
                            onClick={submitPasswordChangeInfo}
                          >
                            {""}
                            Save{""}
                          </Button>
                        )}
                      </div>
                    </Row>
                  </Form>
                </div>
              </section>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Setting;

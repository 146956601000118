import { useState } from "react";

const usePersonal = (callback, validate) => {
  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    dob: "",
  });
  const [errors, setErrors] = useState({});

  const handlePersonalChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handlePersonalSubmit = (e) => {
    e.preventDefault();

    setErrors(validate(values));
  };

  return { handlePersonalChange, handlePersonalSubmit, values, errors };
};

export default usePersonal;

import * as faceapi from 'face-api.js';

export async function loadModels() {
  const MODEL_URL = process.env.PUBLIC_URL + '/models';

  faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
  faceapi.loadTinyFaceDetectorModel(MODEL_URL);
  faceapi.loadFaceLandmarkTinyModel(MODEL_URL);
  faceapi.loadFaceRecognitionModel(MODEL_URL)

}


export async function getFullFaceDescription(blob, inputSize = 416) {
  // tiny_face_detector options
  let scoreThreshold = 0.2;
  const OPTION = new faceapi.TinyFaceDetectorOptions({
    inputSize,
    scoreThreshold
  });
  const useTinyModel = true;

  // fetch image to api
  let img = await faceapi.fetchImage(blob);

  let fullDesc = await faceapi
    .detectAllFaces(img, OPTION)
    .withFaceLandmarks(useTinyModel)
    .withFaceDescriptors()

  return fullDesc;
}
import React, { useEffect, useState } from "react";
import { Form, Image, Col, Row, Alert } from "react-bootstrap";
import useForm from "./useForm";
import validate from "./validateinfo";
import { useHistory } from "react-router-dom";

import axios from "axios";
import Loader from "../loader/Loader";
import { API } from "../../../route/services";

const Signup = () => {
  const history = useHistory();

  const [error, setError] = useState({ email: "", username: "" });
  const [loading, setLoanding] = useState(false);

  const { handleChange, handleSubmit, values, errors } = useForm(
    submitHandler,
    validate
  );
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
    }, 9000);
  });

  async function submitHandler() {
    setError({});
    if (!Object.keys(validate(values)).length) {
      setLoanding(true);
      await axios
        .post(
          API.registration,
          {
            email: values.email,
            username: values.username,
            first_name: values.firstname,
            last_name: values.lastname,
            password: values.password,
          },
          console.log(""),
          { timeout: 5000 },
          {
            headers: {
              "access-control-allow-origin": "*",
              "Content-Type": "application/json",
            },
            mode: "no-cors",
          }
        )
        .then((response) => {
          setAlert(true);
          setLoanding(false);
          setTimeout(() => {
            history.push({ pathname: "/login", state: values.email });
          }, 2000);
        })
        .catch((error) => {
          console.log("signup error:", error);
          setLoanding(false);
          if (error.response.data.email) {
            setError({ email: error.response.data.email });
          } else if (error.response.data.username) {
            setError({ username: error.response.data.username });
          }
        });
    }
  }

  return (
    <div className="form-wrapper">
      <div className="login-form">
        <Image
          src={require("./../../../assets/images/logo.png").default}
          alt="Eita-app"
          className="w-auto p-4"
        />
        <div className="login-page">
          <Form onSubmit={(e) => handleSubmit(e)} className="form" noValidate>
            <h3>Sign Up</h3>
            <p>Let's create your account</p>
            <Row>
              <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                <Form.Group className="form-group">
                  <Form.Control
                    type="name"
                    name="firstname"
                    placeholder="Enter first name"
                    value={values.firstname}
                    onChange={handleChange}
                    noValidate
                  ></Form.Control>
                  {errors.firstname && <p>{errors.firstname}</p>}
                </Form.Group>
              </Col>
              <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                <Form.Group className="form-group">
                  <Form.Control
                    type="name"
                    name="lastname"
                    placeholder="Enter last name"
                    value={values.lastname}
                    onChange={handleChange}
                  ></Form.Control>
                  {errors.lastname && <p>{errors.lastname}</p>}
                </Form.Group>
              </Col>

              <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                <Form.Group className="form-group">
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    value={values.email}
                    onChange={handleChange}
                  ></Form.Control>
                  {(error.email || errors.email) && (
                    <p>{error.email || errors.email}</p>
                  )}
                </Form.Group>
              </Col>

              <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                <Form.Group className="form-group">
                  <Form.Control
                    type="username"
                    name="username"
                    placeholder="Enter username"
                    value={values.username}
                    onChange={handleChange}
                  ></Form.Control>
                  {(error.username || errors.username) && (
                    <p>{error.username || errors.username}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                <Form.Group className="form-group">
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Enter password"
                    value={values.password}
                    onChange={handleChange}
                  ></Form.Control>
                  {errors.password && <p>{errors.password}</p>}
                </Form.Group>
              </Col>
              <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                <Form.Group className="form-group">
                  <Form.Control
                    type="password"
                    name="password2"
                    placeholder="Enter confirm password"
                    value={values.password2}
                    onChange={handleChange}
                  ></Form.Control>
                  {errors.password2 && <p>{errors.password2}</p>}
                </Form.Group>
              </Col>
            </Row>
            {alert && (
              <div className="alert-message">
                <Alert className="alert-success">
                  <Image
                    src={
                      require("./../../../assets/images/success.svg").default
                    }
                    alt="Document"
                    className="me-2"
                  />
                  Registration successfully
                </Alert>
              </div>
            )}
            {loading && <Loader />}
            {!loading && (
              <button
                className="btn btn-primary mt-3 mb-1"
                type="submit"
                onClick={submitHandler}
              >
                {" "}
                Create an Account{" "}
              </button>
            )}
            <p className="account-register">
              Already have an account <a href="login">Sign in</a>{" "}
            </p>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Signup;

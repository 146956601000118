import {Link, useHistory} from "react-router-dom";
import {Image, Button} from "react-bootstrap";
import axios from "axios";
import {API} from "../../../../../route/services";

function Banner28(props) {
    const userId = localStorage.getItem("id");
    const history = useHistory();

    function submitHandler(value) {
        if(value) {
            history.push({pathname: "/user/addshipping", state: userId});
        } else {
            axios.post(`${API.banner28NoThanks}/`,{},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    mode: "no-cors",
                }
            ).then((response) => {
                props.apiCall();
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    return (
        <>
            <section className="delivery-section sc-mt" id="ShippedUSPSOvernight">
                <div className="Shipping-document-banner">
                    <div className="shipping-content-details">
                        <h3 className="text-white banner-28-title">WOULD YOU LIKE YOUR CORRECTED DOCUMENT TO BE SHIPPED?</h3>
                        <p className="text-white" style={{padding: "5px 50px"}}>
                            You have made edits to your order but your printed documents have already been shipped.
                        </p>
                        <p className="text-white" style={{padding: "5px 50px"}}>
                            If you would like your order reshipped, you will have to pay for additional shipping.
                        </p>
                        <div className="banner-28-btn">
                            <div>
                                <Button
                                    type="button"
                                    className="btn btn-warning"
                                    onClick={submitHandler.bind(this, true)}
                                >
                                    Reship my ducuments
                                </Button>
                            </div>
                            <div className="banner-28-btn-2">
                                <Button
                                    style={{width: "204px"}}
                                    type="button"
                                    className="btn btn-warning"
                                    onClick={submitHandler.bind(this, false)}
                                >
                                    No, Thank you
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Banner28;
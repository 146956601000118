import { Image } from "react-bootstrap";

function DayUSPSOvernight() {
  return (
    <>
      <section className="delivery-section sc-mt" id="DayUSPSOvernight">
        {/*<h3>*/}
        {/*  IF Shipping to US and (USPS Tracking, USPS 2-Day, or USPS Overnight)*/}
        {/*</h3>*/}
        <div className="Shipping-document">
          <p className="text-white mb-0">
            Your order will be shipped in shortly!
          </p>
          <h3 className="text-white">
            We will provide tracking as soon as it is available.
          </h3>
          <Image
            className="deliver-document"
            src={require("./../../../../../assets/images/document.png").default}
            alt="Document"
          />
        </div>
      </section>
    </>
  );
}

export default DayUSPSOvernight;

export default function validateInfo(values) {

  let errors = {};
  if (!values.firstname.trim()) {
    errors.firstname = 'First name required';
  }
  if (!values.lastname) {
    errors.lastname = 'Last name required';
  }
  if (!values.email) {
    errors.email = 'Email address required';
  }
  if (values.email === null || values.email === undefined || values.email === 'null' || values.email === "") {
    errors.email = 'Email is required';
  } else {
    let atpos = values.email.indexOf("@");
    let dotpos = values.email.lastIndexOf(".");
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= values.email.length || values.email === '') {
      errors.email = 'Valid email is required';
    }
  }

  if (!values.dob) {
    errors.dob = 'Date of Birth required';
  }

  return errors;

}

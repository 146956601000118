import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Loader from "../loader/Loader";
import { API } from "../../../route/services";

const Forgotpassword = ({ submitForm }) => {
  const history = useHistory();
  const [username, setUserName] = useState("");
  const [load, setLoad] = useState(false);
  const [error, setError] = useState("");
  const webUrl = process.env.REACT_APP_PUBLIC_URL;

  async function submitHandler(e) {
    e.preventDefault();
    if (!username) {
      setError("username is required");
    }
    setError("");
    setLoad(true);
    await axios
      .post(
        `${API.forgotPassword}`,
        {
          email: username,
          redirect_url: `${webUrl}/reset/password`,
        },
        { timeout: 5000 },
        {
          headers: {
            "access-control-allow-origin": "*",
            "Content-Type": "application/json",
          },
          mode: "no-cors",
        }
      )
      .then((response) => {
        setLoad(false);
        history.push({
          pathname: "/verification-email",
          state: response.data.email,
        });
      })
      .catch((error) => {
        setLoad(false);
        setError("Please Enter a valid username");
      });
  }

  return (
    <div className="form-wrapper">
      <div className="login-form">
        <Image
          src={require("./../../../assets/images/logo.png").default}
          alt="Eita-app"
          className="w-auto p-4"
        />
        <div className="login-page">
          <Form onSubmit={submitHandler} className="form" noValidate>
            <h3>Forgot Password?</h3>
            <p>Please enter your username</p>
            <Form.Group className="form-group">
              <Form.Control
                type="username"
                name="username"
                placeholder="Enter username"
                value={username}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              ></Form.Control>
              {error && <p>{error}</p>}
            </Form.Group>

            {load && <Loader />}
            {!load && (
              <button
                type="submit"
                className="btn btn-primary mt-3 mb-1"
                onClick={(e) => submitHandler}
              >
                Send Email
              </button>
            )}

            <p className="account-register">
              i already have an account <a href="login">Sign in</a>{" "}
            </p>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Forgotpassword;

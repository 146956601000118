import React from "react";

import { Image, Alert } from "react-bootstrap";
import axios from "axios";
import $ from "jquery";
import UserContext from "../../../store/user-context";
import Loader from "../loader/Loader";
import { Redirect } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { API } from "../../../route/services";

export class SavedCardDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripeError: "",
      isLoad: false,
      isRedirect: false,
      isTransactionFailed: false,
      isTransactionSuccess: false,
      processFee: parseFloat(props.processFee !== undefined ? props.processFee : localStorage.getItem("process")),
      ip: $.getJSON("https://api.ipify.org?format=json", (data) => {
        this.setState({ ip: data.ip });
      }),
    };
  }
  static contextType = UserContext;

  handleUserInput(e) {
    this.setState({
      stripeError: "",
    });
  }

  renderRedirect = () => {
    if (this.state.isRedirect) {
      return <Redirect to="/user/dashboard" />;
    }
  };

  deleteHandler = (e) => {
    const userId = localStorage.getItem("id");
    e.preventDefault();
    this.setState({ isLoad: true });
    axios
      .post(
        `${API.userDeleteCardDetail}/${userId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          mode: "no-cors",
        }
      )
      .then((response) => {
        this.setState({ isLoad: false });
        this.props.resetCardDetail();
      })
      .catch((error) => {
        console.log("post delete card error:", error);
      });
  };

  render() {
    let image;
    if (this.context.cardDetail.card_type == "Visa") {
      image = require("./../../../assets/images/visa.svg").default;
    } else if (this.context.cardDetail.card_type == "Rupay") {
      image = require("./../../../assets/images/master-card.png").default;
    } else if (this.context.cardDetail.card_type == "MasterCard") {
      image = require("./../../../assets/images/master-card.png").default;
    }

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const userId = localStorage.getItem("id");
          const processFee = this.state.processFee;
          this.setState({ isLoad: true });
          const paymentService = this.context.payment;
          const amount = this.context.amount;

          this.setState({isTransactionFailed: false, isTransactionSuccess: false});

          axios
            .post(
              `${API.usersShippingPayment}`,
              {
                amount: amount,
                id: userId,
                token: "0",
                description: "",
                shipping_option: paymentService.serviceName,
                tracking: paymentService.price == 0 ? 0 : 1,
                user_ip_location: this.state.ip,
                flag: 1,
                plan_type: "",
                validity_years: "",
                process_fee: parseFloat(processFee),
                ship_flag : 1
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                mode: "no-cors",
              }
            )
            .then((response) => {

              this.setState({isTransactionSuccess: true});
              // window.location.replace("/user/dashboard");
              setTimeout(() => {
                this.setState({ isLoad: false, isRedirect: true });
              }, 2000);
            })
            .catch((error) => {

              this.setState({ isTransactionFailed: true, isLoad: false });
            });
        }}
      >
        <div className="card-delete">
          <div className="card-details">
            <p>
              <Image src={image} alt="card" /> **** **** ****{" "}
              {this.context.cardDetail.card_digits}
            </p>
          </div>
          <div className="card-btn-delete">
            <a href="#" className="btn btn-danger" onClick={this.deleteHandler}>
              Delete
            </a>
          </div>
        </div>
        {this.state.isLoad && (
          <div className="payment-loader">
            <Loader />
          </div>
        )}
        {this.renderRedirect()}
        {this.state.isTransactionSuccess && (
              <SweetAlert success title="Payment Success" onConfirm={() => this.setState({isTransactionSuccess:false, isLoad: false, isRedirect: true})}>
              Your payment has been received
            </SweetAlert>
        )}

        {this.state.isTransactionFailed && (
              <SweetAlert danger title="Transaction failed" confirmBtnText="Try again" onConfirm={() => this.setState({isTransactionFailed: false})}>
              Transaction Failed , Please Try again
            </SweetAlert>
        )}

        {!this.state.isLoad && (
          <button
            className="btn btn-success shipping-button"
            type="submit"
            // onClick={submitHandler}
          >
            Pay
          </button>
        )}
      </form>
    );
  }
}

import React from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { API } from "../../../../route/services";
import moment from "moment";

class Datatable extends React.Component {
  apiUrl = process.env.REACT_APP_SERVER_URL;
  userId = localStorage.getItem("id");

  componentDidMount() {
    var table = $("#dataTable").DataTable({
      processing: true,
      paging: true,
      pageLength: 25,
      lengthChange: false,
      searching: {
        regex: false,
      },
      serverSide: true,
      select: true,
      order: [[0, "desc"]],
      ajax: {
        url: `${API.usersTranscationHistory}/?format=datatables`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        totalUsers: "data.iTotalRecords",
        iDisplayLength: 25,
        defaultContent: "",
        error: function (error) {
          if (error.status === 401) {
            localStorage.clear();
            window.location.href="/login";
          }
        }
      },
      columns: [
        {
          title: "id",
          data: null,
          render: (data, type, row, meta) => meta.row + 1,
          searchable: false,
        },
        {
          data: "total_amount",
          name: "total_amount",
          render: (data, type, row) => row.total_amount.toFixed(2),
          searchable: true,
        },
        {
          data: "plan_type",
          name: "plan_type",
          render: (data, type, row) => row.plan_type.toLowerCase(),
          searchable: true,
          className: "text-capitalize"
        },
        {
          data: "created",
          name: "created",
          render: (data, type, row) => {
            if (row.created !== null && row.created !== "") {
              var utcDateTime = moment(row.created).format(
                  "YYYY-MM-DD hh:mm A"
              );
              return utcDateTime;
            } else {
              return ``;
            }
          },
          searchable: true,
        },
      ],
    });
  }

  render() {
    return (
      <>
        <div className="MainDiv">
          <table id="dataTable" className="display table-responsive">
            <thead>
              <tr id="tr">
                <th>Id</th>
                <th>Amount</th>
                <th>Courier Option</th>
                <th>Time Created</th>
              </tr>
            </thead>
            <tbody></tbody>
            {/* <tfoot>
              <tr>
                <th>Id</th>
                <th>Amount</th>
                <th>Courier Option</th>
                <th>Time Created</th>
              </tr>
            </tfoot> */}
          </table>
        </div>
      </>
    );
  }
}

export default Datatable;

export default function validateInfo(values) {

  var newRegPasswsord = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/)

  let errors = {};
  if (!values.firstname.trim()) {
    errors.firstname = 'First name required';
  }

  if (!values.lastname) {
    errors.lastname = 'Last name required';
  }

  if (!values.username) {
    errors.username = 'User name required';
  }
  if (values.username.length < 3) {
    errors.username = 'User name containing at least 3 character ';
  }

  if (!values.email) {
    errors.email = 'Email required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }

  if (!values.password) {
    errors.password = 'Password is required';
  } else if (!newRegPasswsord.test(values.password)) {
    errors.password = 'Please enter a valid password containing at least minimum 6 character , 1 letter and 1 digit ';
  }

  if (!values.password2) {
    errors.password2 = 'Password is required';
  } else if (values.password2 !== values.password) {
    errors.password2 = 'Passwords do not match';
  }

  return errors;
}

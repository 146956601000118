import React from "react";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'
import { Button } from "react-bootstrap"

class AdminReturn extends React.Component {
  componentDidMount() {
    $(document).ready(function () {
      $('#example').DataTable();
    });
  }

  render() {
    return (
      <>
              <div className="MainDiv">
                    <table id="example" className="display table-responsive">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>First</th>
                          <th>Last</th>
                          <th>email</th>
                          <th>Tracking number</th>
                          <th>Phone</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr>
                          <td>81901</td>
                          <td>Tiger</td>
                          <td>Nixon</td>
                          <td>tiger1@gmail.com</td>
                          <td>789456</td>
                          <td>789 340 3333</td>
                          <td><Button className="btn btn-success">Refund</Button></td>
                        </tr>

                        <tr>
                          <td>81902</td>
                          <td>Garrett</td>
                          <td>Winters</td>
                          <td>Garrett2@gmail.com</td>
                          <td>456789</td>
                          <td>789 341 4444</td>
                          <td><Button className="btn btn-success">Refund</Button></td>
                        </tr>

                        <tr>
                          <td>81903</td>
                          <td>Ashton</td>
                          <td>Cox</td>
                          <td>Ashton2@gmail.com</td>
                          <td>456123</td>
                          <td>789 342 5555</td>
                          <td><Button className="btn btn-success">Refund</Button></td>
                        </tr>

                        <tr>
                          <td>81904</td>
                          <td>Cedric</td>
                          <td>Kelly</td>
                          <td>Cedric3@gmail.com</td>
                          <td>147852</td>
                          <td>789 343 6666</td>
                          <td><Button className="btn btn-success">Refund</Button></td>
                        </tr>

                        <tr>
                          <td>81905</td>
                          <td>Airi</td>
                          <td>Satou</td>
                          <td>Airi15@gmail.com</td>
                          <td>3698582</td>
                          <td>789 340 3333</td>
                          <td><Button className="btn btn-success">Refund</Button></td>
                        </tr>

                        <tr>
                          <td>81906</td>
                          <td>Brielle</td>
                          <td>Williamson</td>
                          <td>Brielle31@gmail.com</td>
                          <td>159357</td>
                          <td>+1 206-876-1800</td>
                          <td><Button className="btn btn-success">Refund</Button></td>
                        </tr>

                        
                      </tbody>

                    </table>

                </div>
      </>
    );
  }
}

export default AdminReturn;

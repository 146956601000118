export default function valishipInfo(values) {

  let errors = {};
  if (!values.address) {
    errors.address = 'this field required';
  }

  if (!values.address2) {
    errors.address2 = 'this field required';
  }

  if (!values.city) {
    errors.city = 'this field required';
  }

  if (!values.state) {
    errors.state = 'this field required';
  }

  if (!values.zipcode) {
    errors.zipcode = 'this field required';
  }

  if (!values.phonenumber) {
    errors.phonenumber = 'this field required';
  }


  return errors;

}

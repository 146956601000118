import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import Loader from "../../../loader/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import { API } from "../../../../../route/services";
import moment from "moment-timezone";

function TransactionHistoryModel(props) {
  const orderId = localStorage.getItem("orderId");
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [isSupportUser] = useState(localStorage.getItem("role") !== "support");
  const [alert, setAlert] = useState({
    success: false,
    failed: false,
    message: '',
    title: null
  });
  const [error, setError] = useState(new Array(data.length).fill(false));
  const [show, setShow] = useState(props.show);
  const [checkedState, setCheckedState] = useState(
    new Array(data.length).fill(false)
  );
  const [totalAmount, setTotal] = useState(0);

  var requestData = [];
  const handleClose = () => {
    setShow(false);
    props.handleClose();
  };

  useEffect(() => {
    axios
      .get(`${API.adminSideUsersTranscationHistory}/${orderId}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        mode: "no-cors",
      })
      .then((response) => {
        // setrefundAmount(response.data.data);
        setData(response.data.data);
        setCheckedState(new Array(response.data.data.length).fill(false));
        setError(new Array(response.data.data.length).fill(false));
      })
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          localStorage.clear();
          window.location.href="/login";
        }
        console.log(e);
      });
  }, {});

  const handleOnChange = (position) => {
    if (data[position].amount !== data[position].refunded) {
      const updatedCheckedState = checkedState.map((item, index) =>
        index === position ? !item : item
      );
      setCheckedState(updatedCheckedState);
      const totalPrice = updatedCheckedState.reduce(
        (sum, currentState, index) => {
          if (currentState === true) {
            return sum + data[index].refundAmount;
          }
          return sum;
        },
        0
      );
      setTotal(totalPrice);
    }
  };

  const handleChangePrice = (e, index) => {
    e.preventDefault();
    setError(new Array(data.length).fill(false));
    let { value, max } = e.target;
    if (Number(value) > max) {
      const updatedError = error.map((item, position) =>
        position === index ? true : false
      );
      setError(updatedError);
    }
    setData((data) => [...data], (data[index].refundAmount = Number(value)));
    const totalPrice = checkedState.reduce((sum, currentState, index) => {
      if (currentState === true) {
        return sum + data[index].refundAmount;
      }
      return sum;
    }, 0);
    setTotal(totalPrice);
  };

  const refundData = data.map((data, index) => {
    if((!isSupportUser && data.product !== "Processing Fee") || isSupportUser) {
      return (
          <>
            <tr
                style={{
                  "backgroundColor": data.status === 'Refunded' || (data.product === 'Processing Fee' && data.amount === 5) ? "#eeeeee" : "",
                }}
            >
              <td>
                {data.status === 'Refunded' || (data.product === 'Processing Fee' && data.amount === 5) ? (
                    ""
                ) : (
                    <div class="form-group">
                      <input
                          type="checkbox"
                          id={`data-${index}`}
                          value={`data-${index}`}
                          onChange={() => handleOnChange(index)}
                          checked={checkedState[index]}
                      />
                      <label for={`data-${index}`}/>
                    </div>
                )}
              </td>
              <td>{moment.utc(data.date).tz("America/Los_Angeles").format(
                  "D MMM, YY"
              )}</td>
              <td>{data.product}</td>
              <td>{data.transaction_id}</td>
              {isSupportUser &&
                <td className="text-center">{data.amount.toFixed(2)}</td>
              }
              <td className="text-center">{data.status === 'Refund Initialized'? `${data.status} - ($ ${data.refund_initialized_amount.toFixed(2)})` : data.status}</td>
              {isSupportUser &&
              <React.Fragment>
                <td className="text-center">{data.refunded.toFixed(2)}</td>
                <td>
                  <input
                      type="number" name={index} value={data.refundAmount.toFixed(2)}
                      onChange={(e) => handleChangePrice(e, index)}
                      readOnly={!checkedState[index]} max={Number(data.amount) - Number(data.refunded)}
                      className={"text-center" + (data.status === 'Refunded' || (data.product === 'Processing Fee' && data.amount === 5) ? ' trans-refunded' : "") } disabled={data.status === 'Refunded' || (data.product === 'Processing Fee' && data.amount === 5)}
                  />
                  <br/>
                  {error[index] && (
                      <small className="text-danger align-center">
                        refund amount is greater than main amount
                      </small>
                  )}
                </td>
              </React.Fragment>
              }
            </tr>
          </>
      );
    }
  });

  const submitHandler = () => {
    checkedState.map((value, index) => {
      if (value) {
        requestData.push({
          id: data[index].payment_id,
          amount: data[index].refundAmount,
          refund_initialized_amount: data[index].refund_initialized_amount,
          payment_type: data[index].product
        });
      }
    });
    let errorCounter = 0;
    error.map((value, index) => {
      if (error[index]) {
        errorCounter++;
      }
    });

    if (errorCounter === 0) {
      setLoad(true);
      axios
        .post(
          API.adminPaymentRefund,
          {
            order_id: orderId,
            total_amount: totalAmount,
            data: requestData,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            mode: "no-cors",
          }
        )
        .then((response) => {

          if ('error' in response.data){
            let message = response.data.error.split('-');
            setAlert({
              success: false,
              failed: true,
              message: message ? message.length > 1 ? message[1]: message : null
            });
          } else if ('details' in response.data){
            setAlert({
              success: true,
              failed: false,
              message: response.data.details || null,
              title: response.data.status
            });
          } else {
            setAlert({
              success: true,
              failed: false,
              message: null
            });

            setTimeout(() => {
              handleClose();
              setLoad(true);
            }, 3000);
          }
        })
        .catch((e) => {
          console.log(e);
          setAlert({
            success: false,
            failed: true,
          });
          // setLoad(true);
        });
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="shipping-modal"
      >
        <Modal.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <div class="form-group">
                    {/* <input type="checkbox" id="filter" />
                    <label for="filter"></label> */}
                  </div>
                </th>
                <th>Date</th>
                <th>Product</th>
                <th>Transaction Id</th>
                {isSupportUser &&
                  <th className="text-center">Amount</th>
                }
                <th className="text-center">Status</th>
                {isSupportUser &&
                <React.Fragment>
                  <th className="text-center">Refunded</th>
                  <th>Refund Amount</th>
                </React.Fragment>
                }
              </tr>
            </thead>
            <tbody>
              {refundData}
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                {isSupportUser &&
                <React.Fragment>
                  <th className="text-center">Total</th>
                  <th className="text-center">{totalAmount.toFixed(2)}</th>
                </React.Fragment>
                }
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          {load && (
            <div className="payment-loader">
              <Loader />
            </div>
          )}
          {totalAmount > 0 && !load && (
            <Button
              variant="primary"
              style={{ color: "white", background: "#3fbab2" }}
              onClick={submitHandler}
            >
              Refund
            </Button>
          )}
        </Modal.Footer>
        {alert.success && (
          <SweetAlert
            success
            title={alert.title || "Payment Success"}
            onConfirm={() => handleClose()}
          >
            {alert.message || "Payment Refunded successfully"}
          </SweetAlert>
        )}
        {alert.failed && (
          <SweetAlert
            danger
            title="Transaction failed"
            confirmBtnText="Try again"
            onConfirm={() => handleClose()}
          >
            {alert.message || "Transaction Failed , Please Try again"}
          </SweetAlert>
        )}
      </Modal>
    </>
  );
}

export default TransactionHistoryModel;

import { Link, useHistory } from "react-router-dom";
import { Button, Image } from "react-bootstrap";

function ShippedUSandUSPSTracking(props) {
  const history = useHistory();
  function orderNotArrived() {
    history.push({pathname: "/user/reshipping", maxDate:props.maxDate});
  }
  return (
    <>
      <section
        className="delivery-section sc-mt"
        id="ShippedUSPSOvernight"
        // style={{ display: "none" }}
      >
        {/*<h3>IF Shipped to US and USPS Tracking, USPS 2-Day, USPS Overnight</h3>*/}
        <div className="Shipping-document">
          {props.trackingId !== "" &&
          <p className="text-white mb-0">
            Your order with Tracking Number{" "}
            <span className="text-warning">
              {" "}
              {props.trackingId || "--------"}
            </span>{" "}
            should have arrived by now.
          </p>
          }
          <h3 className="text-white">
            If your order has not arrived --please click below{" "}
            {/* <span className="text-warning">July 23, 2021</span> */}
          </h3>
          <Image
            className="deliver-document"
            src={
              require("./../../../../../assets/images/usps-tracking.png")
                .default
            }
            alt="USPS"
          />
          {/* <Link to="/ordertrack"> */}
          <Button
            type="button"
            className="btn btn-warning"
            onClick={orderNotArrived}
          >
            My order has not arrived
          </Button>
          {/* </Link> */}
        </div>
      </section>
    </>
  );
}

export default ShippedUSandUSPSTracking;

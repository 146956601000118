import InternalServerError from "./InternalServerError";
import React from "react";

export default class ErrorBoundary extends React.Component {
  state = { has_error: false };

  componentDidCatch(error, info) {
    console.log("errorboundry", error);
    this.setState({ has_error: true });
  }

  render() {
    return this.state.has_error ? <InternalServerError /> : this.props.children;
  }
}

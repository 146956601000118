import { Link } from "react-router-dom";
import { Image, Button } from "react-bootstrap";

function Banner24() {
  return (
    <>
      <section className="delivery-section sc-mt" id="ShippedUSPSOvernight">
        {/*<h3>IF Shipped to US and USPS Tracking, USPS 2-Day, USPS Overnight</h3>*/}
        <div className="Shipping-document-banner">
          <div className="shipping-content-details">
            <p className="text-white mb-0">
              The address you submitted on your application was incorrect
            </p>
            <h3 className="text-white">
              Your order will be shipped free of charge with tracking and will arrive 3-5 business day
            </h3>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner24;

import React from "react";
import UserSidebar from "./../../usersidebar/UserSidebar";
import { Row, Col, Container, Image } from "react-bootstrap";
import Datatable from "./datatable/Datatable";

import $ from "jquery";

function TransactionHistory() {
  $(document).ready(function() {
    $(".menuicon").click(function() {
      $(".sidebar").toggleClass("sidebarshow");
      $(".dashboard-data").toggleClass("dashboard-data-show");
    });
  });
  return (
    <>
      <UserSidebar />
      <div className="dashboard-data">
        <div className="document-section d-flex align-items-center justify-content-between pt-2 pb-2 ps-3 pe-3">
          <h3 className="p-0 m-0">Transaction History</h3>
          <p className="menuicon mb-0">
            <Image
              src={require("../../../assets/images/menu.svg").default}
              alt="menu"
              className="d-block d-md-none d-lg-none"
            />
          </p>
        </div>
        <Container fluid>
          <Row>
            <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
              <Datatable />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TransactionHistory;

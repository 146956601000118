import { useHistory } from "react-router-dom";
import { Image, Button, Alert } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import UserContext from "../../../../../store/user-context";
import { API } from "../../../../../route/services";

function UnPaidDocument(props) {
  const userId = localStorage.getItem("id");
  const apiUrl = process.env.REACT_APP_SERVER_URL;
  const [country, setCountry] = useState("");
  const history = useHistory();
  const [alert, setAlert] = useState(false);

  const context = useContext(UserContext);
  const countryContext = context.isCompleteProfile;

  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
    }, 5000);
  });

  useEffect(() => {
    axios
      .get(`${API.userAllDetailCheckFilled}/${userId}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        mode: "no-cors",
      })
      .then((response) => {
        setCountry(response.data.complete_flag);
      })
      .catch((error) => {
        console.log("digital payment page error:", error.response);
      });
  }, []);

  async function submitHandler(e) {
      history.push({ pathname: "/user/unpaidorder", state: userId });
  }

  return (
    <>
      <section className="ship-document-section sc-mt" id="ShipMyDocument">
        <div className="Shipping-document">
          <h3 className="text-white" style={{marginTop: "24px"}}>
            Complete payment and get your documents today.
          </h3>
          <div className="d-block d-md-block d-lg-flex align-items-center justify-content-between">
            <Image
              className="w-50 deliver-document"
              src={
                require("./../../../../../assets/images/document.png").default
              }
              alt="Document"
            />
            <ul className="list-unstyled text-start">
              <li className="text-white pt-2 pb-2">
                <Image
                  src={
                    require("./../../../../../assets/images/done.svg").default
                  }
                  alt="Done"
                />{" "}
                Shipping Worldwide
              </li>
              <li className="text-white pt-2 pb-2">
                <Image
                  src={
                    require("./../../../../../assets/images/done.svg").default
                  }
                  alt="Done"
                />{" "}
                Internationally Recognized IDL Booklet
              </li>
              <li className="text-white pt-2 pb-2">
                <Image
                  src={
                    require("./../../../../../assets/images/done.svg").default
                  }
                  alt="Done"
                />{" "}
                Identification Card
              </li>
              <li className="text-white pt-2 pb-2">
                <Image
                  src={
                    require("./../../../../../assets/images/done.svg").default
                  }
                  alt="Done"
                />{" "}
                Free Replacements
              </li>
            </ul>
          </div>
          {alert && (
            <div className="alert-message">
              <Alert className="alert-danger">
                <Image
                  src={
                    require("./../../../../../assets/images/not-received.svg")
                      .default
                  }
                  alt="Document"
                  className="me-2"
                />
                Please First Fill All Information
              </Alert>
            </div>
          )}

          <Button
            type="button"
            className="btn btn-warning"
            onClick={submitHandler}
          >
            Order my documents
          </Button>
        </div>
      </section>
    </>
  );
}

export default UnPaidDocument;

import React, { useContext, useEffect, useState } from "react";
import {
  CardElement,
  StripeProvider,
  Elements,
  injectStripe,
} from "react-stripe-elements";
import { Row, Col, Container, Form, Image } from "react-bootstrap";
import axios from "axios";
import $ from "jquery";
import UserContext from "../../../../store/user-context";
import { SavedCardDetail } from "./Carddetail";
import Loader from "../../loader/Loader";
import { Redirect } from "react-router-dom";
import { API } from "../../../../route/services";
import SweetAlert from "react-bootstrap-sweetalert";

class PayCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripeError: "",
      isLoad: false,
      isRedirect: false,
      isTransactionFailed: false,
      isTransactionSuccess: false,
    };
  }

  handleUserInput(e) {
    this.setState({
      stripeError: "",
    });

    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  renderRedirect = () => {
    if (this.state.isRedirect) {
      return <Redirect to="/user/dashboard" />;
    }
  };

  render() {
    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          this.setState({ isLoad: true });
          this.props.stripe.createToken().then(async (payload) => {
            const userId = localStorage.getItem("id");
            const amount = localStorage.getItem("price");
            const planType = localStorage.getItem("option");
            const validity = localStorage.getItem("validity");
            const processFee = localStorage.getItem("proceess") || 0;
            // const price = localStorage.getItem("price");

            this.setState({isTransactionFailed: false, isTransactionSuccess: false});
            if (payload.token) {
              this.setState({ stripeError: "" });
              $("#token").val(payload.token.id);
              await axios
                .post(
                  `${API.usersShippingPayment}`,
                  {
                    amount: parseFloat(amount),
                    token: payload.token.id,
                    id: userId,
                    description: "description",
                    shipping_option: "",
                    tracking: 1,
                    user_ip_location: payload.token.client_ip,
                    flag: 2,
                    plan_type: planType,
                    validity_years: validity,
                    process_fee: parseFloat(processFee),
                    plan_price: amount,
                    ship_flag : 2
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    mode: "no-cors",
                  }
                )
                .then((response) => {

                  this.setState({isTransactionSuccess: true});
                  localStorage.removeItem("price");
                  localStorage.removeItem("option");
                  setTimeout(() => {
                    this.setState({ isLoad: false, isRedirect: true });
                  }, 2000);

                  // window.location.replace("/user/dashboard");
                })
                .catch((error) => {

                  this.setState({ isTransactionFailed: true, isLoad: false });
                  console.log("post payments service error :", error);
                });
            } else {
              this.setState({
                stripeError: payload.error.message,
                isLoad: false,
              });
            }
          });
        }}
      >
        <CardElement />
        {this.state.stripeError && (
          <p className="text-left payment-error text-danger">
            {this.state.stripeError}
          </p>
        )}
        {this.state.isLoad && (
          <div className="payment-loader">
            <Loader />
          </div>
        )}
        {this.renderRedirect()}
        {this.state.isTransactionSuccess && (
            <SweetAlert success title="Payment Success" onConfirm={() => this.setState({isTransactionSuccess:false, isLoad: false, isRedirect: true})}>
              Your payment has been received
            </SweetAlert>
        )}

        {this.state.isTransactionFailed && (
            <SweetAlert danger title="Transaction failed" confirmBtnText="Try again" onConfirm={() => this.setState({isTransactionFailed: false})}>
              Transaction Failed , Please Try again
            </SweetAlert>
        )}

        {!this.state.isLoad && (
          <button className="btn btn-success processing-button" type="submit">
            Pay
          </button>
        )}
      </Form>
    );
  }
}
const CardForm = injectStripe(PayCardForm);

class Checkout extends React.Component {
  render() {
    return (
      <div>
        <Elements className="form-control">
          <CardForm />
        </Elements>
      </div>
    );
  }
}

function PlanRenewDigital() {
  const userId = localStorage.getItem("id");
  const price = parseFloat(localStorage.getItem("price"));
  const [cardDetail, setCardDetail] = useState({ card_flag: 0 });
  const userContext = useContext(UserContext);

  function resetCardDetail() {
    setCardDetail({ card_flag: 0 });
  }

  function apicalling() {
    axios
      .post(
        `${API.usersPaymentCardDeails}/${userId}/`,
        {},

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          mode: "no-cors",
        }
      )
      .then((response) => {
        setCardDetail(response.data[0]);
        userContext.cardHandler(response.data[0]);
      })
      .catch((error) => {
        console.log("post card detail error:", error.response);
      });
  }

  useEffect(() => {
    apicalling();
  }, []);

  return (
    <>
      <Container>
        <section className="processing-section">
          <div className="processing-title">
            <h3>Renew Documents</h3>
            <p>Ensure your order is available in less than 10 minutes.</p>
          </div>

          <div className="payment-stripe">
            <Form>
              <div className="payment-stripe">
                <Row>
                  <Col className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div className="processing-section-title">
                      <p>Processing</p>
                      <ul>
                        <li>
                          Renew Charges &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${price.toFixed(2)}
                        </li>
                      </ul>
                    </div>
                    <div className="payment-section">
                      <p>Payment</p>

                      <div className="stripe-payment">
                        <div className="AppWrapper text-center">
                          {cardDetail.card_flag === 1 && (
                            <SavedCardDetail
                              resetCardDetail={resetCardDetail}
                            />
                          )}
                          {cardDetail.card_flag === 0 && (
                            <StripeProvider
                              apiKey={
                                process.env.REACT_APP_REACT_API_STRIPE_KEY
                              }
                            >
                              <Checkout />
                            </StripeProvider>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div className="summary-section">
                      <p>SUMMARY</p>
                      <ul className="processing">
                        <li>Urgent Processing</li>
                        <li>${price.toFixed(2)}</li>
                      </ul>
                      <ul>
                        <li>Total</li>
                        <li>${price.toFixed(2)}</li>
                      </ul>
                      <h6>
                        <Image
                          src={
                            require("./../../../../assets/images/payment.svg")
                              .default
                          }
                          alt="Payment"
                        />{" "}
                        Safe & Secure Payment
                      </h6>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </section>
      </Container>
    </>
  );
}

export default PlanRenewDigital;
